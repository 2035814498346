import React, { useEffect, useState } from 'react';
import {Card, Row, Col, Button, Modal, message, Tag, Empty} from 'antd';
import { Head } from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import axios from 'axios';
import {LoadingOutlined} from "@ant-design/icons";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

const ActionsIndex = () => {
    const [loading, setLoading] = useState(true);  // Loader state
    const [actions, setActions] = useState([]);    // State for actions data

    // Function to fetch actions from the API
    const fetchActions = async () => {
        try {
            const response = await axios.get('/api/actions'); // API call
            setActions(response.data.actions);                // Set actions
        } catch (error) {
            console.error('Error fetching actions:', error);  // Handle errors
        } finally {
            setLoading(false);                                // Stop loading
        }
    };

    // useEffect hook to fetch actions on component mount
    useEffect(() => {
        fetchActions();
    }, []);

    // Function to handle card click
    const handleCardClick = (action) => {
        window.location.href = `/actions/${action.id}/edit`; // Redirect to edit page
    };

    // Function to handle running an action
    const handleRunAction = (action) => {
        Modal.confirm({
            title: 'Are you sure you want to run this action?',
            content: 'This will trigger the action and nothing can be undone.',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                try {
                    await axios.post(`/api/actions/${action.id}/trigger`); // API call to trigger action
                    message.success('Action is running now, It will take some time!');
                } catch (error) {
                    if (error.response.status === 422) {
                        message.error('This action has already been running on the background.');
                    }
                    else {
                        message.error('Our servers are busy right now, please try again later.');
                    }
                }
            }
        });
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Actions</title>
            </Head>

            <Row justify="space-between" align="middle" className="mb-2">
                <Col>
                    <Title level={4} className="m-0">Actions</Title>
                    <p className="m-0 p-0">Choose an action from below.</p>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => window.location.href = '/actions/create'}>Add New Action</Button>
                </Col>
            </Row>

            <Card className="bg-gray-200" loading={loading}>
                <Row gutter={16}>
                    {actions.length > 0 ? (
                            actions.map((action, index) => (
                                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                                    <Card
                                        size="small"
                                        hoverable
                                        onClick={() => handleCardClick(action)} // Redirect on card click
                                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                    >
                                        <h3 className="font-normal m-0 mb-3">{action.name}</h3>
                                        <Tag className={'mb-3'}>
                                            {action.status}{' '}
                                            {
                                                action.status === 'running'
                                                    ?
                                                    <LoadingOutlined />
                                                    :
                                                    null
                                            }
                                        </Tag>
                                        <Button
                                            type={'primary'}
                                            className={'mb-1'}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent card click event
                                                handleRunAction(action); // Trigger action
                                            }}
                                            block
                                        >
                                            Run this action
                                        </Button>
                                        <Button type={'link'} block>Preview</Button>
                                    </Card>
                                </Col>
                            )))
                        : (
                            <Col span={24}>
                                <Empty description="No actions found"/>
                            </Col>
                        )}
                </Row>
            </Card>
        </AuthSideBarWrapper>
    );
};

export default ActionsIndex;
