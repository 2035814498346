import React, { useState } from 'react';
import {Avatar, Button, Card, Checkbox, ConfigProvider, Layout, Switch} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ArrowRightOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../../../public/media/logoIcon.png';
import shopifyIcon from '../../../public/media/shopifyIcon64.png';
import { Head } from '@inertiajs/react';
import axios from 'axios';
import SelectLanguageDropDown from '@/components/Forms/SelectLanguageDropDown';
import WooCommerceInput from "../components/Forms/WooCommerceInput";

export default function RegisterWooOnboardIndex({  user }) {
    const [steps, setSteps] = useState(1);
    const [language, setLanguage] = useState(user.primary_locale);
    const [reviewChanges, setReviewChanges] = useState(false);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [selectedFeatures, setSelectedFeatures] = useState([]);

    const handleSelectLanguage = (value) => {
        setLanguage(value);
    };

    const handlePlatformSelection = (e, platform) => {
        if (e.target.checked) {
            setSelectedPlatforms((prev) => [...prev, platform]);
        } else {
            setSelectedPlatforms((prev) =>
                prev.filter((selectedPlatform) => selectedPlatform !== platform)
            );
        }
    };

    const handleFeatureSelection = (e, feature) => {
        if (e.target.checked) {
            setSelectedFeatures((prev) => [...prev, feature]);
        } else {
            setSelectedFeatures((prev) =>
                prev.filter((selectedFeatures) => selectedFeatures !== feature)
            );
        }
    };

    const handleStepTwo = async () => {
        try {
            // Make an Axios request to update primary_locale
            const response = await axios.patch('/api/user/update/primary_locale', {
                primary_locale: language, // or dynamically set this value
            });
        } catch (error) {
            // Handle error
            console.error('Error making request:', error);
        } finally {
            setSteps(2)
        }
    };

    const handleStepThree = async () => {
        try {
            const platformsString = selectedPlatforms.join(', ');
            await axios.post('/api/hubspot/update', {
                property: 'integration_platforms',
                value: platformsString,
            });
        } catch (error) {
            console.error('Error making request:', error);
        } finally {
            setSteps(3);
        }
    };

    const handleStepFour = async () => {
        try {
            const featuresString = selectedFeatures.join(', ');
            await axios.post('/api/hubspot/update', {
                property: 'platform_feature_request',
                value: featuresString,
            });
        } catch (error) {
            console.error('Error making request:', error);
        } finally {
            setSteps(4);
        }
    };

    const handleStepFive = () => {
        window.location.href = '/dashboard/empty';
    }

    const handleBilling = () => {
        window.location.href = '/billing/plans/2/subscribe';
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#00b96b',
                },
            }}
        >
            <Head>
                <title>Register Onboard</title>
            </Head>
            <Layout style={{ backgroundColor: 'transparent', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}></div>
                    <div>
                        <Card
                            className={'w-full'}
                            size="small"
                            title={
                                <div className={'text-center p-3 space-x-2'}>
                                    <Avatar shape="square" size="large" src={logo} icon={<UserOutlined />} />
                                    <ArrowRightOutlined />
                                </div>
                            }
                            style={{ width: 500 }}
                        >
                            {steps === 1 ? (
                                <>
                                    <h1 className={'mb-2 text-[20px] font-bold'}>
                                        Choose the language for your store's product content
                                    </h1>
                                    <p>This will be the language used to generate all product-related content.</p>
                                    <div className={'mb-3'}>
                                        <SelectLanguageDropDown initialLocale={user.primary_locale} onChange={handleSelectLanguage} />
                                    </div>
                                    <Button
                                        onClick={handleStepTwo}
                                        type={'primary'}
                                        className={'w-full'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : null}
                            {steps === 2 ? (
                                <>
                                    <h1 className={'mb-2 text-[20px] font-bold'}>
                                        Which platforms are you currently using to sell your products?
                                    </h1>
                                    <p>Let us know, and we’ll easily sync your products from those platforms.</p>
                                    <ul className={'list-none p-2'}>
                                        {['Shopify', 'Wordpress', 'Big Commerce', 'Amazon', 'Magento', 'Other'].map((platform) => (
                                            <li key={platform}>
                                                <Checkbox
                                                    onChange={(e) => handlePlatformSelection(e, platform)}
                                                >
                                                    {platform}
                                                </Checkbox>
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        onClick={handleStepThree}
                                        type={'primary'}
                                        className={'w-full'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : null}
                            {steps === 3 ? (
                                <>
                                    <h1 className={'mb-2 text-[20px] font-bold'}>
                                        Which feature are you most interested in using?
                                    </h1>
                                    <p>Select the one that suits your needs best!</p>
                                    <ul className={'list-none p-2'}>
                                        {['AI Title Fix', 'AI Description Fix', 'AI SEO Meta Information', 'AI MetaField Fix', 'CSV Import', 'Manage Pricing', 'Other'].map((feature) => (
                                            <li key={feature}>
                                                <Checkbox
                                                    onChange={(e) => handleFeatureSelection(e, feature)}
                                                >
                                                    {feature}
                                                </Checkbox>
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        onClick={handleStepFour}
                                        type={'primary'}
                                        className={'w-full'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : null}
                            {steps === 4 ? (
                                    <>
                                        <h1 className={'mb-2 text-[20px] font-bold'}>
                                            Welcome {user.name}, Lets connect your store and sync your products with ShopGPT:
                                        </h1>
                                        <div className={'mb-3'}>
                                            <WooCommerceInput />
                                        </div>
                                        <Button
                                            onClick={handleStepFive}
                                            type={'link'}
                                            className={'w-full'}
                                            icon={<ArrowRightOutlined />}
                                            iconPosition={'end'}
                                        >
                                            Skip & connect later
                                        </Button>
                                    </>
                            ) : null}
                        </Card>
                    </div>
                    <div style={{ flexGrow: 1 }}></div>

                    {/* This will push content to the middle */}
                </Content>
            </Layout>
        </ConfigProvider>
    );
}
