import React, { useEffect, useState } from 'react';
import { Card, Form, Radio, Slider, Space, Typography, Button, message, Col, Row } from 'antd';
import { Head } from "@inertiajs/react";
import axios from 'axios';
import {ArrowRightOutlined, LoadingOutlined, RightCircleFilled, RobotFilled, RobotOutlined} from "@ant-design/icons";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";
import MediaPerformancePie from "@/components/Graphs/MediaPerformancePie";
import MediaTable from "@/components/Tables/MediaTable";

const { Title, Text } = Typography;

const DAMIndex = ({ isPro = true }) => {
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [optimizing, setOptimizing] = useState(false);
    const [form] = Form.useForm();
    const [settings, setSettings] = useState({
        image_quality: 100,
        convert_to: "webp",
        resize_to: "2048",
        optimize_for: "published",
    });

    const handleOptimize = async () => {
        try {
            setOptimizing(true);
            await axios.post('/api/media/optimize', settings);
            message.success('Optimization started successfully, Refresh to see progress.');
        } catch (error) {
            if (error.response && error.response.status === 403) {
                message.error(<span>Please subscribe to one of our plan to use this feature.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            } else {
                message.error('It seems our servers are quite busy right now, please try again later.');
            }
        } finally {
            setOptimizing(false);
        }
    };

    const handleProClick = () => {
        if (!isPro) {
            message.error('You require Pro plan for this feature');
        }
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Media</title>
            </Head>

            <Row justify="space-between" align="middle" className="mb-2">
                <Col>
                    <Title level={4} className="m-0">Media</Title>
                    <div className="m-0 p-0">Enhance, compress and AI fix your media.</div>
                </Col>
                <Col>
                    {/*<Button type="primary" onClick={() => window.location.href = '/import/templates/create'}>Add New Media</Button>*/}
                </Col>
            </Row>

            <Card size={'small'} className="mb-3">
                <div className={'flex justify-between align-center'}>
                    <Title level={5} className={'m-0 p-0'}>Settings</Title>
                    {/*<Button size={'small'} type="link" onClick={() => setIsEditing(!isEditing)}>*/}
                    {/*    {isEditing ? 'Close' : 'Edit'}*/}
                    {/*</Button>*/}
                </div>

                {isEditing ? (
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={settings}
                        onValuesChange={(_, allValues) => setSettings(allValues)}
                    >

                        <Form.Item label="Convert to" name="convert_to">
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value="none">None</Radio>
                                    <Radio
                                        value="webp"
                                        disabled={!isPro}
                                        onClick={handleProClick}
                                    >
                                        WEBP <Text className="bg-yellow-100 px-2 rounded text-xs">Recommended</Text>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Resize to (px)" name="resize_to">
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value="none">None</Radio>
                                    <Radio
                                        value="2048"
                                        disabled={!isPro}
                                        onClick={handleProClick}
                                    >
                                        2048x2048 <Text className="bg-yellow-100 px-2 rounded text-xs">Recommended</Text>
                                    </Radio>
                                    <Radio
                                        value="1500"
                                        disabled={!isPro}
                                        onClick={handleProClick}
                                    >
                                        1500x1500
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                ) : (
                    <div className="space-y-2 mb-4">
                        <div>
                            <Text type="secondary">Convert to:</Text>{" "}
                            <Text strong>{settings.convert_to === "none" ? "None" : "WEBP"}</Text>
                        </div>
                        <div>
                            <Text type="secondary">Resize to:</Text>{" "}
                            <Text strong>{settings.resize_to === "none" ? "None" : (settings.resize_to === "1500" ? "1500x1500px" : "2048x2048px")}</Text>
                        </div>
                    </div>
                )}

                <div style={{textAlign: 'center'}}>
                    <Button
                        type="dashed"
                        className={'bg-purple-600 text-white'}
                        onClick={handleOptimize}
                        loading={optimizing}
                    >
                        Optimize Now
                        <RightCircleFilled />
                    </Button>
                </div>
            </Card>

            <Card size={'small'}>
                <div className={'flex justify-between align-center'}>
                    <Title level={5} className={'m-0 p-0'}>Media Completeness</Title>
                </div>
                <MediaPerformancePie/>
            </Card>

            <MediaTable />
        </AuthSideBarWrapper>
    );
};

export default DAMIndex;
