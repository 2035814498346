import {Button, Card, Col, Flex, Form, Input, message, Row, Select} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { Head } from "@inertiajs/react";
import { useState, useEffect } from "react";
import axios from "axios";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

const { Option, OptGroup } = Select;

export default function ProductExportTemplate({ appTemplateFields, templateId }) {
    const [rows, setRows] = useState([{ id: Date.now(), from: '', with_formula: '', to: '' }]);
    const [totalSelected, setTotalSelected] = useState(0);
    const [loader, setLoader] = useState(false);
    const [form] = Form.useForm();

    const options = appTemplateFields;

    useEffect(() => {
        setLoader(true);
        // Fetch the template data from the API
        axios.get(`/api/products/export/templates/${templateId}`)
            .then(response => {
                const { content } = response.data.template; // Assuming the template object has a content field
                if (content) {
                    const parsedContent = JSON.parse(content); // Assuming content is a JSON string
                    setRows(parsedContent.rows); // Populate rows with the content data
                    form.setFieldsValue({ rows: parsedContent.rows }); // Set form fields with the data
                    const selectedCount = parsedContent.rows.filter(row => row.from).length;
                    setTotalSelected(selectedCount); // Update total selected count
                }
            })
            .catch(error => {
                console.error('Error fetching the template:', error);
            }).finally(error => {
            setLoader(false);
        });
    }, [form]);

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), from: '', with_formula: '', to: '' }]);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        setRows(newRows);

        // Update total selected count
        const selectedCount = newRows.filter(row => row.from).length;
        setTotalSelected(selectedCount);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);

        // Clear form values for deleted row
        form.setFieldsValue({
            rows: newRows,
        });

        // Update total selected count
        const selectedCount = newRows.filter(row => row.from).length;
        setTotalSelected(selectedCount);
    };

    const handleSubmit = async (values) => {
        setLoader(true);
        try {
            const response = await axios.post(`/api/products/export/templates/${templateId}/store`, values);

            message.success(response.data.message);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Product Export</title>
            </Head>
            <Flex wrap justify={'space-between'}>
                <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                    <div>
                        <Title level={4} className={'m-0'}>
                            Product Export Shopify
                        </Title>
                        <p className={'m-0 p-0'}>Map columns to create or update your export template</p>
                    </div>
                </Flex>

                <div className={'text-right'}>
                    <Button
                        loading={loader}
                        type={'primary'}
                        onClick={() => form.submit()}
                    >
                        Save Template & Export
                    </Button>
                    <p className={'mt-1 m-0 p-0 text-gray-500 text-[12px]'}>Export Columns: {totalSelected}</p>
                </div>
            </Flex>

            <Card className={'bg-gray-200'}>
                <Form form={form} onFinish={handleSubmit}>
                    {rows.map((row, index) => (
                        <Row gutter={6} key={row.id} align="middle">
                            <Col span={7}>
                                <Form.Item
                                    className={'mb-2'}
                                    name={['rows', index, 'from']}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select From"
                                        value={row.from}
                                        style={{ width: '100%' }}
                                        onChange={(value) => handleInputChange(index, 'from', value)}
                                    >
                                        {options.map(group => (
                                            <OptGroup key={group.label} label={group.label}>
                                                {group.options.map(option => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}
                                            </OptGroup>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    className={'mb-1'} name={['rows', index, 'with_formula']}>
                                    <Input
                                        placeholder="With Formula"
                                        value={row.with_formula}
                                        onChange={(e) => handleInputChange(index, 'with_formula', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    className={'mb-1'}
                                    name={['rows', index, 'to']}
                                    rules={[
                                        { required: !!row.from, message: 'Please input the "To" field.' }
                                    ]}
                                >
                                    <Input
                                        placeholder="To"
                                        value={row.to}
                                        onChange={(e) => handleInputChange(index, 'to', e.target.value)}
                                        disabled={!row.from}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Button
                                    size={'small'}
                                    type="danger"
                                    className={'hover:bg-red-500 hover:border-red-600 hover:text-white'}
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleDeleteRow(index)}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Button type="dashed" onClick={handleAddRow} icon={<PlusOutlined />}>
                        Add Row
                    </Button>
                </Form>
            </Card>
        </AuthSideBarWrapper>
    );
}
