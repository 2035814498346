import React, {useEffect, useState} from 'react';
import {List, Progress, Tag, Typography} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import Title from "antd/lib/typography/Title";

const { Link } = Typography;

export default function SEOAnalysis({ product }) {
    const [issues, setIssues] = useState([]);

    useEffect(() => {
        setIssues(product.issues ? JSON.parse(product.issues) : []);
    }, [product]);

    if(issues?.length > 0) {
        return (
            <List
                header={
                    <>
                        <Tag color={'red'}>{issues?.length} critical Issues</Tag>
                        <Progress
                            size={"small"}
                            percent={product.completeness}
                            strokeColor={product.completeness > 90 ? 'green' : (product.completeness > 49 ? 'orange': 'red')}
                        />
                    </>
                }
                dataSource={issues}
                renderItem={item => (
                    <List.Item style={{ border: 'none', marginBottom: 0, padding: 2 }}>
                        <CloseCircleOutlined style={{ color: 'red', marginRight: 8 }} />
                        {item}
                    </List.Item>
                )}
            />
        );
    }
    else if(product.issues) {
        return (
            <div>
                <Tag color={'success'} className={'mb-1'}>Perfect</Tag>
                <Progress
                    size={"small"}
                    percent={product.completeness}
                    strokeColor={product.completeness > 90 ? 'green' : (product.completeness > 49 ? 'orange' : 'red')}
                />
                <p style={{border: 'none', marginBottom: 0, padding: 2}}>
                    <CheckCircleOutlined style={{color: 'green', marginRight: 8}}/>
                    This Product has the potential to make its mark on search engines.
                </p>
            </div>
        );
    }
    else {
        return (
            <p>Analyze SEO when you create and save product data.</p>
        );
    }
}
