import React, { useState } from 'react';
import {Avatar, Button, Card, Checkbox, ConfigProvider, Layout, Switch} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ArrowRightOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../../../public/media/logoIcon.png';
import shopifyIcon from '../../../public/media/shopifyIcon64.png';
import { Head } from '@inertiajs/react';
import axios from 'axios';
import SelectLanguageDropDown from '@/components/Forms/SelectLanguageDropDown';

export default function OnboardIndex({  user }) {
    const [steps, setSteps] = useState(1);
    const [language, setLanguage] = useState(user.primary_locale);
    const [reviewChanges, setReviewChanges] = useState(false);
    const [selectedFeatures, setSelectedFeatures] = useState([]);

    const handleSelectLanguage = (value) => {
        setLanguage(value);
    };


    const handleFeatureSelection = (e, feature) => {
        if (e.target.checked) {
            setSelectedFeatures((prev) => [...prev, feature]);
        } else {
            setSelectedFeatures((prev) =>
                prev.filter((selectedFeatures) => selectedFeatures !== feature)
            );
        }
    };

    const handleStepTwo = () => {
        setSteps(2);
    };

    const handleStepThree = async () => {
        try {
            // Make an Axios request to update primary_locale
            const response = await axios.patch('/api/user/update/primary_locale', {
                primary_locale: language, // or dynamically set this value
            });
        } catch (error) {
            // Handle error
            console.error('Error making request:', error);
            // Optionally redirect to an error page or display an error message
        } finally {
            setSteps(3)
        }
    };

    const handleStepFour = async () => {
        try {
            // Make an Axios request to update primary_locale
            const response = await axios.patch('/api/settings/update/auto-sync', {
                auto_sync: reviewChanges, // or dynamically set this value
            });
        } catch (error) {
            // Handle error
            console.error('Error making request:', error);
            // Optionally redirect to an error page or display an error message
        } finally {
            setSteps(4)
        }
    };

    const handleStepFive = async () => {
        try {
            const featuresString = selectedFeatures.join(', ');
            await axios.post('/api/hubspot/update', {
                property: 'platform_feature_request',
                value: featuresString,
            });
        } catch (error) {
            console.error('Error making request:', error);
        } finally {
            setSteps(5);
        }
    }

    const handleStepSix = () => {
        window.location.href = '/dashboard';
    }

    const handleBilling = () => {
        window.location.href = '/billing/plans/2/subscribe';
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#00b96b',
                },
            }}
        >
            <Head>
                <title>Onboard</title>
            </Head>
            <Layout style={{ backgroundColor: 'transparent', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}></div>
                    <div>
                        <Card
                            className={'w-full'}
                            size="small"
                            title={
                                <div className={'text-center p-3 space-x-2'}>
                                    <Avatar shape="square" size="large" src={shopifyIcon} icon={<UserOutlined />} />
                                    <ArrowRightOutlined />
                                    <Avatar shape="square" size="large" src={logo} icon={<UserOutlined />} />
                                </div>
                            }
                            style={{ width: 300 }}
                        >
                            {steps === 1 ? (
                                <>
                                    <h1 className={'mb-2 text-[20px] font-bold'}>
                                        Good Job! {user.name}
                                        <br />
                                        <br />
                                        You are now connected with ShopGPT.
                                    </h1>
                                    <p className={'mb-7'}>We are analyzing your products it might take a while...</p>
                                    <Button
                                        onClick={handleStepTwo}
                                        type={'primary'}
                                        className={'w-full'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Lets Start
                                    </Button>
                                </>
                            ) : null}
                            {steps === 2 ? (
                                <>
                                    <h1 className={'mb-2 text-[20px] font-bold'}>
                                        Select language of your store products
                                    </h1>
                                    <p>The language in which all your product content is generated.</p>
                                    <div className={'mb-3'}>
                                        <SelectLanguageDropDown initialLocale={user.primary_locale} onChange={handleSelectLanguage} />
                                    </div>
                                    <Button
                                        onClick={handleStepThree}
                                        type={'primary'}
                                        className={'w-full'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : null}
                            {steps === 3 ? (
                                <>
                                    <h1 className={'mb-2 text-[20px] font-bold'}>
                                        Should we sync your changes automatically to your store?
                                    </h1>
                                    <p>You can update these changes from settings after the account is setup.</p>
                                    <div className={'mb-3'}>
                                        <Switch onChange={() => setReviewChanges(!reviewChanges)} />
                                    </div>
                                    <Button
                                        onClick={handleStepFour}
                                        type={'primary'}
                                        className={'w-full'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : null}
                            {steps === 4 ? (
                                <>
                                    <h1 className={'mb-2 text-[20px] font-bold'}>
                                        Which feature are you most interested in using?
                                    </h1>
                                    <p>Select the one that suits your needs best!</p>
                                    <ul className={'list-none p-2'}>
                                        {['AI Title Fix', 'AI Description Fix', 'AI SEO Meta Information', 'AI MetaField Fix', 'CSV Import', 'Manage Pricing', 'Other'].map((feature) => (
                                            <li key={feature}>
                                                <Checkbox
                                                    onChange={(e) => handleFeatureSelection(e, feature)}
                                                >
                                                    {feature}
                                                </Checkbox>
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        onClick={handleStepFive}
                                        type={'primary'}
                                        className={'w-full'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : null}
                            {steps === 5 ? (
                                <>
                                    <h1 className={'mb-2 text-[20px] font-bold'}>
                                        Hey, Enjoy premium features with a 20% discount if you subscribe now?
                                    </h1>
                                    <p>What you will get?</p>
                                    <ul>
                                        <li>Create & update products with AI</li>
                                        <li>Create & update collections with AI</li>
                                        <li>Create & update blogs with AI</li>
                                        <li>SEO Analysis</li>
                                        <li>Premium support</li>
                                    </ul>
                                    <Button
                                        onClick={handleBilling}
                                        type={'primary'}
                                        className={'w-full mb-2'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Subscribe a $25 Package
                                    </Button>
                                    <Button
                                        onClick={handleStepSix}
                                        type={'default'}
                                        className={'w-full'}
                                        icon={<ArrowRightOutlined />}
                                        iconPosition={'end'}
                                    >
                                        Skip, I want a free trial
                                    </Button>
                                </>
                            ) : null}
                        </Card>
                    </div>
                    <div style={{ flexGrow: 1 }}></div>

                    {/* This will push content to the middle */}
                </Content>
            </Layout>
        </ConfigProvider>
    );
}
