import { useState, useEffect, useRef } from "react";
import {List, Card, message, Avatar, Timeline, Button} from "antd";
import { SendOutlined, LoadingOutlined, RobotOutlined, UserOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";

// CSS for typing animation
const typingAnimation = `
@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}

.typing {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 1s steps(40, end);
}
`;

const AIChat = ({ onSuggestion, updatedContent = '' }) => {
    const [messages, setMessages] = useState([]);
    const [content, setContent] = useState(updatedContent);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const listRef = useRef(null); // Ref for the List container

    // Add an initial AI message when the component mounts
    useEffect(() => {
        const initialMessage = {
            role: "system",
            content: "To get started, please provide some background information about the blog you want to create. " +
                "<br /><br />Share details such as:<br />" +
                "<br /><ul><li>The main topic or theme of your blog.</li>" +
                "<li>Key points or ideas you want to cover.</li>" +
                "<li>Any specific audience you are targeting.</li>" +
                "<li>SEO keywords or phrases you'd like to include.</li></ul>" +
                "<br >" +
                "Once you provide this information, our AI will generate a well-structured, SEO-friendly blog post tailored to your needs. Let’s create something amazing together!",
        };
        setMessages([initialMessage]);

    }, []);

    useEffect(() => {
        setContent(updatedContent);
    }, [updatedContent]);

    // Scroll to the bottom of the List whenever messages change
    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSend = async () => {
        if (!input.trim()) return;

        // Add the user's message to the state
        const userMessage = {
            role: "user",
            content: input,
        };

        // Use the functional form of setMessages to ensure we have the latest state
        setMessages((prev) => {
            const updatedMessages = [...prev, userMessage];

            // Add a system message with a Timeline and loading state
            const systemMessage = {
                role: "system",
                content: (
                    <Timeline
                        items={[{
                            children: 'We are fixing your blog...',
                            dot: <LoadingOutlined />, // Indicate success
                            itemPaddingBottom: 0,
                        }]}
                    />
                ),
                isLoading: true, // Flag to indicate loading state
            };
            const finalMessages = [...updatedMessages, systemMessage];

            // Send the updated messages to the API
            sendToFixAPI(finalMessages);

            return finalMessages; // Update the state
        });

        setInput(""); // Clear the input field
        setLoading(true); // Set loading state
    };

    const sendToFixAPI = async (messages) => {
        try {
            // Send the message history to the API
            const response = await axios.post("/api/articles/fix", {
                messages: messages.map((msg) => ({
                    role: msg.role,
                    content: msg.content,
                })),
                content: content
            });

            if (response.status === 200) {
                // Update the Timeline with the API response and typing animation
                const apiResponse = response.data.message;
                const updatedTimeline = {
                    role: "system",
                    content: (
                        <Timeline
                            items={[
                                {
                                    children: 'Blog fixing completed.',
                                    color: 'green', // Indicate success
                                },
                                {
                                    children: (
                                        <div className="">
                                            {apiResponse}
                                        </div>
                                    ),
                                    itemPaddingBottom: 0,
                                },
                            ]}
                        />
                    ),
                    isLoading: false, // Remove loading state
                };

                // Replace the system message with the updated Timeline
                setMessages((prev) => [
                    ...prev.slice(0, -1), // Remove the last message (system message)
                    updatedTimeline, // Add the updated Timeline
                ]);

                if (onSuggestion && response.data.content) {
                    onSuggestion(response.data.content);
                }
            }
        } catch (error) {
            let errorMessage = "We are experiencing a high volume of traffic right now. Please try again later.";
            if (error.response && error.response.status === 402) {
                errorMessage = 'You are out of your balance. Please upgrade your account.';
                message.error(<span>Your AI Credits are finished. Subscribe now to upgrade your plan.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            } else {
                message.error("An error occurred while processing your request.");
            }

            // Update the Timeline with an error message
            const errorTimeline = {
                role: "system",
                content: (
                    <Timeline
                        items={[
                            {
                                children: 'Request failed',
                                color: 'red', // Indicate failure
                            },
                            {
                                children: errorMessage,
                                color: 'red', // Indicate failure
                                itemPaddingBottom: 0,
                            },
                        ]}
                    />
                ),
                isLoading: false, // Remove loading state
            };

            // Replace the system message with the error Timeline
            setMessages((prev) => [
                ...prev.slice(0, -1), // Remove the last message (system message)
                errorTimeline, // Add the error Timeline
            ]);


        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col h-full">
            {/* Add the CSS for typing animation */}
            <style>{typingAnimation}</style>

            <div className="flex-1 overflow-auto pr-2" ref={listRef}>
                <List
                    itemLayout="horizontal"
                    dataSource={messages}
                    bordered={false} // Remove borders from list items
                    renderItem={(msg) => (
                        <List.Item className={`flex`} style={{ borderBottom: 'none' }}>
                            <Card size={'small'} className={`flex items-start w-full`}>
                                <div className={'mb-2'}>
                                    <Avatar size={'small'} shape={'square'} className={'bg-transparent text-gray-500 border border-gray-300'} icon={msg.role === "system" ? <RobotOutlined /> : <UserOutlined />} />
                                </div>
                                {typeof msg.content === 'string' ? (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: msg.content.replace('\n', '<br />') }}
                                    />
                                ) : (
                                    msg.content
                                )}
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
            <div className="p-4 border-t">
                <div className="w-full max-w-xl">
                    <div className="relative">
                        <TextArea
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Ask a follow up..."
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            className="rounded-lg bg-white border-gray-400 pr-8 pt-2 pl-3 pb-5 text-gray-900 placeholder:text-gray-600"
                            disabled={loading} // Disable input while loading
                        />
                        <SendOutlined
                            onClick={handleSend}
                            className={`absolute top-1 hover:bg-gray-100 cursor-pointer p-2 rounded-2xl right-1 ${
                                loading ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AIChat;
