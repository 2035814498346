import {Button, Card, Col, ConfigProvider, Flex, Row, Statistic} from "antd";

import {Head} from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import ProductsTable from "@/components/Tables/ProductsTable";
import ProductCreateAI from "@/components/Forms/ProductCreateAI";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";
import ImageProductTable from "@/components/Tables/ImageProductTable";

export default function ProductIndex({}) {
    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Fix product data with images</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                <Title level={3} className={'m-0'}>Fix Product With Image</Title>
                <ProductCreateAI />
            </Flex>
            <ImageProductTable />
        </AuthSideBarWrapper>
    )
}
