import React, { useEffect, useState } from 'react';
import {Table, Button, Empty, Col, Row, Flex, message} from 'antd';
import { Head } from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import axios from 'axios';
import AuthSideBarWrapper from "../components/Navs/AuthSideBarWrapper";
import BulkEditProcessedCounter from "../components/Graphs/BulkEditProcessedCounter";

const EditIndex = () => {
    const [loading, setLoading] = useState(true);  // Loader state
    const [templates, setTemplates] = useState([]);    // State for templates data

    // Function to fetch templates from the API
    const fetchTemplates = async () => {
        try {
            const response = await axios.get('/api/edit/templates'); // API call
            setTemplates(response.data.templates);                // Set templates data
        } catch (error) {
            console.error('Error fetching templates:', error);  // Handle errors
        } finally {
            setLoading(false);                                // Stop loading
        }
    };

    // useEffect hook to fetch templates on component mount
    useEffect(() => {
        fetchTemplates();
    }, []);

    // Columns for the table
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                text
            ),
        },
        {
            title: 'Mapped Columns',
            dataIndex: 'mapped_columns',
            key: 'mapped_columns',
            width: 100,
            render: (text) => (
                text
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width:70,
            render: (text, record) => (
                <Flex gap={5}>
                    <Button size={'small'} type="primary" onClick={(e) => handleImport(e, record)}>
                        Start
                    </Button>
                </Flex>
            ),
        },
    ];

    // Function to handle import click
    const handleImport = async (e, template) => {
        e.stopPropagation(); // Prevent row click from firing

        try {
            const response = await axios.post(`/api/edit/templates/${template.id}/start`); // API call
            message.success('Bulk Edit Started, We will notify you once the edit is done.');                // Set templates data
        } catch (error) {
            console.error('Error starting bulk edit:', error);  // Handle errors
        } finally {
            setLoading(false);                                // Stop loading
        }
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Bulk Edit</title>
            </Head>

            <Row justify="space-between" align="middle" className="mb-2">
                <Col>
                    <Title level={4} className="m-0">Bulk Edit</Title>
                    <p className="m-0 p-0">Edit your product data with AI.</p>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => window.location.href = '/edit/templates/create'}>Add New Bulk Edit</Button>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <BulkEditProcessedCounter />
                </Col>
            </Row>

                {/* Table to display the templates */}
            <Table
                size={'small'}
                dataSource={templates}
                columns={columns}
                loading={loading}
                rowKey="id"
                locale={{ emptyText: <Empty description="No bulk edit templates found, you can add new one." /> }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            window.location.href = `/edit/templates/${record.id}/edit`; // Redirect to edit page
                        },
                        style: { cursor: 'pointer' }
                    };
                }}
            />
        </AuthSideBarWrapper>
    );
};

export default EditIndex;
