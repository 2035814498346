import React, { useEffect, useState } from 'react';
import {Head} from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import {Avatar, Button, Card, Col, Flex, Input, message, Modal, Row, Space, Table, Tag, Tooltip} from "antd";
import {DollarCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import shopifyIcon from '../../../public/media/shopifyIcon64.png';
import axios from "axios";

export default function Billing({user}) {
    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState('');
    const [loader, setLoader] = useState(false);
    const handleOk = () => {
        setLoader(true);
        axios.post(`/api/shopify/charge/store`, {credit: inputValue}).then((response) => {
            console.log(response.data.charge.confirmation_url);
            window.location.href = response.data.charge.confirmation_url
        }).catch((error) => {
            if(error.response.status === 422) {
                message.error('Something went wrong please try again.');
            }
        }).finally(() => {
            setTimeout(() => {
                setLoader(false)
            }, 3000);
        });
    };
    const handleCancel = () => {
        setModal2Open(false);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        // Check if value is an integer
        if (/^\d+$/.test(value) || value === '') {
            // Check if value is within the range
            const intValue = parseInt(value);
            if (intValue < 5 || intValue > 100) {
                setInputError('Price must be between 5 and 100');
            } else {
                setInputError('');
            }
            setInputValue(value)
        }

    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        }
    ];
    const data = [
        {
            key: '1',
            name: '$5',
            age: '5000 words'
        },
    ];

    return (
        <div>
            <Head>
                <title>Billing</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                <Title level={3} className={'m-0'}>Billing</Title>
            </Flex>
            <p className={'m-0 p-0 mb-4 font-bold text-[20px]'}>Pay as You Go</p>
            <p className={'p-0 m-0 font-bold'}>Account Balance</p>
            <Row>
                <Col span={24} className="mb-5">
                    <p className="m-0 text-[35px] mb-5">${parseFloat(user.credits).toFixed(2)}</p>
                    <Card size={'small'} bordered className="mb-2">
                        <b>What is account balance?</b><br/>
                            Account Balance is the fuel for your AI bot, when your balance reaches $0, The AI magic will stop working<br /> but do not worry we will remind you once you reach your low balance.
                    </Card>
                    <Flex wrap gap="small">
                        <Button type="primary" onClick={() => setModal2Open(true)}>
                            Add to Balance
                        </Button>
                    </Flex>
                </Col>
            </Row>
            <Modal
                title="Add to account balance."
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button loading={loader} disabled={!!inputError} key="submit" type="primary"  onClick={handleOk}>
                        Continue
                    </Button>
                ]}
            >
                <label>Amount to add</label>
                <Input
                    placeholder="Enter your amount"
                    prefix={<DollarCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    suffix={
                        <Tooltip title="Enter your amount here to calculate how many words you will get">
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                    }

                    value={inputValue}
                    onChange={handleInputChange}

                    style={{ borderColor: inputError ? 'red' : null }}
                />
                <p className={'m-0 p-0'}><small className={`mb-3 ${inputError ? 'text-red-500' : null}`}>Enter amount between $5 to $100,</small></p>
                <p className={'m-0 mb-10'}><strong>Suggested amount according to your products is $25</strong></p>
                <div className={'w-full py-2 text-[12px] px-2 bg-green-50 rounded'} color="geekblue"><Avatar src={shopifyIcon} />
                    You will be charged with shopify payment gateway through your store. And this is a one time charge you have to add balance again manually when ever you think your balance is low.</div>
            </Modal>
        </div>
    )
}
