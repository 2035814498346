import {Button, Card, Col, Flex, Form, Input, message, Modal, Row, Select, Space, Steps} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { Head } from "@inertiajs/react";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import BigFileUpload from "../components/Forms/BigFileUpload";
import CollectionsMultiDropDown from "@/components/Forms/CollectionsMultiDropDown";
import IntegrationsMultiDropDown from "@/components/Forms/IntegrationsMultiDropDown";
import AuthSideBarWrapper from "../components/Navs/AuthSideBarWrapper";

const { Option, OptGroup } = Select;

export default function EditTemplateCreate({ appTemplateFields, templateEditId }) {
    const [rows, setRows] = useState([{ id: Date.now(), from: '', with_formula: '', to: '' }]);
    const [totalSelected, setTotalSelected] = useState(0);
    const [loader, setLoader] = useState(false);
    const [showMapping, setShowMapping] = useState(false);
    const [headings, setHeadings] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false); // Control the visibility of the modal
    const [templateName, setTemplateName] = useState(''); // Store template name
    const [form] = Form.useForm();
    const [templateId, setTemplateId] = useState(templateEditId || null); // Initialize templateId state
    const [filters, setFilters] = useState([]);

    const options = appTemplateFields;

    useEffect(() => {
        if (templateId) {
            setLoader(true);
            // Fetch the template data from the API
            axios.get(`/api/edit/templates/${templateId}`)
                .then(response => {
                    setTemplateName(response.data.template.name);
                    const { content, filter } = response.data.template; // Assuming the template object has a content field
                    if (content) {
                        const parsedContent = JSON.parse(content); // Assuming content is a JSON string
                        setRows(parsedContent); // Populate rows with the content data
                        form.setFieldsValue({ rows: parsedContent }); // Set form fields with the data
                        const selectedCount = parsedContent.filter(row => row.from).length;
                        setTotalSelected(selectedCount); // Update total selected count
                    }

                    const savedPayload = JSON.parse(filter);
                    if (savedPayload) {
                        const prePopulatedFilters = Object.keys(savedPayload || {}).map(key => ({
                            criteria: key,
                            condition: savedPayload[key].condition,
                            value: savedPayload[key].value
                        }));
                        console.log(prePopulatedFilters);
                        setFilters(prePopulatedFilters);
                    }
                })
                .catch(error => {
                    console.error('Error fetching the template:', error);
                }).finally(() => {
                setLoader(false);
            });
        }
    }, [form, templateId]);

    // Configurations for each criteria with `name` added
    const filterConfig = {
        all: {
            name: 'All Products', // User-friendly name
            conditionOptions: null,
            InputOption: null
        },
        title: {
            name: 'Title', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="equals_to">Equals to</Option>
                    <Option key={2} value="not_equals_to">Not Equals to</Option>
                    <Option key={3} value="contains">Contains</Option>
                    <Option key={4} value="not_contains">Not Contains</Option>
                </Select>
            ),
            InputOption: <Input style={{ width: 200 }} placeholder="Enter title" />
        },
        description: {
            name: 'Description', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="contains">Contains</Option>
                    <Option key={2} value="not_contains">Not Contains</Option>
                </Select>
            ),
            InputOption: <Input style={{ width: 200 }} placeholder="Enter description" />
        },
        tags: {
            name: 'Tags', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="contains">Contains</Option>
                </Select>
            ),
            InputOption: <Input style={{ width: 200 }} placeholder="Enter comma-separated tags" />
        },
        sync_status: {
            name: 'Sync Status', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="equals_to">Equals to</Option>
                    <Option key={2} value="not_equals_to">Not Equals to</Option>
                </Select>
            ),
            InputOption: (
                <Select style={{ width: 200 }} placeholder="Select status">
                    <Option key={1} value="synced">Synced</Option>
                    <Option key={2} value="updated">Not Synced</Option>
                    <Option key={3} value="issue">Issue</Option>
                </Select>
            )
        },
        collections: {
            name: 'Collections/Categories', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="contains">Contains</Option>
                </Select>
            ),
            InputOption: <CollectionsMultiDropDown />
        },
        integrations: {
            name: 'Integrations', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="contains">Contains</Option>
                </Select>
            ),
            InputOption: <IntegrationsMultiDropDown />
        }
    };

    // Criteria and actions options with the new `name` attribute
    const criteriaOptions = Object.keys(filterConfig).map(key => ({
        value: key,
        label: filterConfig[key].name
    }));

    // Handle criteria change
    const handleCriteriaChange = (index, value) => {
        const updatedFilters = [...filters];
        updatedFilters[index].criteria = value;
        updatedFilters[index].condition = null;
        updatedFilters[index].value = '';
        setFilters(updatedFilters);
    };

    // Handle condition change
    const handleConditionChange = (index, value) => {
        const updatedFilters = [...filters];
        updatedFilters[index].condition = value;
        setFilters(updatedFilters);
    };

    // Handle input change for filters
    const handleInputFilterChange = (index, e) => {
        const updatedFilters = [...filters];
        updatedFilters[index].value = e.target ? e.target.value : e; // Support for dropdown select too
        setFilters(updatedFilters);
    };

    // Remove filter row
    const removeFilter = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters(updatedFilters);
    };

    const addNewFilter = () => {
        setFilters([...filters, { criteria: null, condition: null, value: '' }]);
    };

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), from: '', with_formula: '', to: '' }]);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        setRows(newRows);

        // Update total selected count
        const selectedCount = newRows.filter(row => row.from).length;
        setTotalSelected(selectedCount);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);

        // Clear form values for deleted row
        form.setFieldsValue({
            rows: newRows,
        });

        // Update total selected count
        const selectedCount = newRows.filter(row => row.from).length;
        setTotalSelected(selectedCount);
    };

    const handleSubmit = async (values) => {
        setLoader(true);
        const payload = {
            filters: {},
            content: {},
            name: templateName
        };

        filters.forEach(filter => {
            if (filter.criteria) {
                payload.filters[filter.criteria] = {
                    condition: filter.condition,
                    value: filter.value
                };
            }
        });

        payload.content = values.rows

        try {
            if (templateId) {
                await axios.put(`/api/edit/templates/${templateId}/update`, payload);
            } else {
                const response = await axios.post(`/api/edit/templates/store`, payload);
                setTemplateId(response.data.edit_template.id);
            }
            if (form.getFieldValue('start_import')) {
                message.success('Import Started Successfully.');
            } else {
                message.success('Template saved successfully.');
            }
            return window.location.href = '/edit';

        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;
                Object.keys(errors).forEach((field) => {
                    errors[field].forEach((messageError) => {
                        message.error(messageError);  // Display each error message
                    });
                });
            } else {
                message.error('Something went wrong. Please try again.');
            }
        } finally {
            form.setFieldsValue({start_import:false});
            setLoader(false);
        }
    };

    const handleSaveTemplateClick = () => {
        setIsModalVisible(true); // Show the modal when "Save Template" is clicked
    };

    const handleImportTemplateClick = () => {
        form.setFieldsValue({start_import:true});
        form.submit(); // Submit the form when modal OK is clicked
    };

    const handleModalOk = () => {
        form.submit(); // Submit the form when modal OK is clicked
        setIsModalVisible(false); // Hide the modal
    };

    const handleModalCancel = () => {
        setIsModalVisible(false); // Hide the modal when Cancel is clicked
    };

    const handleSuccessUpload = (response) => {
        if (response) {
            setShowMapping(true);
            setHeadings(Object.entries(response.headings));
            form.setFieldsValue({ file: response.path });
        } else {
            setShowMapping(false);
            setHeadings([]);
            form.setFieldsValue({ file: '' });
        }
    };

    const handleErrorUpload = (response) => {};

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Create Bulk Edit Template</title>
            </Head>
            <Flex wrap justify={'space-between'}>
                <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                    <div>
                        <Title level={4} className={'m-0'}>
                            Create Bulk Edit Template
                        </Title>
                        <p className={'m-0 p-0'}>Map columns to create or update your bulk edit template</p>
                    </div>
                </Flex>

                <div className={'text-right'}>
                    <Flex gap={5}>
                        <Button
                            loading={loader}
                            type={'primary'}
                            onClick={handleSaveTemplateClick} // Show modal when "Save Template" is clicked
                        >
                            Save Template
                        </Button>
                    </Flex>
                    <p className={'mt-1 m-0 p-0 text-gray-500 text-[12px]'}>Mapped Columns: {totalSelected}</p>
                </div>
            </Flex>

            <Steps
                direction="vertical"
                size="small"
                items={[
                    {
                        title: 'Choose Filtered Products',
                        description: <Card className={'bg-gray-200'} title={"Filter Products"} size={'small'}>
                            {filters.map((filter, index) => (
                                <Flex key={index} gap={5} wrap={true} align="center" size={8} style={{ marginBottom: 16 }}>
                                    {/* Criteria Select */}
                                    <Select
                                        style={{ width: 200 }}
                                        placeholder="Select Criteria"
                                        value={filter.criteria}
                                        onChange={(value) => handleCriteriaChange(index, value)}
                                    >
                                        {criteriaOptions.map(option => (
                                            <Option key={option.value} value={option.value}>{option.label}</Option>
                                        ))}
                                    </Select>

                                    {/* Condition Options */}
                                    {filter.criteria && filterConfig[filter.criteria].conditionOptions && React.cloneElement(
                                        filterConfig[filter.criteria].conditionOptions,
                                        { value: filter.condition, onChange: (value) => handleConditionChange(index, value) }
                                    )}

                                    {/* Input Option */}
                                    {filter.criteria && filterConfig[filter.criteria].InputOption && React.cloneElement(
                                        filterConfig[filter.criteria].InputOption,
                                        { value: filter.value, onChange: (e) => handleInputFilterChange(index, e) }
                                    )}

                                    {/* Remove Filter Button */}
                                    {filters.length > 1 && (
                                        <Button type={'link'} onClick={() => removeFilter(index)}>Remove</Button>
                                    )}
                                </Flex>
                            ))}

                            <Button type="dashed" onClick={addNewFilter}>
                                Add Filter
                            </Button>
                        </Card>
                    },
                    {
                        title: 'Bulk Edit Options',
                        description: <Card size={'small'} className={'bg-gray-200'}>
                            <Form form={form} onFinish={handleSubmit}>
                                <Form.Item
                                    hidden={true}
                                    name={'file'}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    hidden={true}
                                    initialValue={false}
                                    name={'start_import'}
                                >
                                    <Input />
                                </Form.Item>
                                {rows.map((row, index) => (
                                    <Row gutter={6} key={row.id} align="middle">
                                        <Col span={7}>
                                            <Form.Item
                                                className={'mb-2'}
                                                name={['rows', index, 'from']}
                                                rules={[
                                                    { required: true, message: 'Please input the "From" field.' },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select From"
                                                    value={row.from}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => handleInputChange(index, 'from', value)}
                                                >
                                                    {options.map(group => (
                                                        <OptGroup key={group.label} label={group.label}>
                                                            {group.options.map(option => (
                                                                <Option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Option>
                                                            ))}
                                                        </OptGroup>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                className={'mb-1'} name={['rows', index, 'with_formula']}>
                                                <Input
                                                    placeholder="Write AI Prompt to update data"
                                                    value={row.with_formula}
                                                    onChange={(e) => handleInputChange(index, 'with_formula', e.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                className={'mb-1'}
                                                name={['rows', index, 'to']}
                                                rules={[
                                                    { required: true, message: 'Please input the "To" field.' }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select From"
                                                    value={row.from}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => handleInputChange(index, 'to', value)}
                                                >
                                                    {options.map(group => (
                                                        <OptGroup key={group.label} label={group.label}>
                                                            {group.options.map(option => (
                                                                <Option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Option>
                                                            ))}
                                                        </OptGroup>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                            <Button
                                                size={'small'}
                                                type="danger"
                                                className={'hover:bg-red-500 hover:border-red-600 hover:text-white'}
                                                icon={<DeleteOutlined />}
                                                onClick={() => handleDeleteRow(index)}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                                <Button type="dashed" onClick={handleAddRow} icon={<PlusOutlined />}>
                                    Add Row
                                </Button>
                            </Form>
                        </Card>
                    },
                ]}
            />

            <Modal
                title="Save Template"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Input
                    placeholder="Enter template name"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)} // Update template name state
                />
            </Modal>
        </AuthSideBarWrapper>
    );
}
