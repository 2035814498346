import {Button, Flex} from "antd";

import {Head} from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import CollectionsTable from "@/components/Tables/CollectionsTable";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function CollectionIndex({}) {
    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Collections</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                <Title level={3} className={'m-0'}>Collections</Title>
                {/*<Button onClick={() => window.location.href = '/collections/create'} type={'primary'}>Add Collection</Button>*/}
            </Flex>
            <CollectionsTable />
        </AuthSideBarWrapper>
    )
}
