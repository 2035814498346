import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Flex, Spin, Card, Progress } from 'antd';
import axios from 'axios';

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip);

const MediaPerformancePie = () => {
    const [goodImageCount, setGoodImageCount] = useState(0);
    const [needsImprovementImageCount, setNeedsImprovementImageCount] = useState(0);
    const [badImageCount, setBadImageCount] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [totalImagesFixed, setTotalImagesFixed] = useState(0);
    const [sizeBefore, setSizeBefore] = useState(0);
    const [sizeAfter, setSizeAfter] = useState(0);
    const [compressionSaving, setCompressionSaving] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Make API request to get media stats
        axios
            .get('/api/stats/media/completeness')
            .then((response) => {
                const {
                    good_media_count,
                    need_improvement_media_count,
                    bad_media_count,
                    total_images,
                    total_images_fixed,
                    size_before_compression,
                    size_after_compression
                } = response.data;

                setGoodImageCount(good_media_count);
                setNeedsImprovementImageCount(need_improvement_media_count);
                setBadImageCount(bad_media_count);
                setTotalImages(total_images);
                setTotalImagesFixed(total_images_fixed);
                setSizeBefore(size_before_compression);
                setSizeAfter(size_after_compression);
                setCompressionSaving(((sizeBefore - sizeAfter) / sizeBefore) * 100);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching media stats:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="loading-spinner text-center py-4">
                <Spin />
            </div>
        );
    }

    // Chart data (does not include product-related information)
    const data = {
        labels: ['Good Images', 'Needs Improvement', 'Bad Images'],
        datasets: [
            {
                label: 'Media Performance',
                data: [(goodImageCount+needsImprovementImageCount+badImageCount > 0 ? goodImageCount : 1), needsImprovementImageCount, badImageCount],
                backgroundColor: [(goodImageCount+needsImprovementImageCount+badImageCount > 0 ? '#3f8600' : '#c4c4c4'), '#ffb74d', '#cf1322'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        return `${value}`;
                    },
                },
            },
        },
        cutout: '60%',
        layout: {
            padding: 0,
        },
    };

    // Format the size from bytes to KB, MB, or GB
    const formatSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';

        const kilobytes = 1024;
        const megabytes = kilobytes * 1024;
        const gigabytes = megabytes * 1024;

        if (bytes < kilobytes) {
            return `${bytes} Bytes`;
        } else if (bytes < megabytes) {
            return `${(bytes / kilobytes).toFixed(2)} KB`;
        } else if (bytes < gigabytes) {
            return `${(bytes / megabytes).toFixed(2)} MB`;
        } else {
            return `${(bytes / gigabytes).toFixed(2)} GB`;
        }
    };

    const chartContainerStyle = {
        width: '140px',
        height: '140px',
        margin: 0,
        padding: 0,
    };

    return (
        <Flex gap={0} align={'center'}>
            <div style={chartContainerStyle}>
                <Doughnut data={data} options={options} />
            </div>
            <div className={'text-[13px] w-72 ml-2'}>
                <ul className={'m-0 pl-5'}>
                    <li>
                        Good Images{' '}
                        <span style={{color: '#3f8600'}} className={'font-bold'}>
                            {goodImageCount}
                        </span>
                    </li>
                    <li>
                        Needs Improvement{' '}
                        <span style={{color: '#e57373'}} className={'font-bold'}>
                            {needsImprovementImageCount}
                        </span>
                    </li>
                    <li>
                        Bad Images{' '}
                        <span style={{color: '#cf1322'}} className={'font-bold'}>
                            {badImageCount}
                        </span>
                    </li>
                </ul>
            </div>
            <div className={'w-full'}>
                <Flex className={'w-full mb-2'}>
                    <Card size={'small'} className="ml-2 w-full border-0 bg-gray-100">
                        <Flex>
                            <p className="m-0 p-0">Total Images</p>
                        </Flex>
                        <p className="m-0 p-0 font-bold">
                            {totalImages} <small>images</small>
                        </p>
                    </Card>
                    <Card size={'small'} className="ml-2 w-full border-0 bg-gray-100">
                        <Flex>
                            <p className="m-0 p-0">Total Images Scanned</p>
                        </Flex>
                        <div className="m-0 p-0 font-bold">
                            <Progress strokeColor={'green'} percent={Math.round((totalImagesFixed / totalImages) * 100)} />
                        </div>
                    </Card>
                </Flex>
                {/*<Flex className={'w-full'}>*/}
                {/*    <Card size={'small'} className="ml-2 w-full border-0 bg-gray-100">*/}
                {/*        <Flex>*/}
                {/*            <p className="m-0 p-0">Total Size Before</p>*/}
                {/*        </Flex>*/}
                {/*        <p className="m-0 p-0 font-bold">{formatSize(sizeBefore)}</p>*/}
                {/*    </Card>*/}
                {/*    <Card size={'small'} className="ml-2 w-full border-0 bg-gray-100">*/}
                {/*        <Flex>*/}
                {/*            <p className="m-0 p-0">After Compression</p>*/}
                {/*        </Flex>*/}
                {/*        <p className="m-0 p-0 font-bold">*/}
                {/*            {formatSize(sizeAfter)} <small>Saved {Math.round(compressionSaving)}%</small>*/}
                {/*        </p>*/}
                {/*    </Card>*/}
                {/*</Flex>*/}
            </div>
        </Flex>
    );
};

export default MediaPerformancePie;
