import React, { useState, useEffect } from 'react';
import { Progress, Flex } from 'antd';
import axios from 'axios';

const VariantCompletenessLine = () => {
    const [completeness, setCompleteness] = useState(0); // Percentage of completeness
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Make API request to get variant completeness stats
        axios
            .get('/api/stats/variants/completeness')
            .then((response) => {
                const { completeness } = response.data; // Assume the API returns a completeness percentage
                setCompleteness(completeness);
                setLoading(false); // Set loading to false after data is loaded
            })
            .catch((error) => {
                console.error('Error fetching variant completeness stats:', error);
                setLoading(false);
            });
    }, []);

    // Determine the progress bar color based on completeness
    const progressColor = loading ? '#d9d9d9' : completeness > 90 ? 'green' : completeness > 50 ? 'orange' : 'red';

    return (
        <div className={'mb-2'}>
            <Flex gap={3} align={'center'}>
                <div className={'p-0 m-0'}>Variants</div>
                <Progress
                    percent={loading ? 0 : completeness} // Show 0% when loading
                    status="active"
                    strokeColor={progressColor} // Dynamic color based on completeness or gray when loading
                    trailColor="#d9d9d9" // Gray color for the remaining part
                    showInfo={true} // Show percentage text
                />
            </Flex>
        </div>
    );
};

export default VariantCompletenessLine;
