import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios';

const { Option } = Select;

const IntegrationsMultiDropDown = ({ value = [], onChange }) => {
    const [integrations, setIntegrations] = useState([]);
    const [loading, setLoading] = useState(true); // Loader state

    useEffect(() => {
        // Fetch integrations from the API
        const fetchIntegrations = async () => {
            try {
                const response = await axios.get('/api/integrations');
                setIntegrations(response.data.integrations); // Assuming response contains an array of integrations
                setLoading(false); // Stop loading when data is fetched
            } catch (error) {
                console.error("Failed to fetch integrations", error);
                setLoading(false); // Stop loading even if there is an error
            }
        };

        fetchIntegrations();
    }, []); // Only run once when component mounts

    if(value) {
        value = value.filter(v => v !== null && v !== undefined && v !== '');
    }
    else {
        value = [];
    }

    return (
        <>
            {loading ? (
                <Spin /> // Show loader while data is loading
            ) : (
                <Select
                    mode="multiple"
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select Integrations"
                    optionFilterProp="children"
                    value={value} // Bind value to the selected integrations
                    onChange={onChange} // Trigger onChange when selection changes
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {integrations.map((integration) => (
                        <Option key={integration.id} value={integration.id}>
                            {integration.name}
                        </Option>
                    ))}
                </Select>
            )}
        </>
    );
};

export default IntegrationsMultiDropDown;
