import React, { useState, useEffect } from 'react';
import { Progress, Flex, Card, Statistic, Tooltip } from 'antd';
import axios from 'axios';
import {
    CheckCircleOutlined,
    LoadingOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

const BulkEditProcessedCounter = () => {
    // State for storing stats
    const [stats, setStats] = useState({
        completed: 0,
        failed: 0,
        inProgress: 0,
        completedPercentage: 0,
        failedPercentage: 0,
        inProgressPercentage: 0,
    });

    // Fetch stats from the API
    useEffect(() => {
        axios
            .get('/api/stats/bulk-edit/stats')
            .then((response) => {
                const {
                    completed,
                    failed,
                    in_progress,
                    completed_percentage,
                    failed_percentage,
                    in_progress_percentage,
                } = response.data;

                // Update state with API response
                setStats({
                    completed,
                    failed,
                    inProgress: in_progress,
                    completedPercentage: completed_percentage,
                    failedPercentage: failed_percentage,
                    inProgressPercentage: in_progress_percentage,
                });
            })
            .catch((error) => {
                console.error('Error fetching stats bulk edit:', error);
            });
    }, []);

    return (
        <div className={'mb-2'}>
            <Card variant="borderless" size={'small'}>
                <Flex gap={20} justify={'space-between'}>
                    <Statistic
                        title="Processed"
                        value={stats.completed}
                        valueStyle={{ color: '#3F8600' }}
                        prefix={<CheckCircleOutlined />}
                    />
                    <Statistic
                        title="In progress"
                        value={stats.inProgress}
                        valueStyle={{ color: '#e06315' }}
                        prefix={<LoadingOutlined />}
                    />
                    <Statistic
                        title="Failed"
                        value={stats.failed}
                        valueStyle={{ color: '#cf1322' }}
                        prefix={<ExclamationCircleOutlined />}
                    />
                </Flex>
                {
                    stats.inProgress > 0
                    ?
                        <>
                            Current edit's Progress{' '}
                            <Tooltip
                                title={
                                    'Sometimes bulk edits can take up to 24 hours depending on the complexity and amount of data.'
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            <Progress
                                percent={stats.completedPercentage} // Use completed percentage from API
                                status="active"
                                strokeColor={'#3F8600'}
                                trailColor="#d9d9d9" // Gray color for the remaining part
                                showInfo={true} // Show percentage text
                            />
                        </>
                        :
                        null
                }
            </Card>
        </div>
    );
};

export default BulkEditProcessedCounter;
