import React from 'react';
import { Table, Button, Card, Row, Col } from 'antd';
import { Inertia } from '@inertiajs/inertia';
import TopBarWrapper from "../../components/Admin/TopBarWrapper";
import SubscriptionLineGraph from "../../components/Admin/SubscriptionLineGraph";

const Dashboard = ({ users, shopifyLogins, wooLogins, manualLogins, paidLogins }) => {
    const impersonate = (id) => {
        Inertia.post(`/admin/impersonate/${id}`);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Login Type',
            dataIndex: 'login_type',
            key: 'login_type',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className={'flex gap-1'}>
                    <Button onClick={() => impersonate(record.id)}>Login as</Button>
                </div>
            ),
        },
    ];

    return (
        <TopBarWrapper>
            <div className="mb-4">
                <Row gutter={16}>
                    <Col span={6}>
                        <Card title="Shopify Logins" bordered={false}>
                            {shopifyLogins}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Woo Logins" bordered={false}>
                            {wooLogins}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Manual Logins" bordered={false}>
                            {manualLogins}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Active Paid" bordered={false}>
                            {paidLogins}
                        </Card>
                    </Col>
                </Row>
            </div>
            <SubscriptionLineGraph />
            <Table dataSource={users} columns={columns} />
        </TopBarWrapper>
    );
};

export default Dashboard;
