import React, {useEffect, useState} from "react";
import { Button, Form, Input, Tag, Tooltip, Spin, message } from "antd";
import { InfoCircleOutlined, RightCircleTwoTone, RobotOutlined, LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import axios from 'axios';

export default function ProductTagsField({ product, onUpdateProduct }) {
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState(product?.tags ? product?.tags.split(',') : []);
    const [AIInputValue, setAIInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            const newTags = inputValue.split(',').map(tag => tag.trim()).filter(tag => tag);
            setTags([...tags, ...newTags]);
            setInputValue('');
            e.preventDefault();  // Prevent default behavior of Enter key
        }
    };

    useEffect(() => {
        if (onUpdateProduct) {
            onUpdateProduct({ ...product, tags: tags.join(', ') });
        }
    }, [tags]);

    const handleAIInputChange = (e) => {
        setAIInputValue(e.target.value);
    };

    const handleGenerateAI = async () => {
        if (loading) return; // Prevent multiple requests
        setLoading(true);
        setSuccess(false);
        try {
            const response = await axios.post(`/api/products/${product.id}/generate/tags`, { additional_instructions: AIInputValue });
            if (response.status === 200) {
                setSuccess(true);
                setAIInputValue(''); // Clear input field
                setTags(response.data?.tags?.split(','));
                setTimeout(() => {
                    setSuccess(false);
                }, 1000); // Set success to false after 1 second
            }
        } catch (error) {
            if(error.response.status === 402) {
                message.error(<span>Your AI Credits are finished. Subscribe now to upgrade your plan.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            }
            else if(error.response.status === 422) {
                message.error('Please type some message.');
            }
            else {
                message.error('Our servers are busy at the moment please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleAIKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleGenerateAI();
        }
    };

    const spinIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;
    const successIcon = <CheckOutlined style={{ color: 'green', fontSize: 14 }} />;

    return (
        <div>
            <label>Tags:</label><br />
            {tags.map((tag, index) => (
                <Tag color={'processing'} key={index} className={'mb-1'} closable onClose={() => setTags(tags.filter((_, i) => i !== index))}>
                    {tag}
                </Tag>
            ))}
                <Input size="large"
                       value={inputValue}
                       className="border-b-0 rounded-bl-none rounded-br-none"
                       placeholder="Product tags"
                       onChange={handleInputChange}
                       onKeyDown={handleInputKeyDown}
                />
                <Input className="mb-2 border-t-0 rounded-tl-none rounded-tr-none"
                       placeholder="Write something to generate through AI."
                       prefix={loading ? <Spin indicator={spinIcon} /> : (success ? successIcon : <RobotOutlined />)}
                       suffix={
                           <Button
                               size="small"
                               className="border-0 p-0 m-0 h-3"
                               onClick={handleGenerateAI}
                               disabled={loading}
                           >
                               <RightCircleTwoTone />
                           </Button>
                       }
                       value={AIInputValue}
                       onChange={handleAIInputChange}
                       onKeyDown={handleAIKeyDown}
                       disabled={loading}
                />
        </div>
    );
}
