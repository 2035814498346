import React, { useEffect, useState } from "react";
import { Button, message, Input, Tag, Tooltip, Spin } from "antd";
import { Head, usePage } from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import {CheckOutlined, HeartFilled, LoadingOutlined, RightCircleTwoTone, RobotOutlined} from "@ant-design/icons";
import axios from "axios";
import ProductCreateAI from "../components/Forms/ProductCreateAI";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";
import WelcomeBanner from "@/components/Cards/WelcomeBanner";

const NoProductDashboard = ({ user }) => {
    const { props } = usePage();
    const { success } = props;

    const [AIInputValue, setAIInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false); // Local state for success message

    useEffect(() => {
        if (success) {
            message.success(success);
        }
    }, [success]);

    const handleGenerateAI = async () => {
        if (loading) return; // Prevent multiple requests
        setLoading(true);
        setSuccessMessage(false);
        try {
            const response = await axios.post('/api/products/store/ai', { message: AIInputValue });
            console.log(response.status);

            setSuccessMessage(true);
            setTimeout(() => {
                setSuccessMessage(false);
            }, 1000);
            setAIInputValue(''); // Clear input field
            window.location.href = `/products/${response.data.product.id}/edit`;
        } catch (error) {
            if (error.response && error.response.status === 422) {
                message.error('You are out of your balance, please subscribe to our plan.');
            } else {
                message.error('Our servers are busy, please try again.');
            }
        } finally {
            setLoading(false);
        }

    };

    const handleInputChange = (e) => {
        setAIInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleGenerateAI();
        }
    };

    const handleDefaultPrompts = (prompt) => {
        setAIInputValue(prompt);
    };

    return (
        <AuthSideBarWrapper>
            <div style={{height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Head>
                    <title>Dashboard No Products Found</title>
                </Head>
                <WelcomeBanner />
                <div className={'flex justify-between'} style={{width: '100%'}}>
                    <Title level={3} style={{margin: 0}}>{user.name}, looks like you do not have any products In your
                        store yet!</Title>
                </div>
                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                    <div style={{maxWidth: '600px', width: '100%', textAlign: 'center'}}>
                        <Title className={'m-0'} level={3}>Add your 1st product with AI and make your idea come to life!
                            ✨</Title>
                        <p>Add your products with just a few prompts and keywords with less than a minute that your
                            customers will <HeartFilled className={'text-red-700'}/></p>
                        <div className={'mb-3'}>
                            <ProductCreateAI/>
                        </div>
                        <div>
                            <Button type={"primary"} onClick={() => window.location.href = '/import/templates/create'}>Import
                                Through CSV</Button>
                        </div>
                        {/*<Input*/}
                        {/*    size={'large'}*/}
                        {/*    className={`mb-2 ${loading ? 'blinking-border' : ''}`}*/}
                        {/*    placeholder="e.g: I want to sell a Iphone cover with white color."*/}
                        {/*    prefix={loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} /> : (success ? <CheckOutlined style={{ color: 'green', fontSize: 14 }} /> : <RobotOutlined />)}*/}
                        {/*    suffix={*/}
                        {/*        <Button*/}
                        {/*            size="small"*/}
                        {/*            className="border-0 p-0 m-0 h-3"*/}
                        {/*            onClick={handleGenerateAI}*/}
                        {/*            disabled={loading}*/}
                        {/*        >*/}
                        {/*            <RightCircleTwoTone />*/}
                        {/*        </Button>*/}
                        {/*    }*/}
                        {/*    value={AIInputValue}*/}
                        {/*    onChange={handleInputChange}*/}
                        {/*    onKeyDown={handleKeyDown}*/}
                        {/*    disabled={loading}*/}
                        {/*/>*/}
                        {/*<Tooltip title="Get help from suggested prompts, Click on the prompt to edit.">*/}
                        {/*    <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>*/}
                        {/*        <Tag color="default" bordered={false} className={'bg-transparent'}>Example Prompts:</Tag>*/}
                        {/*        <p className={'cursor-pointer mb-1 wordBreak px-2 border rounded m-1 bg-gray-100 text-[12px]'}*/}
                        {/*           onClick={() => handleDefaultPrompts("Generate a product for the keywords, ")}*/}
                        {/*        >Generate a product for the keywords, </p>*/}
                        {/*        <p*/}
                        {/*            onClick={() => handleDefaultPrompts("I have to create a product with variants, ")}*/}
                        {/*            className={'cursor-pointer mb-1 wordBreak px-2 border rounded m-1 bg-gray-100 text-[12px]'}>I have to create a product with variants, </p>*/}
                        {/*        <p*/}
                        {/*            onClick={() => handleDefaultPrompts("Create a product for, ____ in spanish language!")}*/}
                        {/*            className={'cursor-pointer mb-1 wordBreak px-2 border rounded m-1 bg-gray-100 text-[12px]'}>Create a product for, ____ in spanish language!</p>*/}
                        {/*    </div>*/}
                        {/*</Tooltip>*/}
                    </div>
                </div>
            </div>
        </AuthSideBarWrapper>
    );
};

export default NoProductDashboard;
