import React, {useState} from 'react';
import {Button, message} from 'antd';
import axios from "axios";

export function SyncToShopify({ product, onSyncSuccessful = (product) => {} }) {
    const [loader, setLoader] = useState(false);

    const syncToShopifyHandler = async () => {
        setLoader(true);
        if (product.id) {
            axios.post(`/api/products/${product.id}/edit`, product)
                .then((response) => {
                    message.success("Saved Successfully");
                    onSyncSuccessful(response.data.product);
                })
                .catch((error) => {
                    message.error("Maybe our servers are busy right now. Please try again.");
                })
                .finally(() =>  {
                    setLoader(false);
                });
        }
        else {
            axios.post(`/api/products/store`, product)
                .then((response) => {
                    message.success("Synced to Shopify");
                    onSyncSuccessful(response.data.product);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        // Extract error messages from the response
                        const errors = error.response.data.errors;

                        // Loop over errors and show each using message.error
                        Object.keys(errors).forEach((field) => {
                            errors[field].forEach((errorMessage) => {
                                message.error(errorMessage);
                            });
                        });
                    }
                    else {
                        message.error("Maybe our servers are busy right now. Please try again.");
                    }
                })
                .finally(() =>  {
                    setLoader(false);
                });
        }
    };

    return (
        <Button
            type={"primary"}
            loading={loader}
            onClick={syncToShopifyHandler}
        >Update</Button>
    );
}
