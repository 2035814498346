import React, {useState} from "react";
import {Card, message, Progress, Grid, Popover, Tooltip, Button, Flex, Empty} from "antd";
import {InfoCircleOutlined, RobotOutlined} from "@ant-design/icons";

const { useBreakpoint } = Grid;

const MediaGrid = ({ product, media }) => {
    const screens = useBreakpoint();
    const [loading, setLoading] = useState(false);

    const handleAIFix = async (e, media_id) => {
        try {
            setLoading(true);
            // Replace `yourApiEndpoint` with the actual API endpoint
            const response = await axios.post(`/api/products/${product.id}/media/${media_id}/data/fix`, {
                media_id: media_id,
            });

            // Check if the response is successful
            message.success('Your product data has been updated successfully!');
        } catch (error) {
            // Handle specific status code errors
            if (error.response && error.response.status === 402) {
                message.error(<span>Your AI Credits are finished. Subscribe now to upgrade your plan.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            } else if (error.response && error.response.status === 403) {
                message.error(<span>Please upgrade your plan to use this feature.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            } else {
                message.error('It seems our servers are quite busy right now, please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div
                className="grid gap-4"
                style={{
                    gridTemplateColumns: screens.lg
                        ? "repeat(3, 1fr)"
                        : screens.md
                            ? "repeat(2, 1fr)"
                            : "repeat(1, 1fr)",
                }}
            >
                {media.map((media, index) => (
                    <Card
                        size={'small'}
                        key={index}
                        title={
                            <Flex justify={'space-between'} align={'center'}>
                                <Button
                                    size={'small'}
                                    type="primary"
                                    onClick={(e) => handleAIFix(e, media.id)}
                                    loading={loading}
                                >
                                    {
                                        loading
                                            ?
                                            null
                                            :
                                            <RobotOutlined/>
                                    }
                                    Fix Product Data
                                </Button>
                                <Popover
                                    placement={'bottomRight'}
                                    title="Issues"
                                    content={
                                        <ul className="list-disc pl-4">
                                            {media?.issues?.length > 0 ? (
                                                media.issues.map((issue, idx) => <li key={idx}>{issue}</li>)
                                            ) : (
                                                <p>No issues found</p>
                                            )}
                                        </ul>
                                    }
                                >
                                    <div className="text-right">
                                        <InfoCircleOutlined
                                            className="cursor-pointer text-gray-500 hover:text-blue-600"/>
                                    </div>
                                </Popover>
                            </Flex>
                        }
                        cover={<img src={media.url} alt={media.name} className="rounded-xl"/>}
                        className="shadow-lg border rounded-xl"
                    >
                        {/*<div className="mb-2">*/}
                        {/*    <b>Completeness:</b>*/}
                        {/*    <Tooltip title={media.completeness ? "Media Completeness Score" : 'Calculating...'}>*/}
                        {/*        <Progress*/}
                        {/*            percent={media.completeness}*/}
                        {/*            size="small"*/}
                        {/*            status="active"*/}
                        {/*            showInfo*/}
                        {/*        />*/}
                        {/*    </Tooltip>*/}
                        {/*</div>*/}

                        <div className="text-sm">
                            <p><strong>Alt Text:</strong> {media.alt_text}</p>
                            {/*<p><strong>Size:</strong> {media.size ? media.size + ' KB' : <small>Calculating...</small>}</p>*/}
                            <p><strong>Dimensions:</strong> {media.width}x{media.height} px</p>
                        </div>
                    </Card>
                ))}

            </div>
            {
                media.length === 0 && (
                    <div className="text-center w-full">
                        <Empty description={'No Image found for this product yet.'} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    </div>
                )
            }
        </div>
    );
};

export default MediaGrid;
