import React, { useState, useEffect } from 'react';
import { Table, Input, Row, Col, Button, message, Avatar, Popover, Tag, Progress, Switch, Spin } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import axios from 'axios';
import { debounce } from 'lodash';

export default function VariantsTable({ product }) {
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [filteredVariants, setFilteredVariants] = useState([]);
    const [editingValues, setEditingValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false); // New state for update button loading

    const { Search } = Input;

    useEffect(() => {
        if (product && product.id) {
            fetchVariants(product.id);
        }
    }, [product]);

    const fetchVariants = async (productId, searchTerm = '') => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/products/${productId}/variants`, {
                params: { q: searchTerm }
            });
            const variants = response.data.variants;
            setDataSource(variants);
            setFilteredVariants(variants);
        } catch (error) {
            console.error("Error fetching variants:", error);
            message.error("Failed to fetch variants.");
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = debounce((value) => {
        setSearchValue(value);
        fetchVariants(product.id, value);
    }, 500);

    const handleInputChange = (value, key, record) => {
        setEditingValues((prev) => ({
            ...prev,
            [record.id]: {
                ...prev[record.id],
                [key]: value, // Directly update the value, even if it's an empty string
            },
        }));
    };

    const handleSave = async () => {
        setUpdating(true); // Set updating state to true
        setLoading(true); // Set loading state to true for the table
        try {
            const productId = product.id;
            const updatedVariants = dataSource.map(item => ({
                ...item,
                ...editingValues[item.id],
            }));

            await axios.post(`/api/products/${productId}/variants/update`, { variants: updatedVariants });

            setDataSource(updatedVariants);
            setFilteredVariants(updatedVariants);
            message.success("Variants updated successfully!");

            // Refetch variants according to the search query
            await fetchVariants(productId, searchValue);
        } catch (error) {
            console.error("Error saving data:", error);
            message.error("Our server is busy right now, please try again.");
        } finally {
            setUpdating(false); // Set updating state to false
            setLoading(false); // Set loading state to false for the table
        }
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 45,
            render: (text, record) => (
                <Avatar src={record.media.length > 0 ? record.media[0].url : ''} shape="square" size="small" icon={<FileImageOutlined />} />
            )
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 800,
            render: (text, record) => (
                <>
                    <span className={'text-[12px]'}>{JSON.parse(record.options).join(' / ')}</span>
                    <Input
                        value={editingValues[record.id]?.title ?? text} // Use nullish coalescing to fallback to the original value
                        onChange={(e) => handleInputChange(e.target.value, 'title', record)}
                    />
                </>
            ),
        },
        {
            title: 'Available For Sale',
            dataIndex: 'available_for_sale',
            key: 'available_for_sale',
            width: 400,
            render: (text, record) => (
                <Switch
                    checked={editingValues[record.id]?.available_for_sale ?? text} // Use nullish coalescing
                    onChange={(checked) => handleInputChange(checked, 'available_for_sale', record)}
                />
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 400,
            render: (text, record) => (
                <Input
                    value={editingValues[record.id]?.price ?? text} // Use nullish coalescing
                    onChange={(e) => handleInputChange(e.target.value, 'price', record)}
                />
            ),
        },
        {
            title: 'Compare At Price',
            dataIndex: 'compare_at_price',
            key: 'compare_at_price',
            width: 400,
            render: (text, record) => (
                <Input
                    value={editingValues[record.id]?.compare_at_price ?? text} // Use nullish coalescing
                    onChange={(e) => handleInputChange(e.target.value, 'compare_at_price', record)}
                />
            ),
        },
        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'barcode',
            width: 400,
            render: (text, record) => (
                <Input
                    value={editingValues[record.id]?.barcode ?? text} // Use nullish coalescing
                    onChange={(e) => handleInputChange(e.target.value, 'barcode', record)}
                />
            ),
        },
        {
            title: 'Sku',
            dataIndex: 'sku',
            key: 'sku',
            width: 400,
            render: (text, record) => (
                <Input
                    value={editingValues[record.id]?.sku ?? text} // Use nullish coalescing
                    onChange={(e) => handleInputChange(e.target.value, 'sku', record)}
                />
            ),
        },
        {
            title: 'Issues',
            dataIndex: 'issues',
            key: 'issues',
            width: 100,
            render: (issues, record) => (
                <>
                    <Popover
                        title={record.issues && JSON.parse(record.issues).length > 0 ? "Issues" : "You Did A Good Job!"}
                        content={record.issues && JSON.parse(record.issues).length > 0
                            ? <ul>{JSON.parse(record.issues).map((issue, index) => <li key={index}>{issue}</li>)}</ul>
                            : "This product has the potential to make its mark on search engines"}
                        overlayStyle={{ maxWidth: 400 }}
                        placement={'left'}
                    >
                        <Tag color={record.issues && JSON.parse(record.issues).length > 0 ? "magenta" : "green"}>
                            {record.issues && JSON.parse(record.issues).length > 0 ? `${JSON.parse(record.issues).length} critical issues` : 'Perfect'}
                        </Tag>
                    </Popover>
                    <Progress
                        size={"small"}
                        percent={record.completeness}
                        strokeColor={record.completeness > 90 ? 'green' : (record.completeness > 50 ? 'orange' : 'red')}
                    />
                </>
            ),
        }
    ];

    return (
        <>
            <div className='w-100 bg-white rounded p-2 mb-1'>
                <Row className="justify-between">
                    <Col className={'mb-2'}>
                        <Search
                            placeholder="Search variant by title, price, sku, or barcode"
                            onChange={e => handleSearch(e.target.value)}
                            style={{ width: 300 }}
                        />
                    </Col>
                    <Col>
                        <Button type={'primary'} onClick={handleSave} loading={updating}>
                            Update Variants
                        </Button>
                    </Col>
                </Row>
            </div>
            <Spin spinning={loading}>
                <Table
                    size={'small'}
                    pagination={{ pageSize: 100 }}
                    columns={columns}
                    dataSource={filteredVariants}
                    rowKey="id"
                />
            </Spin>
        </>
    );
}
