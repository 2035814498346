import React, { useEffect, useState } from 'react';
import {Avatar, Card, Progress, Spin, Tooltip} from "antd";
import Pusher from "pusher-js";
import Echo from "laravel-echo";

export default function JobProgressCard() {
    const [progress, setProgress] = useState(1);
    const [message, setMessage] = useState("Message goes here");
    const [timeRemaining, setTimeRemaining] = useState('');
    const [jobNotifications, setJobNotifications] = useState([]);

    useEffect(() => {
        const fetchJobNotifications = async () => {
            try {
                const response = await axios.get('/api/job-notification', {
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                setJobNotifications(response.data.job_notifications);
            } catch (error) {
                console.error('Error fetching job notifications:', error);
            }
        };

        fetchJobNotifications().then();
    }, []); // Adding the empty array as a dependency to ensure it runs only once

    useEffect(() => {
        // Get the last notification message
        if (jobNotifications.length > 0) {
            const lastNotification = jobNotifications[jobNotifications.length - 1];
            setMessage(lastNotification.data.message);
        }
    }, [jobNotifications]);

    if(jobNotifications.length > 0) {
        return (
            <Card size={'small'} className={'mb-3'}>
                <div className={'flex items-center gap-2'}>
                    <Avatar.Group>
                        {jobNotifications.map(notification => {
                            return (
                                <Tooltip key={notification.id} title={notification.message} placement="top">
                                    <Avatar style={{ backgroundColor: '#00b96b' }}>
                                        O
                                    </Avatar>
                                </Tooltip>
                            )
                        })}
                    </Avatar.Group>
                    <div className={'w-full'}>
                        <p className={'mb-0 mt-0 font-medium text-[14px]'}>{message} <Spin /></p>
                    </div>
                </div>
            </Card>
        );
    }
    else return "";
}
