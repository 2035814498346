import React, { useState, useEffect } from "react";
import { Avatar, Button, Card, Checkbox, Col, Form, Grid, Input, Row, theme, Typography, message } from "antd";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import logo from '../../../public/media/logoIcon.png';
import axios from "axios";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function Login({GOOGLE_SITE_KEY}) {
    const { token } = useToken();

    const [loading, setLoading] = useState(false); // State to handle button loading

    // Load reCAPTCHA script dynamically when the component mounts
    useEffect(() => {
        const loadReCaptcha = () => {
            if (!window.grecaptcha) {
                const script = document.createElement("script");
                script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_SITE_KEY}`;
                script.async = true;
                script.onload = () => {
                    console.log('reCAPTCHA script loaded');
                };
                document.head.appendChild(script);
            }
        };
        loadReCaptcha();
    }, []);

    const onFinish = async (values) => {
        setLoading(true); // Show loader on button
        try {
            // Execute reCAPTCHA and get the token
            const recaptchaToken = await window.grecaptcha.execute(GOOGLE_SITE_KEY, { action: 'register' });
            const payload = {
                ...values,
                recaptcha_token: recaptchaToken, // Send the token to the backend
            };

            const response = await axios.post('/api/auth/login', payload);
            message.success('Login successful!');
            window.location.href = ('/dashboard'); // Redirect to dashboard on success
        } catch (error) {
            setLoading(false); // Stop loader
            if (error.response && error.response.data.errors) {
                // Display Laravel validation errors
                const errors = error.response.data.errors;
                Object.values(errors).forEach(errMsg => {
                    message.error(errMsg[0]);
                });
            } else if(error.response.status === 401) {
                message.error('Email or Password does not match our records.');
            } else {
                message.error('Our Servers are busy right now, please try again later!');
            }
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = "/auth/google/begin"; // Redirect to Google login
    };

    const styles = {
        container: {
            margin: "0 auto",
            maxWidth: "780px"
        },
        footer: {
            marginTop: token.marginLG,
            textAlign: "center",
            width: "100%"
        },
        forgotPassword: {
            float: "right"
        },
        header: {
            marginBottom: token.marginXL
        },
    };

    return (
        <section style={styles.section}>
            <div style={styles.container}>
                <Row
                    gutter={20}
                    justify="center"
                    align="middle"
                    style={{minHeight: '100vh'}} // This ensures vertical center alignment
                >
                    {/* Hidden on mobile, visible on larger screens */}
                    <Col xs={0} md={12}>
                        <div style={styles.header}>
                            <Avatar shape="square" size="large" src={logo} icon={<UserOutlined/>}/>
                            <Title style={styles.title}>ShopGPT: AI built for Commerce Success!</Title>
                            <Text style={styles.text}>
                                Experience a free trial first, then kickstart with $5 – 💳 no credit card needed until you’re ready to pay.
                            </Text>
                            <button
                                type="button"
                                onClick={handleGoogleLogin}
                                className="w-full mt-5 bg-white border-0 text-gray-800 border-gray-200 px-4 py-2 cursor-pointer hover:bg-white hover:border-gray-300 shadow-md flex justify-center items-center gap-2"
                            >
                                <svg className="w-6 h-6 text-black dark:text-white" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                     viewBox="0 0 24 24">
                                    <path fillRule="evenodd"
                                          d="M12.037 21.998a10.313 10.313 0 0 1-7.168-3.049 9.888 9.888 0 0 1-2.868-7.118 9.947 9.947 0 0 1 3.064-6.949A10.37 10.37 0 0 1 12.212 2h.176a9.935 9.935 0 0 1 6.614 2.564L16.457 6.88a6.187 6.187 0 0 0-4.131-1.566 6.9 6.9 0 0 0-4.794 1.913 6.618 6.618 0 0 0-2.045 4.657 6.608 6.608 0 0 0 1.882 4.723 6.891 6.891 0 0 0 4.725 2.07h.143c1.41.072 2.8-.354 3.917-1.2a5.77 5.77 0 0 0 2.172-3.41l.043-.117H12.22v-3.41h9.678c.075.617.109 1.238.1 1.859-.099 5.741-4.017 9.6-9.746 9.6l-.215-.002Z"
                                          clipRule="evenodd"/>
                                </svg>

                                Login with Google Account
                            </button>
                        </div>
                    </Col>

                    {/* Full width on mobile, column layout on larger screens */}
                    <Col xs={24} md={12}>
                        <Card>
                            <div style={styles.header}>
                                <Title style={styles.title} className={'m-0'}>Login Account</Title>
                                <Text style={styles.text}>
                                    Welcome Back.
                                </Text>
                            </div>
                            <Form
                                name="normal_login"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                layout="vertical"
                                requiredMark="optional"
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: "email",
                                            required: true,
                                            message: "Please input your Email!",
                                        },
                                    ]}
                                >
                                    <Input size="large" prefix={<MailOutlined />} placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your Password!",
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        type="password"
                                        size="large"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Checkbox>Remember me</Checkbox>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "0px" }}>
                                    <Button block type="primary" size="large" htmlType="submit" loading={loading}>
                                        {loading ? 'Logging In...' : 'Login'}
                                    </Button>
                                    <div style={styles.footer}>
                                        <Text style={styles.text}>Don't have an account?</Text>{" "}
                                        <Link href="/register">Create account now</Link>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        </section>
    );
}
