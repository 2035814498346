import {Button, Flex} from "antd";

import {Head} from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import BlogsTable from "@/components/Tables/BlogsTable";
import {RobotOutlined} from "@ant-design/icons";
import ArticlesTable from "../components/Tables/ArticlesTable";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function BlogIndex({}) {
    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Blogs</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                <Title level={3} className={'m-0'}>Blogs</Title>
                <Button
                    onClick={() => window.location.href = '/articles/create'}
                    type={'dashed'}
                    className={'bg-green-600 text-white'}><RobotOutlined /> Create with AI</Button>
            </Flex>
            <ArticlesTable />
        </AuthSideBarWrapper>
    )
}
