import { Button, Form, Input, Spin, message } from "antd";
import { LoadingOutlined, RightCircleTwoTone, RobotOutlined, CheckOutlined } from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import axios from 'axios';

export default function ProductTitleField({ product, onUpdateProduct }) {
    const [title, setTitle] = useState(product.title);
    const [AIInputValue, setAIInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    useEffect(() => {
        if (onUpdateProduct) {
            onUpdateProduct({ ...product, title: title });
        }
    }, [title]);

    const handleInputChange = (e) => {
        setAIInputValue(e.target.value);
    };

    const handleGenerateAI = async () => {
        if (loading) return; // Prevent multiple requests
        setLoading(true);
        setSuccess(false);
        try {
            const response = await axios.post(`/api/products/${product.id}/generate/title`, { additional_instructions: AIInputValue });
            if (response.status === 200) {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 1000);
                setAIInputValue(''); // Clear input field
                setTitle(response?.data?.title);
            }
        } catch (error) {
            if(error.response.status === 402) {
                message.error(<span>Your AI Credits are finished. Subscribe now to upgrade your plan.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            }
            else if(error.response.status === 422) {
                message.error('Please type some message.');
            }
            else {
                message.error('Our servers are busy at the moment please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleGenerateAI();
        }
    };

    const spinIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;
    const successIcon = <CheckOutlined style={{ color: 'green', fontSize: 14 }} />;

    return (
            <>
                <label>Title:</label>
                <Input size="large"
                       value={title}
                       className="border-b-0 rounded-bl-none rounded-br-none"
                       placeholder="Product Title"
                       onChange={handleTitleChange}
                />
                <Input
                    className="mb-2 border-t-0 rounded-tl-none rounded-tr-none"
                    placeholder="Write something to generate through AI."
                    prefix={loading ? <Spin indicator={spinIcon} /> : (success ? successIcon : <RobotOutlined />)}
                    suffix={
                        <Button
                            size="small"
                            className="border-0 p-0 m-0 h-3"
                            onClick={handleGenerateAI}
                            disabled={loading}
                        >
                            <RightCircleTwoTone />
                        </Button>
                    }
                    value={AIInputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    disabled={loading}
                />
            </>
    );
}
