import { useRef, useState, useEffect } from "react";
import { Button, Card, Flex, Modal, Input, message } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import AIChat from "@/components/Forms/AIChat";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";
import { Head, router } from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import { RobotOutlined } from "@ant-design/icons";

const BlogCreate = ({ blog }) => {
    const [blogContent, setBlogContent] = useState(blog?.body_html || "");
    const [blogObject, setBlogObject] = useState(blog || {});
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [blogTitle, setBlogTitle] = useState(blog?.title || "");
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const editorRef = useRef(null);

    // Handle AI suggestions
    const handleAISuggestion = (suggestion) => {
        setBlogContent(suggestion);
        setHasUnsavedChanges(true); // Mark as unsaved
    };

    // Handle editor content change
    const handleEditorChange = (content) => {
        if (content) {
            setBlogContent(content);
            setHasUnsavedChanges(true); // Mark as unsaved
        }
    };

    // Handle Save Blog button click
    const handleSaveClick = () => {
        setIsModalOpen(true); // Open the modal
    };

    // Handle Save Blog (after entering title)
    const handleSaveBlog = async () => {
        try {
            setLoading(true);
            const url = blogObject?.id
                ? `/api/articles/${blogObject.id}/update`
                : '/api/articles/store';

            const method = blogObject?.id ? 'PUT' : 'POST';

            const payload = {
                ...blogObject,
                title: blogTitle,
                body_html: blogContent,
            };

            const response = await axios(url, {
                method: method,
                headers: {
                    'Accept': 'application/json',
                },
                data: payload,
            });

            if (response.data) {
                setBlogObject(response.data.article); // Update blog object with new data
                setHasUnsavedChanges(false); // Reset unsaved changes flag
                message.success('Blog saved successfully!');
            }
        } catch (error) {
            console.error('Error saving the blog:', error);
            message.error('Failed to save the blog. Please try again later.');
        } finally {
            setLoading(false);
            setIsModalOpen(false); // Close the modal
        }
    };

    // Handle unsaved changes warning
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue = ''; // Required for Chrome
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Blogs</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                <Title level={3} className={'m-0'}>{ blog?.title ? blog?.title : 'Create Blog' }</Title>
                <Button
                    loading={loading}
                    onClick={handleSaveClick}
                    className={'bg-green-600 text-white'}>Save Blog</Button>
            </Flex>
            <div className="flex h-[calc(100vh-67px)] bg-gray-50">
                {/* Left Column - AI Chat */}
                <div className="w-[40%] border-r border-gray-200">
                    <AIChat onSuggestion={handleAISuggestion} updatedContent={blogContent} />
                </div>

                {/* Right Column - HTML Editor */}
                <div className="w-[60%]">
                    <Card bordered={false} className="h-full" styles={{body:{padding: 0, height: "100%"}}}>
                        <Editor
                            apiKey="your-tinymce-api-key" // Replace with your TinyMCE API key
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            onEditorChange={handleEditorChange}
                            initialValue={blog?.body_html || ""}
                            value={blogContent}
                            init={{
                                height: "100%",
                                menubar: true,
                                plugins: [
                                    "advlist",
                                    "autolink",
                                    "lists",
                                    "link",
                                    "image",
                                    "charmap",
                                    "preview",
                                    "anchor",
                                    "searchreplace",
                                    "visualblocks",
                                    "code",
                                    "fullscreen",
                                    "insertdatetime",
                                    "media",
                                    "table",
                                    "code",
                                    "help",
                                    "wordcount",
                                ],
                                toolbar:
                                    "undo redo | blocks | " +
                                    "bold italic forecolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent | " +
                                    "removeformat | help"
                            }}
                        />
                    </Card>
                </div>
            </div>

            {/* Modal for Blog Title */}
            <Modal
                title="Save Blog"
                open={isModalOpen}
                onOk={handleSaveBlog}
                onCancel={() => setIsModalOpen(false)}
                okText="Save"
                cancelText="Cancel"
                confirmLoading={loading}
            >
                <Input
                    placeholder="Enter blog title"
                    value={blogTitle}
                    onChange={(e) => setBlogTitle(e.target.value)}
                />
            </Modal>
        </AuthSideBarWrapper>
    );
};

export default BlogCreate;
