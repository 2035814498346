import { Button, Form, Input, Space, Tooltip, Spin, message } from "antd";
import { InfoCircleOutlined, RightCircleTwoTone, RobotOutlined, LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import React, {useEffect, useRef, useState} from "react";
import axios from 'axios';

export default function ProductDescriptionField({ product, onUpdateProduct }) {
    const editorRef = useRef(null);
    const [bodyHtml, setBodyHtml] = useState(product.body_html);
    const [AIInputValue, setAIInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleDescriptionChange = (event, editor) => {
        setBodyHtml(editor.getContent());
    };

    useEffect(() => {
        if (onUpdateProduct) {
            onUpdateProduct({ ...product, body_html: bodyHtml });
        }
    }, [bodyHtml]);

    const handleInputChange = (e) => {
        setAIInputValue(e.target.value);
    };

    const handleGenerateAI = async () => {
        if (loading) return; // Prevent multiple requests
        setLoading(true);
        setSuccess(false);
        try {
            const response = await axios.post(`/api/products/${product.id}/generate/body-html`, { additional_instructions: AIInputValue });
            if (response.status === 200) {
                setSuccess(true);
                setBodyHtml(response.data.body_html);
                setAIInputValue(''); // Clear input field
                setTimeout(() => {
                    setSuccess(false);
                }, 1000); // Set success to false after 1 second
            }
        } catch (error) {
            if(error.response.status === 402) {
                message.error(<span>Your AI Credits are finished. Subscribe now to upgrade your plan.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            }
            else if(error.response.status === 422) {
                message.error('Please type some message.');
            }
            else {
                message.error('Our servers are busy at the moment please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleGenerateAI();
        }
    };

    const spinIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;
    const successIcon = <CheckOutlined style={{ color: 'green', fontSize: 14 }} />;

    return (
        <>
            <label>
                Description:
            </label>
            <Editor
                initialValue={bodyHtml}
                onChange={handleDescriptionChange}
            />
            <Input
                   className="mb-2 border-t-0"
                   placeholder="Write something to generate through AI."
                   prefix={loading ? <Spin indicator={spinIcon} /> : (success ? successIcon : <RobotOutlined />)}
                   suffix={
                       <Button
                           size="small"
                           className="border-0 p-0 m-0 h-3"
                           onClick={handleGenerateAI}
                           disabled={loading}
                       >
                           <RightCircleTwoTone />
                       </Button>
                   }
                   value={AIInputValue}
                   onChange={handleInputChange}
                   onKeyDown={handleKeyDown}
                   disabled={loading}
            />
        </>
    );
}
