import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin } from 'antd';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
} from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const SubscriptionLineGraph = () => {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get('/api/admin/stats/recurring-charges')
            .then((response) => {
                const { labels, data } = response.data;

                // Format labels to show day and month (e.g., "Jan 1")
                const formattedLabels = labels.map((label) => {
                    const date = new Date(label);
                    return date.toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                    });
                });

                setChartData({
                    labels: formattedLabels,
                    datasets: [
                        {
                            label: 'Total Price of Active Subscriptions',
                            data,
                            borderColor: '#3f8600',
                            backgroundColor: 'rgba(63, 134, 0, 0.2)',
                            tension: 0.4,
                        },
                    ],
                });

                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching subscription data:', error);
                setLoading(false);
            });
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.dataset.label}: $${tooltipItem.raw}`;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Day',
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10, // Show fewer labels to avoid clutter
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Total Price ($)',
                },
                beginAtZero: true, // Ensure y-axis starts from 0
                min: 0, // Ensure no negative values are shown
            },
        },
    };

    if (loading) {
        return <Spin />;
    }

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default SubscriptionLineGraph;
