import React, { useEffect, useState } from 'react';
import {Table, Button, Tag, Empty, Col, Row, Flex} from 'antd';
import { Head } from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import axios from 'axios';
import { LoadingOutlined } from "@ant-design/icons";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

const ImportIndex = () => {
    const [loading, setLoading] = useState(true);  // Loader state
    const [templates, setTemplates] = useState([]);    // State for templates data

    // Function to fetch templates from the API
    const fetchTemplates = async () => {
        try {
            const response = await axios.get('/api/import/templates'); // API call
            setTemplates(response.data.templates);                // Set templates data
        } catch (error) {
            console.error('Error fetching templates:', error);  // Handle errors
        } finally {
            setLoading(false);                                // Stop loading
        }
    };

    // useEffect hook to fetch templates on component mount
    useEffect(() => {
        fetchTemplates();
    }, []);

    // Columns for the table
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                text
            ),
        },
        {
            title: 'Mapped Columns',
            dataIndex: 'mapped_columns',
            key: 'mapped_columns',
            width: 100,
            render: (text) => (
                text
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width:70,
            render: (text, record) => (
                <Flex gap={5}>
                    <Button size={'small'} type="primary" onClick={() => handleImport(record)}>
                        Import
                    </Button>
                </Flex>
            ),
        },
    ];

    // Function to handle import click
    const handleImport = (template) => {
        window.location.href = `/import/templates/${template.id}/edit`; // Redirect to edit page
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Import</title>
            </Head>

            <Row justify="space-between" align="middle" className="mb-2">
                <Col>
                    <Title level={4} className="m-0">Import <small>(beta)</small></Title>
                    <p className="m-0 p-0">Import your products through CSVs.</p>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => window.location.href = '/import/templates/create'}>Add New Import</Button>
                </Col>
            </Row>

            {/* Table to display the templates */}
            <Table
                size={'small'}
                dataSource={templates}
                columns={columns}
                loading={loading}
                rowKey="id"
                locale={{ emptyText: <Empty description="No templates found" /> }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            window.location.href = `/import/templates/${record.id}/edit`; // Redirect to edit page
                        },
                        style: { cursor: 'pointer' }
                    };
                }}
            />
        </AuthSideBarWrapper>
    );
};

export default ImportIndex;
