import {Button, Form, Input, Spin, message, Card} from "antd";
import { LoadingOutlined, RightCircleTwoTone, RobotOutlined, CheckOutlined } from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import axios from 'axios';

export default function ProductMetaTitleField({ product, onUpdateProduct }) {

    const [titleTag, setTitleTag] = useState(product.meta_fields ? product?.meta_fields.find(field => field?.key === 'title_tag')?.value : null);
    const [descriptionTag, setDescriptionTag] = useState(product.meta_fields ? product?.meta_fields.find(field => field?.key === 'description_tag')?.value : null);
    const [AIInputValue, setAIInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleTitleChange = (e) => {
        setTitleTag(e.target.value);
    };

    useEffect(() => {
        if (onUpdateProduct && product.meta_fields) {
            let updatedMetaFields = product.meta_fields.map(field =>
                field.key === "title_tag" ? { ...field, value: titleTag } : field
            );

            updatedMetaFields = updatedMetaFields.map(field =>
                field.key === "description_tag" ? { ...field, value: descriptionTag } : field
            );

            onUpdateProduct({ ...product, meta_fields: updatedMetaFields });
        }
    }, [titleTag, descriptionTag]);

    const handleDescriptionChange = (e) => {
        setDescriptionTag(e.target.value);
    };

    const handleAIInputChange = (e) => {
        setAIInputValue(e.target.value);
    };

    const handleGenerateAI = async () => {
        if (loading) return; // Prevent multiple requests
        setLoading(true);
        setSuccess(false);
        try {
            const response = await axios.post(`/api/products/${product.id}/generate/meta-seo`, { additional_instructions: AIInputValue });
            if (response.status === 200) {
                setSuccess(true);
                setAIInputValue(''); // Clear input field
                setTitleTag(response.data?.seo?.metaTitle);
                setDescriptionTag(response.data?.seo?.metaDescription);
                setTimeout(() => {
                    setSuccess(false);
                }, 1000); // Set success to false after 1 second
            }
        } catch (error) {
            if(error.response.status === 402) {
                message.error(<span>Your AI Credits are finished. Subscribe now to upgrade your plan.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            }
            else if(error.response.status === 422) {
                message.error('Please type some message.');
            }
            else {
                message.error('Our servers are busy at the moment please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleAIKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleGenerateAI();
        }
    };

    const spinIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;
    const successIcon = <CheckOutlined style={{ color: 'green', fontSize: 14 }} />;

    return (
        <>
            <Card size={'small'} style={{margin: 0}} title={'Meta Information'}
                  className={'border-gray-300 border-b-0 rounded-bl-none rounded-br-none'}>
                <Input
                    style={{marginBottom: 0}}
                    placeholder="Product Title"
                    value={titleTag}
                    onChange={handleTitleChange}
                />
                <p className={'text-right text-gray-700 m-0 p-0'}><small>{titleTag ? titleTag.length : 0} char</small></p>

                <Input.TextArea
                    placeholder="Product Description"
                    value={descriptionTag}
                    onChange={handleDescriptionChange}
                />
                <p className={'text-right text-gray-700 m-0 p-0'}><small>{descriptionTag ? descriptionTag.length : 0} char</small></p>
            </Card>
            <Input
                className="border-t-0 rounded-tl-none rounded-tr-none"
                placeholder="Write something to generate through AI."
                prefix={loading ? <Spin indicator={spinIcon} /> : (success ? successIcon : <RobotOutlined />)}
                suffix={
                    <Button
                        size="small"
                        className="border-0 p-0 m-0 h-3"
                        onClick={handleGenerateAI}
                        disabled={loading}
                    >
                        <RightCircleTwoTone />
                    </Button>
                }
                value={AIInputValue}
                onChange={handleAIInputChange}
                onKeyDown={handleAIKeyDown}
                disabled={loading}
            />
        </>
    );
}
