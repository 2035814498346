import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import {
    Avatar,
    Button,
    Col,
    Dropdown,
    message,
    Flex,
    Popover,
    Progress,
    Row,
    Space,
    Table,
    Tag,
    Tour
} from "antd";
import {
    FileImageOutlined,
    FilterOutlined,
    RobotOutlined,
    StarOutlined
} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { Input} from 'antd';
import {SyncToShopify} from "../Buttons/SyncToShopify";

export default function CollectionsTable () {

    const [open, setOpen] = useState(false);
    const AIFixBtnRef = useRef(null);
    const ProductPreviewRef = useRef(null);

    const tourSteps = [
        {
            title: 'Fix Your Product Title, Description, Tags and Meta SEO',
            description: 'By clicking on the AI Fix button you can update your product directly to your store with AI content generation.',
            target: () => AIFixBtnRef.current,
        },
        {
            title: 'Preview Product Data',
            description: 'By clicking on the product you can preview and update all the product data individually and then upload it on shopify with one click.',
            target: () => ProductPreviewRef.current,
        }
    ];

    useEffect(() => {
        // Check if the "tourDone" flag is set in localStorage
        const tourDone = localStorage.getItem('tourDone');

        if (!tourDone) {
            // If no tour done status, start the tour
            setOpen(true);
        }
    }, []);

    const handleTourClose = () => {
        // When tour is closed, set the "tourDone" flag in localStorage
        localStorage.setItem('tourDone', 'true');
        setOpen(false);
    };

    const [products, setProducts] = useState([]);
    const [loader, setLoader] = useState(true);
    const [productSearch, setProductSearch] = useState({
        completeness:'all',
        search:'',
        filter:''
    });
    const [loadingProductIds, setLoadingProductIds] = useState([]);

    function fetchProducts(data = {}) {
        setLoader(true);
        axios.get("/api/collections", {
            params: data,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            setProducts(response.data.collections);
        }).finally(() => {
            setLoader(false);
        });
    }

    const handleAIFix = (e, id) => {
        e.stopPropagation();

        setLoadingProductIds(prev => [...prev, id]);

        axios.post(`/api/collections/${id}/fix`, {
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            setProducts(prevProducts =>
                prevProducts.map(product =>
                    product.id === response.data.collection.id ? response.data.collection : product
                )
            );
            message.success('Collection Is Fixed!');
        }).catch((error) => {
            if (error.response && error.response.status === 402) {
                message.error(<span>Your AI Credits are finished. Subscribe now to upgrade your plan.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            } else {
                message.error('It seems our servers are quite busy right now, please try again later.');
            }
        })
            .finally(() => {
                setLoadingProductIds(prev => prev.filter(productId => productId !== id));
            });
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 400
        },
        {
            title: 'Completeness',
            dataIndex: 'completeness',
            key: 'completeness',
            width: 100,
            render: (completeness, record) => (
                <Progress
                    size={"small"}
                    percent={completeness}
                    strokeColor={completeness > 90 ? 'green' : (completeness > 50 ? 'orange': 'red')}
                />
            ),
        },
        {
            title: 'Issues',
            dataIndex: 'issues',
            key: 'issues',
            width: 100,
            render: (issues, record) => (<Popover
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{record.issues ? JSON.parse(record.issues).length > 0 ? "Issues" : "You Did A Good Job!" : "You Did A Good Job!"}</span>
                    </div>
                }
                content={
                    record.issues && JSON.parse(record.issues).length > 0
                        ?
                        <ul>
                            {record.issues ? JSON.parse(record.issues).map((issue, index) => (
                                <li key={index}>{issue}</li>
                            )) : null}
                        </ul>
                        :
                        "This product has the potential to make its mark on search engines"
                }
                overlayStyle={{maxWidth: 400}}
                placement={'left'}
            >
                <Tag color={record.issues ? JSON.parse(record.issues).length > 0 ? "magenta" : "green" : "green"}>{record.issues ? JSON.parse(record.issues).length > 0 ? `${JSON.parse(record.issues).length} critical issues` : 'Perfect' : 'Calculating...'} </Tag>
            </Popover>),
        },
        {
            title: 'Updated',
            dataIndex: 'updated_at_human',
            width: 100,
            key: 'updated_at_human',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            width: 90,
            render: (text, record, index) => (
                <Button
                    ref={index === 0 ? AIFixBtnRef : null}
                    size={'small'}
                    type="primary"
                    onClick={(e) => handleAIFix(e, record.id)}
                    loading={loadingProductIds.includes(record.id)}
                >
                    {
                        loadingProductIds.includes(record.id)
                            ?
                            null
                            :
                            <RobotOutlined />
                    }
                    AI Fix
                </Button>
            ),
        }
    ];

    const handleMenuClick = (e) => {
        handleProductFilter({filter:e.key});
    };

    const items = [
        {
            label: 'Completeness',
            key: 'completeness',
            icon: <StarOutlined />,
        },
        {
            label: 'Updated',
            key: 'updated_at',
            icon: <StarOutlined />,
        }
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const { Search } = Input;

    const handleProductFilter = (value) => {
        setProductSearch(prevState => ({
            ...prevState,
            ...value // This assumes `value` is an object with key-value pairs to be appended or updated
        }));
    };

    useEffect(() => {
        if(productSearch.completeness || productSearch.search || productSearch.filter) {
            fetchProducts(productSearch);
        }
    }, [productSearch]);

    return (
        <>
            <div className=' w-100 bg-white rounded p-2 mb-1'>
                <Row className="justify-between">
                    <Col>
                        <Flex wrap gap={4}>
                            <Button
                                onClick={() => handleProductFilter({completeness:'all'})}
                                className={`border-0 ${productSearch.completeness === 'all' ? "bg-gray-200" : null}`} type="text" >
                                All
                            </Button>
                            <Button
                                onClick={() => handleProductFilter({completeness:'good'})}
                                className={`border-0 ${productSearch.completeness === 'good' ? "bg-gray-200" : null}`} type="text" >
                                Good
                            </Button>
                            <Button
                                onClick={() => handleProductFilter({completeness:'average'})}
                                className={`border-0 ${productSearch.completeness === 'average' ? "bg-gray-200" : null}`} type="text" >
                                Average
                            </Button>
                            <Button
                                onClick={() => handleProductFilter({completeness:'bad'})}
                                className={`border-0 ${productSearch.completeness === 'bad' ? "bg-gray-200" : null}`} type="text" >
                                Bad
                            </Button>
                            <Search
                                placeholder="Search with title"
                                onSearch={(value) => handleProductFilter({search:value})}
                                style={{
                                    width: 300,
                                }}
                            />
                        </Flex>
                    </Col>

                    <Col >
                        <Flex wrap gap={4}>
                            <Dropdown menu={menuProps}>
                                <Button>
                                    <Space>
                                        {productSearch.filter ? `${productSearch.filter}`.charAt(0).toUpperCase() + `${productSearch.filter}`.slice(1) : 'Sort'}
                                        <FilterOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Flex></Col>
                </Row>
            </div>
            <Table rowKey={'id'} size={'small'} pagination={{ pageSize: 100 }} loading={loader} columns={columns} dataSource={products} />
            <Tour open={open} onClose={handleTourClose} steps={tourSteps} />
        </>
    );
}
