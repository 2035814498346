import React, { useEffect } from "react";
import {Card, Col, Row, Statistic, message, Flex, Avatar} from "antd";
import {Head, Link, usePage} from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import {
    ArrowDownOutlined,
    ExclamationCircleOutlined,
    InteractionTwoTone,
    PlusCircleOutlined,
    RobotFilled
} from "@ant-design/icons";
import ProductsTable from "@/components/Tables/ProductsTable";
import DashboardNotifications from "../components/Cards/DashboardNotifications";
import ProductCreateAI from "@/components/Forms/ProductCreateAI";
import VideoTutorial from "../components/Badges/VideoTutorial";
import PaymentActivationAlert from "../components/Cards/PaymentActivationAlert";
import ProductCompletenessPie from "../components/Graphs/ProductCompletenessPie";
import ProductSyncPie from "../components/Graphs/ProductSyncPie";
import AuthSideBarWrapper from "../components/Navs/AuthSideBarWrapper";
import WelcomeBanner from "../components/Cards/WelcomeBanner";
import VariantCompletenessLine from "@/components/Graphs/VariantCompletenessLine";
import MediaCompletenessLine from "@/components/Graphs/MediaCompletenessLine";
import shopifyIcon from "../../../public/media/shopifyIcon.svg";
import wooIcon from "../../../public/media/wooIcon.svg";
import bigIcon from "../../../public/media/bigIcon.svg";

const Dashboard = ({ user, productCount }) => {
    const { props } = usePage();
    const { success, error } = props;

    useEffect(() => {
        if (success) {
            message.success(success);
        }
        if (error) {
            message.error(error);
        }
    }, [success, error]);

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Dashboard</title>
            </Head>
            <DashboardNotifications />
            <PaymentActivationAlert />
            {
                productCount < 1 ?
                    <WelcomeBanner />
                    :
                    null
            }
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Title level={3} style={{ margin: 0 }}>{user.name} you have synced {productCount} products! <small><small><VideoTutorial url={'https://www.youtube.com/embed/5axVExfE1mQ?si=Qc38NE5m47b9g7VV'} /></small></small> </Title>
                <ProductCreateAI />
            </div>
            <Row gutter={16} className={'mb-2'}>
                <Col span={6}>
                    <Card bordered={false} size={'small'}>
                        <p className={'m-0 mb-2'}>Product Completeness</p>
                        <ProductCompletenessPie />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false} size={'small'}>
                        <p className={'m-0 mb-2'}>Product Sync Status</p>
                        <ProductSyncPie />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false} size={'small'}>
                        <p className={'m-0 mb-2'}>Other Completeness Scores</p>
                        <VariantCompletenessLine />
                        <MediaCompletenessLine />
                    </Card>
                </Col>
            </Row>
            <Row gutter={6} className={'mb-2'}>
                <Col span={8}>
                    <Link href={'/products/bulk/fix'}>
                        <Card size={'small'}
                              className={'cursor-pointer hover:shadow'}>
                            <div className={'flex justify-between'}>
                                <div className={'flex items-center gap-2'}>
                                    <Avatar size={'small'} style={{backgroundColor:'white'}} icon={<RobotFilled
                                        style={{
                                            color: '#00b96b',
                                        }}
                                    />} />
                                    <b>Bulk Fix</b>
                                </div>
                                <PlusCircleOutlined />
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href={'/quick-action/bulk_sync'}>
                        <Card size={'small'}
                              className={'cursor-pointer hover:shadow'}>
                            <div className={'flex justify-between'}>
                                <div className={'flex items-center gap-2'}>
                                    <Avatar size={'small'} style={{backgroundColor:'white'}} icon={<InteractionTwoTone />} />
                                    <b>Bulk Sync</b>
                                </div>
                                <PlusCircleOutlined/>
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href={'/integrations'}>
                        <Card size={'small'}
                              className={'cursor-pointer hover:shadow'}>
                            <div className={'flex justify-between'}>
                                <div className={'flex items-center gap-2'}>
                                    <Avatar.Group>
                                        <Avatar src={bigIcon} size={'small'} style={{backgroundColor:'white'}} />
                                        <Avatar src={shopifyIcon} size={'small'} style={{backgroundColor:'white'}} />
                                        <Avatar src={wooIcon} size={'small'} style={{backgroundColor:'white'}} />
                                    </Avatar.Group>
                                    <b>Connect Integration</b>
                                </div>
                                <PlusCircleOutlined/>
                            </div>
                        </Card>
                    </Link>
                </Col>
            </Row>
            <ProductsTable />
        </AuthSideBarWrapper>
    );
};

export default Dashboard;
