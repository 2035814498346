import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Flex, Spin, Card } from 'antd';
import axios from 'axios';

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip);

const ProductCompletenessPie = () => {
    const [goodProductCount, setGoodProductCount] = useState(0);
    const [needImprovementProductCount, setNeedImprovementProductCount] = useState(0);
    const [badProductCount, setBadProductCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Make API request to get product completeness stats
        axios
            .get('/api/stats/product/completeness')
            .then((response) => {
                const { good_product_count, need_improvement_product_count, bad_product_count } = response.data;
                setGoodProductCount(good_product_count);
                setNeedImprovementProductCount(need_improvement_product_count);
                setBadProductCount(bad_product_count);
                setLoading(false); // Set loading to false after data is loaded
            })
            .catch((error) => {
                console.error('Error fetching product completeness stats:', error);
                setLoading(false);
            });
    }, []);

    // Determine if all values are zero
    const allValuesAreZero = goodProductCount === 0 && needImprovementProductCount === 0 && badProductCount === 0;

    // Chart data
    const data = {
        labels: allValuesAreZero ? ['No Data'] : ['Good Products', 'Needs Improvement', 'Bad Products'],
        datasets: [
            {
                label: 'Product Completeness',
                data: allValuesAreZero ? [1] : [goodProductCount, needImprovementProductCount, badProductCount],
                backgroundColor: allValuesAreZero ? ['#d9d9d9'] : ['#3f8600', '#e06315', '#cf1322'],
                hoverBackgroundColor: allValuesAreZero ? ['#bfbfbf'] : ['#66bb6a', '#ffb74d', '#e57373'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        return `${value}`;
                    },
                },
            },
        },
        cutout: '60%',
        layout: {
            padding: 0,
        },
    };

    const chartContainerStyle = {
        width: '60px',
        height: '60px',
        margin: 0,
        padding: 0,
    };

    return (
        <Flex gap={0} align={'center'}>
            <div style={chartContainerStyle}>
                <Doughnut data={data} options={options} />
            </div>
            <div className={'text-[12px]'}>
                <ul className={'m-0 pl-5'}>
                    <li>
                        Good Products{' '}
                        <span style={{ color: '#3f8600' }} className={'font-bold'}>
                            <ArrowUpOutlined /> {goodProductCount}
                        </span>
                    </li>
                    <li>
                        Needs Improvement{' '}
                        <span style={{ color: '#e06315' }} className={'font-bold'}>
                            <ArrowDownOutlined /> {needImprovementProductCount}
                        </span>
                    </li>
                    <li>
                        Bad Products{' '}
                        <span style={{ color: '#cf1322' }} className={'font-bold'}>
                            <ArrowDownOutlined /> {badProductCount}
                        </span>
                    </li>
                </ul>
            </div>
        </Flex>
    );
};

export default ProductCompletenessPie;
