import { Button, Card, Col, Flex, Form, Input, message, Modal, Row, Select } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { Head } from "@inertiajs/react";
import { useState, useEffect } from "react";
import axios from "axios";
import BigFileUpload from "../components/Forms/BigFileUpload";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

const { Option, OptGroup } = Select;

export default function ProductImportTemplate({ appTemplateFields, templateEditId }) {
    const [rows, setRows] = useState([{ id: Date.now(), from: '', with_formula: '', to: '' }]);
    const [totalSelected, setTotalSelected] = useState(0);
    const [loader, setLoader] = useState(false);
    const [showMapping, setShowMapping] = useState(false);
    const [headings, setHeadings] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false); // Control the visibility of the modal
    const [templateName, setTemplateName] = useState(''); // Store template name
    const [form] = Form.useForm();
    const [templateId, setTemplateId] = useState(templateEditId || null); // Initialize templateId state

    const options = appTemplateFields;

    useEffect(() => {
        if (templateId) {
            setLoader(true);
            // Fetch the template data from the API
            axios.get(`/api/import/templates/${templateId}`)
                .then(response => {
                    setTemplateName(response.data.template.name);
                    const { content } = response.data.template; // Assuming the template object has a content field
                    if (content) {
                        const parsedContent = JSON.parse(content); // Assuming content is a JSON string
                        setRows(parsedContent); // Populate rows with the content data
                        form.setFieldsValue({ rows: parsedContent }); // Set form fields with the data
                        const selectedCount = parsedContent.filter(row => row.from).length;
                        setTotalSelected(selectedCount); // Update total selected count
                    }
                })
                .catch(error => {
                    console.error('Error fetching the template:', error);
                }).finally(() => {
                setLoader(false);
            });
        }
    }, [form, templateId]);

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), from: '', with_formula: '', to: '' }]);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        setRows(newRows);

        // Update total selected count
        const selectedCount = newRows.filter(row => row.from).length;
        setTotalSelected(selectedCount);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);

        // Clear form values for deleted row
        form.setFieldsValue({
            rows: newRows,
        });

        // Update total selected count
        const selectedCount = newRows.filter(row => row.from).length;
        setTotalSelected(selectedCount);
    };

    const handleSubmit = async (values) => {
        setLoader(true);
        try {
            values.name = templateName; // Include template name in the form submission
            if (templateId) {
                await axios.put(`/api/import/templates/${templateId}/update`, values);
            } else {
                const response = await axios.post(`/api/import/templates/store`, values);
                setTemplateId(response.data.template.id);
            }

            if (form.getFieldValue('start_import')) {
                message.success('Import Started Successfully.');
            } else {
                message.success('Template saved successfully.');
            }


        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;
                Object.keys(errors).forEach((field) => {
                    errors[field].forEach((messageError) => {
                        message.error(messageError);  // Display each error message
                    });
                });
            } else {
                message.error('Something went wrong. Please try again.');
            }
        } finally {
            form.setFieldsValue({start_import:false});
            setLoader(false);
        }
    };

    const handleSaveTemplateClick = () => {
        setIsModalVisible(true); // Show the modal when "Save Template" is clicked
    };

    const handleImportTemplateClick = () => {
        form.setFieldsValue({start_import:true});
        form.submit(); // Submit the form when modal OK is clicked
    };

    const handleModalOk = () => {
        form.submit(); // Submit the form when modal OK is clicked
        setIsModalVisible(false); // Hide the modal
    };

    const handleModalCancel = () => {
        setIsModalVisible(false); // Hide the modal when Cancel is clicked
    };

    const handleSuccessUpload = (response) => {
        if (response) {
            setShowMapping(true);
            setHeadings(Object.entries(response.headings));
            form.setFieldsValue({ file: response.path });
        } else {
            setShowMapping(false);
            setHeadings([]);
            form.setFieldsValue({ file: '' });
        }
    };

    const handleErrorUpload = (response) => {};

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Product Import</title>
            </Head>
            <Flex wrap justify={'space-between'}>
                <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                    <div>
                        <Title level={4} className={'m-0'}>
                            Product Import
                        </Title>
                        <p className={'m-0 p-0'}>Map columns to create or update your import template</p>
                    </div>
                </Flex>

                <div className={'text-right'}>
                    <Flex gap={5}>
                        <Button
                            loading={loader}
                            type={'primary'}
                            onClick={handleSaveTemplateClick} // Show modal when "Save Template" is clicked
                        >
                            Save Template
                        </Button>
                        {
                            templateId ?
                                <Button
                                    loading={loader}
                                    type={'link'}
                                    onClick={handleImportTemplateClick}
                                >
                                    Start Import
                                </Button>
                                :
                                null
                        }
                    </Flex>
                    <p className={'mt-1 m-0 p-0 text-gray-500 text-[12px]'}>Import Columns: {totalSelected}</p>
                </div>
            </Flex>

            <Card className={'bg-gray-200'}>
                <div className={'mb-3'}>
                    <BigFileUpload onSuccess={handleSuccessUpload} onError={handleErrorUpload} />
                </div>
                {
                    showMapping && <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                            hidden={true}
                            name={'file'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            hidden={true}
                            initialValue={false}
                            name={'start_import'}
                        >
                            <Input />
                        </Form.Item>
                        {rows.map((row, index) => (
                            <Row gutter={6} key={row.id} align="middle">
                                <Col span={7}>
                                    <Form.Item
                                        className={'mb-2'}
                                        name={['rows', index, 'from']}
                                        rules={[
                                            { required: true, message: 'Please input the "From" field.' },
                                            {
                                                validator: (_, value) => {
                                                    const isValid = headings.some(([key]) => key === value);
                                                    return isValid ? Promise.resolve() : Promise.reject(new Error('This field is missing in CSV'));
                                                },
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select From"
                                            value={row.from}
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleInputChange(index, 'from', value)}
                                        >
                                            {headings.map(([key, value]) => (
                                                <Option key={key} value={key}>
                                                    {value}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item
                                        className={'mb-1'} name={['rows', index, 'with_formula']}>
                                        <Input
                                            placeholder="Write AI Prompt to update data"
                                            value={row.with_formula}
                                            onChange={(e) => handleInputChange(index, 'with_formula', e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        className={'mb-1'}
                                        name={['rows', index, 'to']}
                                        rules={[
                                            { required: true, message: 'Please input the "To" field.' }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select From"
                                            value={row.from}
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleInputChange(index, 'to', value)}
                                        >
                                            {options.map(group => (
                                                <OptGroup key={group.label} label={group.label}>
                                                    {group.options.map(option => (
                                                        <Option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </OptGroup>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Button
                                        size={'small'}
                                        type="danger"
                                        className={'hover:bg-red-500 hover:border-red-600 hover:text-white'}
                                        icon={<DeleteOutlined />}
                                        onClick={() => handleDeleteRow(index)}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Button type="dashed" onClick={handleAddRow} icon={<PlusOutlined />}>
                            Add Row
                        </Button>
                    </Form>
                }
            </Card>

            <Modal
                title="Save Template"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Input
                    placeholder="Enter template name"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)} // Update template name state
                />
            </Modal>
        </AuthSideBarWrapper>
    );
}
