import React, { useState, useEffect } from 'react';
import {Tabs, Table, Input, Button, message as antMessage, Tooltip, Flex} from 'antd';
import axios from 'axios';
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {Head} from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

const { TabPane } = Tabs;
const { Search } = Input;

const Notifications = ({ user }) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [tabKey, setTabKey] = useState('unread');

    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/notifications', {
                params: {
                    q: searchTerm,
                    read: tabKey === 'unread' ? 0 : 1,
                },
            });
            setNotifications(response.data.notifications);
        } catch (error) {
            antMessage.error('Failed to fetch notifications');
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleMarkAsRead = async (id) => {
        try {
            await axios.patch(`/api/notifications/${id}/read`, { id });
            antMessage.success('Notification marked as read');
            fetchNotifications();
        } catch (error) {
            antMessage.error('Failed to mark notification as read');
        }
    };

    const handleMarkAsUnread = async (id) => {
        try {
            await axios.patch(`/api/notifications/${id}/unread`, { id });
            antMessage.success('Notification marked as unread');
            fetchNotifications();
        } catch (error) {
            antMessage.error('Failed to mark notification as unread');
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, [tabKey, searchTerm]);

    const columns = [
        {
            title: 'Message',
            dataIndex: ['data', 'message'], // Access nested message
            key: 'message',
            sorter: true,
            render: (text, record) => (
                <>
                    <p className={'m-0 p-0'}>{text}</p>
                    {record.data.action_url && (
                        <Button
                            className={'mt-2'}
                            size={'small'}
                            type="primary"
                            href={record.data.action_url}
                            target="_blank"
                        >
                            {record.data.action_label || 'Click Here'}
                        </Button>
                    )}
                </>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'created_at_human',
            key: 'created_at_human',
            render: (text) => <small>{text}</small>, // Convert to relative time
        },
    ];


    return (

        <AuthSideBarWrapper>
            <Head>
                <title>Notifications</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-0'}>
                <Title level={3} className={'m-0'}>Notifications</Title>
            </Flex>
            <Search
                style={{width:'300px'}}
                placeholder="Search notifications"
                onSearch={handleSearch}
                className="mb-4"
            />
            <Table

                showHeader={false}
                columns={columns}
                dataSource={notifications}
                loading={loading}
                rowKey="id"
                pagination={{ pageSize: 5 }}
            />
        </AuthSideBarWrapper>
    );
};

export default Notifications;
