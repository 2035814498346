
import {Head} from "@inertiajs/react";
import React, {useState} from "react";
import ProductSidebarWrapper from "../components/Navs/ProductSidebarWrapper";
import VariantsTable from "../components/Tables/VariantsTable";
import MetafieldsTable from "@/components/Tables/MetafieldsTable";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function ProductIndex({product}) {
    const [syncLoader, setSyncLoader] = useState(false);

    return (
        <AuthSideBarWrapper>
            <ProductSidebarWrapper product={product}>
                <Head>
                    <title>Metafields</title>
                </Head>
                <MetafieldsTable metafields={product.metafields} product={product} />
            </ProductSidebarWrapper>
        </AuthSideBarWrapper>
    )
}
