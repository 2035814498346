import React, { useState } from "react";
import { Input, Button, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const WooCommerceInput = () => {
    const [url, setUrl] = useState("");

    // Function to validate and sanitize the URL
    const sanitizeUrl = (inputUrl) => {
        // Add protocol if missing
        if (!/^https?:\/\//i.test(inputUrl)) {
            inputUrl = `http://${inputUrl}`;
        }

        // Remove trailing slash, if present
        inputUrl = inputUrl.trim().replace(/\/+$/, "");

        return inputUrl;
    };

    const validateUrl = (inputUrl) => {
        const urlPattern = new RegExp(
            "^(https:\\/\\/)" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        );
        return !!urlPattern.test(inputUrl);
    };

    // Function to handle navigation
    const handleRedirect = () => {
        let sanitizedUrl = sanitizeUrl(url);

        if (!validateUrl(sanitizedUrl)) {
            message.error("Please enter a valid URL with https included (e.g., https://example.com)");
            return;
        }

        window.location.href = `/auth/woocommerce/begin?shop=${encodeURIComponent(sanitizedUrl)}`;
    };

    // Handle Enter key press
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleRedirect();
        }
    };

    return (
        <Input.Group compact style={{ display: "flex", margin: "0 auto" }}>
            <Input
                size="large"
                placeholder="Enter your WooCommerce/WordPress store URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                onKeyPress={handleKeyPress}
                style={{ flex: 1 }}
            />
            <Button
                size="large"
                type="primary"
                icon={<ArrowRightOutlined />}
                onClick={handleRedirect}
                style={{ borderRadius: "0 4px 4px 0" }}
            />
        </Input.Group>
    );
};

export default WooCommerceInput;
