import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import {
    Avatar,
    Button,
    Col,
    Dropdown,
    message,
    Flex,
    Popover,
    Progress,
    Row,
    Space,
    Table,
    Tag,
    Tour
} from "antd";
import {
    FileImageOutlined,
    FilterOutlined,
    RobotOutlined,
    StarOutlined
} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { Input} from 'antd';
import {SyncToShopify} from "../Buttons/SyncToShopify";
import {Link} from "@inertiajs/react";

export default function ArticlesTable () {

    const [open, setOpen] = useState(false);
    const PreviewRef = useRef(null);

    const tourSteps = [
        {
            title: 'Update and Manage Blogs with AI',
            description: 'By clicking on the blog you can preview and update all the blog data individually and then upload it on shopify with one click.',
            target: () => PreviewRef.current,
        }
    ];

    useEffect(() => {
        // Check if the "tourDone" flag is set in localStorage
        const tourDone = localStorage.getItem('blogTourDone');

        if (!tourDone) {
            // If no tour done status, start the tour
            setOpen(true);
        }
    }, []);

    const handleTourClose = () => {
        // When tour is closed, set the "tourDone" flag in localStorage
        localStorage.setItem('blogTourDone', 'true');
        setOpen(false);
    };

    const [blogs, setBlogs] = useState([]);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({
        completeness:'all',
        search:'',
        filter:''
    });
    const [loadingProductIds, setLoadingProductIds] = useState([]);

    function fetchArticles(data = {}) {
        setLoader(true);
        axios.get("/api/articles", {
            params: data,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            setBlogs(response.data.articles);
        }).finally(() => {
            setLoader(false);
        });
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: ['media', '0', 'url'], // Access the first media item's src
            key: 'media.src',
            width: 45,
            render: (text) => (
                <Avatar src={text} shape="square" size="small" icon={<FileImageOutlined />} />
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 400,
            render: (completeness, record) => (
                <>
                    {record.title}<br />
                    <small>{record.author} -- {record.blog.title}</small>
                </>
            ),
        },
        {
            title: 'Completeness',
            dataIndex: 'completeness',
            key: 'completeness',
            width: 100,
            render: (completeness, record) => (
                <Progress
                    size={"small"}
                    percent={completeness}
                    strokeColor={completeness > 90 ? 'green' : (completeness > 50 ? 'orange': 'red')}
                />
            ),
        },
        {
            title: 'Issues',
            dataIndex: 'issues',
            key: 'issues',
            width: 100,
            render: (issues, record) => (<Popover
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{record.issues ? JSON.parse(record.issues).length > 0 ? "Issues" : "You Did A Good Job!" : "You Did A Good Job!"}</span>
                    </div>
                }
                content={
                    record.issues && JSON.parse(record.issues).length > 0
                        ?
                        <ul>
                            {record.issues ? JSON.parse(record.issues).map((issue, index) => (
                                <li key={index}>{issue}</li>
                            )) : null}
                        </ul>
                        :
                        "This Blog has the potential to make its mark on search engines"
                }
                overlayStyle={{maxWidth: 400}}
                placement={'left'}
            >
                <Tag color={record.issues ? JSON.parse(record.issues).length > 0 ? "magenta" : "green" : "green"}>{record.issues ? JSON.parse(record.issues).length > 0 ? `${JSON.parse(record.issues).length} critical issues` : 'Perfect' : 'Calculating...'} </Tag>
            </Popover>),
        },
        {
            title: 'Updated',
            dataIndex: 'updated_at_human',
            width: 120,
            key: 'updated_at_human',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 80,
            key: 'action',
            render: (issues, record) => (
                <Link href={`/articles/${record.id}/edit`}>
                    Edit
                </Link>
            ),
        }
    ];

    const handleMenuClick = (e) => {
        handleProductFilter({filter:e.key});
    };

    const items = [
        {
            label: 'Completeness',
            key: 'completeness',
            icon: <StarOutlined />,
        },
        {
            label: 'Updated',
            key: 'updated_at',
            icon: <StarOutlined />,
        }
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const { Search } = Input;

    const handleProductFilter = (value) => {
        setSearch(prevState => ({
            ...prevState,
            ...value // This assumes `value` is an object with key-value pairs to be appended or updated
        }));
    };

    useEffect(() => {
        if(search.completeness || search.search || search.filter) {
            fetchArticles(search);
        }
    }, [search]);

    return (
        <>
            <div className=' w-100 bg-white rounded p-2 mb-1'>
                <Row className="justify-between">
                    <Col>
                        <Flex wrap gap={4}>
                            <Button
                                onClick={() => handleProductFilter({completeness:'all'})}
                                className={`border-0 ${search.completeness === 'all' ? "bg-gray-200" : null}`} type="text" >
                                All
                            </Button>
                            <Button
                                onClick={() => handleProductFilter({completeness:'good'})}
                                className={`border-0 ${search.completeness === 'good' ? "bg-gray-200" : null}`} type="text" >
                                Good
                            </Button>
                            <Button
                                onClick={() => handleProductFilter({completeness:'average'})}
                                className={`border-0 ${search.completeness === 'average' ? "bg-gray-200" : null}`} type="text" >
                                Average
                            </Button>
                            <Button
                                onClick={() => handleProductFilter({completeness:'bad'})}
                                className={`border-0 ${search.completeness === 'bad' ? "bg-gray-200" : null}`} type="text" >
                                Bad
                            </Button>
                            <Search
                                placeholder="Search with title"
                                onSearch={(value) => handleProductFilter({search:value})}
                                style={{
                                    width: 300,
                                }}
                            />
                        </Flex>
                    </Col>

                    <Col >
                        <Flex wrap gap={4}>
                            <Dropdown menu={menuProps}>
                                <Button>
                                    <Space>
                                        {search.filter ? `${search.filter}`.charAt(0).toUpperCase() + `${search.filter}`.slice(1) : 'Sort'}
                                        <FilterOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Flex></Col>
                </Row>
            </div>
            <Table onRow={(record) => ({
                onClick: () => {window.location.href = `/articles/${record.id}/edit`},
                style: {cursor: "pointer"}
            })} size={'small'} pagination={{ pageSize: 100 }} loading={loader} columns={columns} dataSource={blogs} />
            <Tour open={open} onClose={handleTourClose} steps={tourSteps} />
        </>
    );
}
