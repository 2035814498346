import React from 'react';
import { Avatar, Flex, Layout, Tabs } from 'antd';
import { FileImageOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";

const { Content } = Layout;

const ProductSidebarWrapper = ({ children, product }) => {

    const items = [
        {
            key: 'overview',
            label: 'Overview',
            onClick: () => window.location.href = `/products/${product.id}/edit`,
            active: window.location.pathname.includes('/edit')
        },
        {
            key: 'media',
            label: 'Media',
            onClick: () => window.location.href = `/products/${product.id}/media`,
            active: window.location.pathname.includes('/media')
        },
        {
            key: 'variants',
            label: 'Variants',
            onClick: () => window.location.href = `/products/${product.id}/variants`,
            active: window.location.pathname.includes('/variants')
        },
        {
            key: 'integrations',
            label: 'Integrations',
            onClick: () => window.location.href = `/products/${product.id}/integrations`,
            active: window.location.pathname.includes('/integrations')
        },
        {
            key: 'metafields',
            label: 'MetaFields',
            onClick: () => window.location.href = `/products/${product.id}/metafields`,
            active: window.location.pathname.includes('/metafields')
        }
    ];

    return (
        <>
            <Flex className={'px-3'} gap={10}>
                <Avatar
                    src={product.image}
                    className={'mt-1 ml-2 flex-shrink-0'}
                    shape="square"
                    size={'large'}
                    icon={<FileImageOutlined />}
                />
                <div className="flex-grow">
                    <Title level={4} className={'m-0 p-0 break-words'}>
                        {product.id ? product.title : "Create Product"}
                    </Title>
                </div>
            </Flex>
            <Layout style={{ backgroundColor: 'transparent' }}>
                <Tabs
                    defaultActiveKey={items.find(item => item.active)?.key || 'overview'}
                    onTabClick={(key) => {
                        const selectedItem = items.find(item => item.key === key);
                        if (selectedItem) selectedItem.onClick();
                    }}
                    tabBarStyle={{ margin: '8px 0', paddingLeft: '0.75rem' }}
                    items={items.map(item => ({
                        key: item.key,
                        label: item.label,
                    }))}
                />
                <Layout style={{ padding: '12px 10px 0px', backgroundColor: 'transparent' }}>
                    <Content
                        style={{
                            padding: 0,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export default ProductSidebarWrapper;
