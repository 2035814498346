import {Avatar, Button, Card, Flex, Form, message, Tooltip} from "antd";

import {Head} from "@inertiajs/react";
import React, {useEffect, useState} from "react";
import Title from "antd/lib/typography/Title";
import {SyncToShopify} from "../components/Buttons/SyncToShopify";
import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    FileImageOutlined,
    LoadingOutlined,
    WarningFilled
} from "@ant-design/icons";
import ProductTitleField from "../components/Forms/ProductTitleField";
import ProductDescriptionField from "../components/Forms/ProductDescriptionField";
import ProductTagsField from "../components/Forms/ProductTagsField";
import ProductMetaTitleField from "../components/Forms/ProductMetaTitleField";
import SEOAnalysis from "../components/SEOAnalysis";
import ProductCreateAI from "@/components/Forms/ProductCreateAI";
import axios from "axios";
import ProductSidebarWrapper from "../components/Navs/ProductSidebarWrapper";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function ProductIndex({user, initialProduct = {
    title:'',
    body_html:'',
    tags: '',
    meta_fields: [{
        key: "title_tag",
        value: ""
    }, {
        key: "description_tag",
        value: ""
    }]
}}) {

    const [product, setProduct] = useState(initialProduct);
    const [syncLoader, setSyncLoader] = useState(false);

    const handleUpdateProduct = (product) => {
        setProduct(product);
    }

    const syncSuccessHandler = (productUpdated) => {
        setProduct(productUpdated);
    }

    const handleProductSync = (e, productId) => {
        e.stopPropagation();
        setSyncLoader(true);
        axios.post(`/api/products/${productId}/sync`, {
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            setProduct(response.data.product);
            message.success('Sync in progress!');
        }).catch((error) => {
            if (error.response && error.response.status === 402) {
                message.error(<span>You need to purchase a plan to sync your products.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            } else {
                message.error('It seems our servers are quite busy right now, please try again later.');
            }
        }).finally(() => {
            setSyncLoader(false);
        });
    }

    return (
        <AuthSideBarWrapper>
            <ProductSidebarWrapper product={product}>
                <Head>
                    <title>Product Create</title>
                </Head>
                <Flex gap={20}>
                    <Form layout={'vertical'} className={'w-full'}>
                        <ProductTitleField
                            product={product}
                            onUpdateProduct={handleUpdateProduct}
                        />
                        <ProductDescriptionField
                            product={product}
                            onUpdateProduct={handleUpdateProduct}
                        />
                        <ProductTagsField
                            product={product}
                            onUpdateProduct={handleUpdateProduct}
                        />
                        <ProductMetaTitleField
                            product={product}
                            onUpdateProduct={handleUpdateProduct}
                        />
                    </Form>
                    <div className={'w-1/2 bg-transparent border-0 pt-6'}>
                        <Flex align={'center'} gap={5} justify={'end'}>
                            {/*<Button type={'link'} className={'border-0 p-0'} onClick={() => window.open(`https://${user.shop}/products/${product ? product.handle : null}`, '_blank')}>Store Preview</Button>*/}
                            {/*<span className={'text-gray-500'}>•</span>*/}
                            {/*<Button type={'link'} className={'border-0 p-0'} onClick={() => window.open(`https://${user.shop}/admin/products/${product ? product.shopify_product_id : null}`, '_blank')}>Admin Preview</Button>*/}
                        </Flex>
                        <Flex wrap justify={'end'} align={'center'}>
                            <Flex wrap align={'center'} gap={5}>
                                {
                                    product.sync_status === 'synced'
                                        ?
                                        <Tooltip placement={'left'} title={'Synced'}>
                                            <CheckCircleFilled className={'text-green-600'} />
                                        </Tooltip>
                                        :
                                        (
                                            syncLoader || product.sync_status === 'in_progress'
                                                ?
                                                <Tooltip placement={'left'} title={'Syncing, refresh to see progress'}>
                                                    <LoadingOutlined className={'text-green-500'} />
                                                </Tooltip>
                                                :
                                                (
                                                    product.sync_status === 'issue'
                                                        ?
                                                        <Tooltip placement={'left'} title={'Check Issues section and sync again'}>
                                                            <WarningFilled className={'text-red-500'} onClick={(e) => handleProductSync(e, product.id)} />
                                                        </Tooltip>
                                                        :
                                                        <Tooltip placement={'left'} title={'Not synced, Click to sync your product'}>
                                                            <ExclamationCircleFilled className={'text-orange-500'} onClick={(e) => handleProductSync(e, product.id)} />
                                                        </Tooltip>
                                                )
                                        )
                                }
                                <SyncToShopify product={product} onSyncSuccessful={syncSuccessHandler} />
                                <ProductCreateAI data={product} onSubmit={handleUpdateProduct} />
                            </Flex>
                        </Flex>
                        <SEOAnalysis product={product} />
                    </div>
                </Flex>
            </ProductSidebarWrapper>
        </AuthSideBarWrapper>
    )
}
