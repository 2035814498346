import {Avatar, Button, Card, Col, ConfigProvider, Flex, Row, Statistic} from "antd";

import {Head, Link} from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    InteractionTwoTone,
    PlusCircleOutlined,
    RobotFilled
} from "@ant-design/icons";
import ProductsTable from "@/components/Tables/ProductsTable";
import ProductCreateAI from "@/components/Forms/ProductCreateAI";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";
import bigIcon from "../../../public/media/bigIcon.svg";
import shopifyIcon from "../../../public/media/shopifyIcon.svg";
import wooIcon from "../../../public/media/wooIcon.svg";
import React from "react";

export default function ProductIndex({}) {
    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Products</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                <Title level={3} className={'m-0'}>Products</Title>
                <ProductCreateAI />
            </Flex>
            <Row gutter={6} className={'mb-2'}>
                <Col span={8}>
                    <Link href={'/products/bulk/fix'}>
                        <Card size={'small'}
                              className={'cursor-pointer hover:shadow'}>
                            <div className={'flex justify-between'}>
                                <div className={'flex items-center gap-2'}>
                                    <Avatar size={'small'} style={{backgroundColor:'white'}} icon={<RobotFilled
                                        style={{
                                            color: '#00b96b',
                                        }}
                                    />} />
                                    <b>Bulk Fix</b>
                                </div>
                                <PlusCircleOutlined />
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href={'/quick-action/bulk_sync'}>
                        <Card size={'small'}
                              className={'cursor-pointer hover:shadow'}>
                            <div className={'flex justify-between'}>
                                <div className={'flex items-center gap-2'}>
                                    <Avatar size={'small'} style={{backgroundColor:'white'}} icon={<InteractionTwoTone />} />
                                    <b>Bulk Sync</b>
                                </div>
                                <PlusCircleOutlined/>
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href={'/integrations'}>
                        <Card size={'small'}
                              className={'cursor-pointer hover:shadow'}>
                            <div className={'flex justify-between'}>
                                <div className={'flex items-center gap-2'}>
                                    <Avatar.Group>
                                        <Avatar src={bigIcon} size={'small'} style={{backgroundColor:'white'}} />
                                        <Avatar src={shopifyIcon} size={'small'} style={{backgroundColor:'white'}} />
                                        <Avatar src={wooIcon} size={'small'} style={{backgroundColor:'white'}} />
                                    </Avatar.Group>
                                    <b>Connect Integration</b>
                                </div>
                                <PlusCircleOutlined/>
                            </div>
                        </Card>
                    </Link>
                </Col>
            </Row>
            <ProductsTable />
        </AuthSideBarWrapper>
    )
}
