import { Avatar, Card, Col, Empty, Flex, Row } from "antd";
import { Head } from "@inertiajs/react";
import React, { useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import ShopifyIcon from '../../../public/media/shopifyIcon64.png';
import { ArrowRightOutlined } from "@ant-design/icons";
import axios from "axios";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function ProductExport() {
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        axios.get('/api/products/export/templates')
            .then(response => {
                setTemplates(response.data.templates); // Assuming the response contains an array of templates
            })
            .catch(error => {
                console.error("There was an error fetching the templates!", error);
            });
    }, []);

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Product Export</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'}>
                <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                    <div>
                        <Title level={4} className={'m-0'}>
                            Product Export
                        </Title>
                        <p className={'m-0 p-0'}>Choose a template from below to begin your export</p>
                    </div>
                </Flex>
            </Flex>

            <Card className={'bg-gray-200'}>
                <Row gutter={6}>
                    {templates.length > 0 ? (
                        templates.map((template, index) => (
                            <Col key={index} span={5}>
                                <Card size={'small'} onClick={() => window.location.href=`/products/export/templates/${template.id}`} className={'cursor-pointer hover:shadow'}>
                                    <Flex justify={'space-between'}>
                                        <Flex wrap align={'center'} gap={5}>
                                            <div
                                                dangerouslySetInnerHTML={{__html: template.icon || ShopifyIcon}}
                                            />
                                            <b>{template.name}</b>
                                        </Flex>
                                        <ArrowRightOutlined/>
                                    </Flex>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <Col span={24}>
                            <Empty description="No templates found" />
                        </Col>
                    )}
                </Row>
            </Card>
        </AuthSideBarWrapper>
    );
}
