import React, { useState } from 'react';
import { Menu, Dropdown, Button, Avatar } from 'antd';
import {
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
    DashboardOutlined,
    DollarCircleOutlined
} from '@ant-design/icons';
import { router } from "@inertiajs/react";

export default function TopBarWrapper({ children }) {
    const [currentMenu, setCurrentMenu] = useState('dashboard');

    const handleMenuClick = (e) => {
        setCurrentMenu(e.key);

        // Navigate to the corresponding route based on the key
        switch (e.key) {
            case 'dashboard':
                router.visit('/admin');
                break;
            case 'billing':
                router.visit('/admin/billing');
                break;
            case 'settings':
                router.visit('/admin/settings');
                break;
            case 'profile':
                router.visit('/admin/profile');
                break;
            case 'logout':
                router.post('/admin/logout');
                break;
            default:
                break;
        }
    };

    const menuItems = [
        {
            label: 'Dashboard',
            key: 'dashboard',
            icon: <DashboardOutlined />,
        },
        {
            label: 'Billing',
            key: 'billing',
            icon: <DollarCircleOutlined />,
        },
        {
            label: 'Profile',
            key: 'profile',
            icon: <UserOutlined />,
        },
        {
            label: 'Logout',
            key: 'logout',
            icon: <LogoutOutlined />,
        },
    ];

    return (
        <div>
            <div className={'flex px-5 justify-between items-center bg-white'}>
                <div className={'flex items-center gap-2'}>
                    <h1 className="text-xl font-bold">Admin Portal</h1>
                </div>
                <div className={'flex justify-between items-center gap-3'}>
                    <Menu
                        onClick={handleMenuClick}
                        selectedKeys={[currentMenu]}
                        mode="horizontal"
                        items={menuItems}
                        className={'border-none'}
                    />
                    <Dropdown
                        overlay={
                            <Menu
                                onClick={handleMenuClick}
                                items={[
                                    { label: 'Profile', key: 'profile', icon: <UserOutlined /> },
                                    { label: 'Logout', key: 'logout', icon: <LogoutOutlined /> },
                                ]}
                            />
                        }
                    >
                        <Avatar icon={<UserOutlined />} className="cursor-pointer" />
                    </Dropdown>
                </div>
            </div>
            <div className={'py-5 px-5'}>
                {children}
            </div>
        </div>
    );
}
