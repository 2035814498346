import React, { useState } from 'react';
import { Head } from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import { Card, Flex, Form, Input, Button, message } from "antd";
import axios from 'axios'; // Or use fetch if preferred
import TextArea from "antd/lib/input/TextArea";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function Help({ user }) {
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            await axios.post('/api/help/store', values);
            message.success(`Our team member will contact you shortly on ${user.email}.`);
        } catch (error) {
            message.error("There was an issue submitting your request.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Help</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                <Title level={3} className={'m-0'}>Help</Title>
            </Flex>
            <p className={'m-0 p-0 mb-4 font-bold text-[20px]'}>
                Technical/Billing Help<br />
                <small className={'font-normal text-[12px]'}>Email replies will be sent on {user.email}, please make sure to check your spam email.</small>
            </p>

            <Card size={'small'} bordered className="mb-2">
                <Form layout="vertical" onFinish={onFinish} >
                    <Form.Item
                        label={'Subject'}
                        name="subject"
                        rules={[{ required: true, message: 'Please enter the subject' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={'Message'}
                        name="message"
                        rules={[{ required: true, message: 'Please enter your message' }]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </AuthSideBarWrapper>
    )
}
