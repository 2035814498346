import {Button, message, Table, Tag, Tooltip} from "antd";

import {Head, Link} from "@inertiajs/react";
import React, {useEffect, useState} from "react";
import ProductSidebarWrapper from "../components/Navs/ProductSidebarWrapper";
import {CheckCircleFilled, ExclamationCircleFilled, WarningFilled} from "@ant-design/icons";
import axios from "axios";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function ProductIntegrationIndex({product}) {
    const [syncLoader, setSyncLoader] = useState(false);

    const handleProductSync = (e, productId) => {
        e.stopPropagation();
        setSyncLoader(true);
        axios.post(`/api/products/${productId}/sync`, {
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            message.success('Product Is Synced!');
        }).catch((error) => {
            if (error.response && error.response.status === 402) {
                message.error(<span>Your AI Credits are finished. Subscribe now to upgrade your plan.<div className={'flex justify-end mt-2'}><Button size={'small'} type={'primary'} onClick={() => {window.location.href = '/billing/plans'}}>Choose Plan</Button></div></span>).then();
            } else {
                message.error('It seems our servers are quite busy right now, please try again later.');
            }
        }).finally(() => {
            setSyncLoader(false);
        });
    }

    const handleUnlink = (integrationId) => {
        axios.patch(`/api/products/${product.id}/integrations/${integrationId}/update/unlink`)
            .then(response => {
                window.location.reload();
                // You can add additional success handling here
            })
            .catch(error => {
                message.error('It seems our servers are quite busy right now, please try again later.');
                console.error('Error unlinking product:', error);
                // Handle the error here
            });
    };

    const columns = [
        // {
        //     title: 'Image',
        //     dataIndex: 'image',
        //     key: 'image',
        //     width: 45,
        //     render: (text, record) => <div dangerouslySetInnerHTML={{__html:record.icon}}></div>,
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
        },
        {
            title: 'Domain',
            dataIndex: 'url',
            key: 'url',
            width: 400,
        },
        {
            title: 'Sync Status',
            dataIndex: 'status',
            key: 'status',
            width: 400,
            render: (text, record) => (
                    record.channel.sync_status === 1
                        ?
                        <Tooltip placement={'left'} title={'Synced'}>
                            <CheckCircleFilled className={'text-green-600'} />
                        </Tooltip>
                        :
                        <Tooltip placement={'left'} title={'Not synced, Click to sync your product'}>
                            <ExclamationCircleFilled className={'text-orange-500'} onClick={(e) => handleProductSync(e, product.id)} />
                        </Tooltip>
            ),
        },
        {
            title: 'Preview',
            dataIndex: 'barcode',
            key: 'barcode',
            width: 400,
            render: (text, record) => (
                <a href={`https://${record.url}/products/${product.handle}`} target={'_blank'}>
                    Preview
                </a>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 100,
            render: (text, record) => (
                <Button size={'small'} onClick={() => handleUnlink(record.id)}>
                    Unlink
                </Button>
            ),
        },
    ];

    return (
        <AuthSideBarWrapper>
            <ProductSidebarWrapper product={product}>
                <Head>
                    <title>Integrations</title>
                </Head>
                <Table
                    size={'small'}
                    pagination={{ pageSize: 100 }}
                    loading={false}
                    columns={columns}
                    dataSource={product?.integrations} // Render filtered variants
                    rowKey="id"
                />
            </ProductSidebarWrapper>
        </AuthSideBarWrapper>
    )
}
