import {Button, Card, Col, Empty, Modal, Row, Form, Input, Flex, Avatar} from "antd";
import {Head, Link} from "@inertiajs/react";
import React, { useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import {
    ArrowRightOutlined, CheckCircleFilled,
    CiCircleFilled,
    InfoCircleFilled,
    PlusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import axios from "axios";
import WooCommerceInput from "../components/Forms/WooCommerceInput";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";
import shopifyIcon from '../../../public/media/shopifyIcon.svg';
import wooIcon from '../../../public/media/wooIcon.svg';
import bigIcon from '../../../public/media/bigIcon.svg';

export default function IntegrationIndex() {

    const [integrations, setIntegrations] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modalBody, setModalBody] = useState(null); // To control modal content

    useEffect(() => {
        axios.get('/api/integrations')
            .then(response => {
                setIntegrations(response.data.integrations);
            })
            .catch(error => {
                console.error("There was an error fetching the integrations!", error);
            }).finally(() => {
            setLoading(false);
        });
    }, []);

    const showModal = (body) => {
        setModalBody(body);
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setModalBody(null); // Reset modal body on close
    };

    const handleIntegrationCreate = (integrationType) => {
        if (integrationType === 'woocommerce') {
            showModal(<WooCommerceForm />);
        } else if (integrationType === 'shopify') {
            showModal(<ShopifyForm />);
        }
    };

    // WooCommerce Form Component
    const WooCommerceForm = () => {
        const onFinish = (values) => {
            axios.post('/api/integrations/store', values)
                .then(response => {
                    window.location.href = `/dashboard`;
                })
                .catch(error => {
                    console.error("There was an error fetching the integrations!", error);
                });
        };

        return (
            <div>
                <Flex gap={4} align={'center'} className={'mb-3'}>
                    <b>New WooCommerce Integration</b>
                </Flex>
                <Title level={5}>Automatic Integration (recommended)</Title>
                <WooCommerceInput />
                <div className={'mb-5'}>

                </div>
                <Title level={5}>Manual Integration (<a href={'https://developer.woocommerce.com/docs/getting-started-with-the-woocommerce-rest-api/#3-generate-keys'} target={'_blank'}><small>Read Docs</small></a>)</Title>
                <Form onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="type"
                        hidden={true}
                        initialValue={'woocommerce'}
                    >
                        <Input />
                    </Form.Item>
                    <label>Store URL</label>
                    <Form.Item
                        name="shop"
                        className={'mb-1'}
                        rules={[
                            {required: true, message: 'Please enter your store URL!'},
                            {type: 'url', message: 'Please enter a valid URL!'},
                            {
                                pattern: /^https?:\/\/[\w\-]+(\.[\w\-]+)+$/,
                                message: 'URL must be valid and end without slashes'
                            }
                        ]}
                    >
                        <Input placeholder="Enter WooCommerce store URL"/>
                    </Form.Item>
                    <label>Store Token</label>
                    <Form.Item
                        name="token"
                        className={'mb-1'}
                        rules={[
                            {required: true, message: 'Please enter your store token'},
                        ]}
                    >
                        <Input placeholder="Enter WooCommerce store token"/>
                    </Form.Item>
                    <label>Store Secret</label>
                    <Form.Item
                        name="secret"
                        rules={[
                            {required: true, message: 'Please enter your store secret'},
                        ]}
                    >
                        <Input placeholder="Enter WooCommerce store secret"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Start Integration
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    // Shopify Form Component
    const ShopifyForm = () => {
        const onFinish = (values) => {
            window.location.href = `/auth/shopify/begin?shop=${values.shop}`;
        };

        return (
            <div>
                <Flex gap={4} align={'center'} className={'mb-3'}>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="38" height="38" viewBox="0 0 48 48">
                        <path fill="#7cb342"
                              d="M37.216,11.78c-0.023-0.211-0.211-0.305-0.351-0.305s-3.21-0.234-3.21-0.234s-2.132-2.132-2.39-2.343	c-0.234-0.234-0.68-0.164-0.867-0.117c-0.023,0-0.469,0.141-1.195,0.375c-0.726-2.086-1.968-3.984-4.194-3.984h-0.211	C24.187,4.375,23.391,4,22.735,4c-5.155,0-7.639,6.444-8.412,9.725c-2.015,0.633-3.445,1.054-3.609,1.125	c-1.125,0.351-1.148,0.375-1.289,1.429c-0.117,0.797-3.046,23.456-3.046,23.456L29.179,44l12.373-2.671	C41.575,41.282,37.24,11.991,37.216,11.78z M27.937,9.483c-0.562,0.164-1.242,0.375-1.921,0.609V9.671	c0-1.265-0.164-2.296-0.469-3.117C26.718,6.695,27.445,7.984,27.937,9.483L27.937,9.483z M24.117,6.812	c0.305,0.797,0.516,1.922,0.516,3.468v0.234c-1.265,0.398-2.601,0.797-3.984,1.242C21.422,8.804,22.899,7.351,24.117,6.812	L24.117,6.812z M22.617,5.359c0.234,0,0.469,0.094,0.656,0.234c-1.664,0.773-3.421,2.718-4.148,6.655	c-1.101,0.351-2.156,0.656-3.163,0.984C16.806,10.233,18.915,5.359,22.617,5.359z"></path>
                        <path fill="#558b2f"
                              d="M36.865,11.428c-0.141,0-3.21-0.234-3.21-0.234s-2.132-2.132-2.39-2.343	C31.17,8.757,31.053,8.71,30.96,8.71L29.249,44l12.373-2.671c0,0-4.335-29.338-4.359-29.549	C37.169,11.569,37.005,11.475,36.865,11.428z"></path>
                        <path fill="#fff"
                              d="M24.792,18.593l-1.475,4.449c0,0-1.337-0.715-2.927-0.715c-2.374,0-2.489,1.498-2.489,1.867	c0,2.028,5.301,2.812,5.301,7.583c0,3.757-2.374,6.177-5.578,6.177c-3.872,0-5.808-2.397-5.808-2.397l1.037-3.411	c0,0,2.028,1.752,3.734,1.752c1.129,0,1.59-0.876,1.59-1.521c0-2.651-4.333-2.766-4.333-7.145c0-3.665,2.628-7.214,7.952-7.214	C23.777,17.994,24.792,18.593,24.792,18.593z"></path>
                    </svg>
                    <b>New Shopify Integration</b>
                </Flex>
                <Form onFinish={onFinish} layout="vertical">
                    <label>Store URL</label>
                    <Form.Item
                        name="shop"

                    >
                        <Input placeholder="Enter Shopify store URL"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Start Integration
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Integrations</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'top'}>
                <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                    <div>
                        <Title level={4} className={'m-0'}>
                            Add Integrations
                        </Title>
                        <p className={'m-0 p-0'}>Sync your data from different platforms or sync data to multiple stores. Attach your store and start syncing.</p>
                    </div>
                </Flex>
            </Flex>

            <Row gutter={6} className={'mb-3'}>
                <Col span={8}>
                    <Card size={'small'} onClick={() => handleIntegrationCreate('shopify')}
                          className={'cursor-pointer hover:shadow'}>
                        <div className={'flex justify-between'}>
                            <div className={'flex items-center gap-2'}>
                                <Avatar src={shopifyIcon} size={'small'} shape={'square'}/>
                                <b>Connect Shopify</b>
                            </div>
                            <PlusCircleOutlined />
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size={'small'} onClick={() => handleIntegrationCreate('woocommerce')}
                          className={'cursor-pointer hover:shadow'}>
                        <div className={'flex justify-between'}>
                            <div className={'flex items-center gap-2'}>
                                <Avatar src={wooIcon} size={'small'} shape={'square'}/>
                                <b>Connect WooCommerce</b>
                            </div>
                            <PlusCircleOutlined/>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size={'small'}
                          className={'cursor-pointer hover:shadow'}>
                        <div className={'flex justify-between'}>
                            <div className={'flex items-center gap-2'}>
                                <Avatar src={bigIcon} size={'small'} shape={'square'}/>
                                <b>Connect BigCommerce <small>(Soon)</small></b>
                            </div>
                            <PlusCircleOutlined/>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Card className={'bg-gray-200'} size={'small'} loading={loading}>
                <Row gutter={6}>
                    {integrations.length > 0 ? (
                        integrations.map((integration, index) => (
                            <Col key={index} span={5}>
                                <Link /**href={`/integrations/${integration.id}/show`}*/>
                                    <Card size={'small'}
                                          onClick={() => window.location.href = `#`}
                                          className={'cursor-pointer hover:shadow'}>
                                        {
                                            integration.type === 'woocommerce' ? (
                                                <Avatar src={wooIcon} size={'small'} shape={'square'}/>
                                            ) : (
                                                integration.type === 'bigcommerce' ? (
                                                        <Avatar src={bigIcon} size={'small'} shape={'square'}/>
                                                    ) : (
                                                        <Avatar src={shopifyIcon} size={'small'} shape={'square'}/>
                                                    )
                                            )
                                        }
                                        <Flex align={'center'} gap={5}>
                                            <b>{integration.name}</b>
                                        </Flex>
                                        {/*<div className={'flex items-center gap-1'}>*/}
                                        {/*    <CheckCircleFilled className={'text-green-700'} />*/}
                                        {/*    <small>Connected</small>*/}
                                        {/*</div>*/}
                                        <ArrowRightOutlined/>
                                    </Card>
                                </Link>
                            </Col>
                        ))
                    ) : (
                        <Col span={24}>
                            <Empty description="No integrations found"/>
                        </Col>
                    )}
                </Row>
            </Card>
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                {modalBody}
            </Modal>
        </AuthSideBarWrapper>
    );
}
