import React, {useEffect, useState} from 'react';
import {Badge, Button, Card, Flex, message, Spin, Tag} from 'antd';
import {AlignLeftOutlined, CheckOutlined, TagOutlined, TagsOutlined} from '@ant-design/icons';
import Title from "antd/lib/typography/Title";
import {Head, usePage} from "@inertiajs/react";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

const plansData = [
    {
        id: 1,
        title: <p>Basic<br /><small>Best for startups/testers</small></p>,
        price: <span><Title level={2} style={{ marginBottom: 0, marginTop: 0 }}>$5</Title><p className={'mt-0'}>per month</p></span>,
        mainFeatures: [<><AlignLeftOutlined className="mr-2" /> Upto 250k AI words </>,
            <><TagOutlined className="mr-2" /> Upto 100 Products </>],
        subHeading: 'Basic plan includes',
        features: ["Create & update products", "Create & update collections", "Basic support"],
        buttonText: "Get started",
        link: "/billing/plans/1/subscribe",
    },
    {
        id: 2,
        title: <p>Growth<br /><small>Recommended for business growth</small></p>,
        price: <span><Title level={2} style={{ marginBottom: 0, marginTop: 0 }}>$25</Title><p className={'mt-0'}>per month</p></span>,
        mainFeatures: [<><AlignLeftOutlined className="mr-2" /> Upto 1.3m AI words </>,
            <><TagOutlined className="mr-2" /> Upto 400 Products </>],
        subHeading: 'Growth plan includes',
        features: ["Create & update products", "Create & update collections", "Create & update blogs", "Premium support"],
        buttonText: "Get started",
        link: "/billing/plans/2/subscribe",
    },
    {
        id: 3,
        title: <p>Enterprise<br /><small>Big businesses that want to scale</small></p>,
        price: <span><Title level={2} style={{ marginBottom: 0, marginTop: 0 }}>$100</Title><p className={'mt-0'}>per month</p></span>,
        mainFeatures: [<><AlignLeftOutlined className="mr-2" /> Upto 5m AI words </>,
            <><TagOutlined className="mr-2" /> Upto 10,000 Products </>],
        subHeading: 'Enterprise plan includes',
        features: ["Create & update products", "Create product data with image", "Create & update collections", "Create & update blogs", "Premium support", "Dedicated account manager"],
        buttonText: "Get started",
        link: "/billing/plans/3/subscribe",
    }
];

const Plans = ({ planId }) => {
    const { props } = usePage();
    const { success } = props;
    const { error } = props;

    useEffect(() => {
        if (success) {
            message.success(success);
        }
    }, [success]);


    useEffect(() => {
        if (error) {
            message.error(error);
        }
    }, [error]);

    const [loading, setLoading] = useState(null);

    const handleButtonClick = (id) => {
        setLoading(id);
        window.location.href = plansData.find(plan => plan.id === id).link;
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Billing</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-0'}>
                <Title level={3} className={'m-0'}>Billing</Title>
            </Flex>
            <Title level={3} style={{ marginBottom: 25 }} className="text-center p-0">Measurable <span className="text-green-600">$avings</span> from day one</Title>
            <div className="container grid grid-cols-1 md:grid-cols-3 mb-3 gap-2">
                {plansData.map(plan => (
                    <Card
                        key={plan.id}
                        title={plan.title}
                        bordered={false}
                        className={`shadow-lg ${plan.id === 3 ? 'border-2 border-green-500' : ''}`}
                        styles={{
                            header: {
                                backgroundColor: plan.id === 2 ? 'rgb(0,185,107)' :
                                    plan.id === 1 ? 'rgb(38,38,38)' :
                                        plan.id === 3 ? '#262626' : '',
                                color: plan.id === 2 ? 'white' : 'white'
                            }
                        }}
                        style={{
                            boxShadow: plan.id === 2 ? '0 4px 8px rgba(0, 0, 0, 0.3)' : ''
                        }}
                    >
                        {
                            plan.id === planId
                                ?
                                <Tag className={'mb-4 bg-green-500 text-white border-0 text-[14px] font-bold'}>Current Plan</Tag>
                                :
                                ''
                        }

                        {plan.price}

                        <Button
                            type={`${plan.id === 2 ? 'primary' : ''}`}
                            className="w-full"
                            disabled={plan.id <= planId}
                            loading={loading === plan.id}
                            onClick={() => handleButtonClick(plan.id)}
                        >
                            {plan.buttonText}
                        </Button>
                        <ul className="mb-4 p-0">
                            {plan.mainFeatures.map((feature, index) => (
                                <li key={index} className="flex m-0 p-0 items-center font-bold text-gray-600 mb-2">
                                    {feature}
                                </li>
                            ))}
                        </ul>
                        <Title level={5}>{plan.subHeading}</Title>
                        <ul className="mb-4 p-0">
                            {plan.features.map((feature, index) => (
                                <li key={index} className="flex items-center mb-2">
                                    <CheckOutlined className="mr-2 text-green-500" />
                                    {feature}
                                </li>
                            ))}
                        </ul>

                    </Card>
                ))}
            </div>

            <Card size={'small'} bordered className="mb-2 w-1/2 m-auto">
                <b>Want to cancel subscription?</b><br />
                When you cancel subscription your remaining words will be cancelled, All your ongoing tasks will end immediately and you have to subscribe again and restart all your tasks.
                <br />
                <br />
                <Button className={'bg-red-600 border-red-800 text-white hover:text-red-700'} onClick={() => window.location.href = '/billing/plans/unsubscribe'}>
                    Cancel Plan
                </Button>
            </Card>
        </AuthSideBarWrapper>
    );
};

export default Plans;
