import React, { useState } from 'react';
import {Table, Button, message, Flex} from 'antd';
import {DeleteOutlined, InboxOutlined} from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';

const BigFileUpload = ({ onSuccess, onError }) => {
    const [fileList, setFileList] = useState([]);
    const [uploadedData, setUploadedData] = useState(null); // State to store uploaded file data
    const [fileName, setFileName] = useState(null); // State to store uploaded file data
    const [columns, setColumns] = useState([]); // Dynamic columns for table
    const [isFileUploaded, setIsFileUploaded] = useState(false); // Toggle between dragger and table


    const props = {
        name: 'file',
        multiple: false, // Single file upload
        action: '/api/import/file/upload',
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        beforeUpload(file) {
            const isValidType = file.type === 'text/csv' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const isValidSize = file.size / 1024 / 1024 < 10;

            if (!isValidType) {
                message.error('You can only upload CSV or XLSX files!');
                return false;
            }
            if (!isValidSize) {
                message.error('File must be smaller than 10MB!');
                return false;
            }

            // Clear previous files
            setFileList([file]); // Only keep the new file
            return true;
        },
        onChange(info) {
            const { status, response } = info.file;
            setFileList([info.file]); // Update fileList with the current file

            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                setIsFileUploaded(true); // Toggle to show table
                setFileName(info.file.name);
                // Process the response data and create dynamic columns
                if (response && response.data && Array.isArray(response.data)) {
                    const [firstRow] = response.data;
                    if (firstRow) {
                        const dynamicColumns = Object.keys(firstRow).map((key) => ({
                            title: key, // Column header
                            dataIndex: key, // Accessor for data
                            key: key,
                            ellipsis: true, // Prevent wrapping, truncate if necessary
                        }));
                        setColumns(dynamicColumns);
                        setUploadedData(response.data); // Set the uploaded data for the table
                    }
                }

                if (onSuccess) onSuccess(response);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
                if (onError) onError(response);
            }
        },
        onRemove(file) {
            if (onSuccess) onSuccess(null);
            setIsFileUploaded(false); // Show the uploader again
            setUploadedData(null); // Clear uploaded data
            setFileList([]); // Reset file list
            setColumns([]); // Reset columns
        }
    };

    const handleReupload = () => {
        setIsFileUploaded(false); // Show the uploader again
        setUploadedData(null); // Clear uploaded data
        setFileList([]); // Reset file list
        setColumns([]); // Reset columns
    };

    return (
        <div>
            {isFileUploaded ? (
                <div>
                    <Table
                        className={'mb-3'}
                        size={'small'}
                        dataSource={uploadedData} // Display the uploaded data in table
                        columns={columns} // Dynamic columns based on CSV headers
                        rowKey={(record, index) => index} // Ensure unique key for rows
                        scroll={{ x: 'max-content' }} // Enable horizontal scrolling based on content
                        pagination={false} // Disable pagination (can enable if needed)
                    />
                    <Flex gap={5} align={'center'} justify={'space-between'}>
                        <div>
                            {fileName} Uploaded successfully.
                        </div>
                        <Button
                            size={'small'}
                            type="danger"
                            className={'hover:bg-red-500 hover:border-red-600 hover:text-white'}
                            icon={<DeleteOutlined />}
                            onClick={() => props.onRemove(fileList)}
                        />
                    </Flex>
                </div>
            ) : (
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag CSV & XLSX file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for single upload. Prohibited to 10mb of file.
                    </p>
                </Dragger>
            )}
        </div>
    );
};

export default BigFileUpload;
