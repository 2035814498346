import React from 'react';
import {Typography, Card, Row, Col, Avatar, Tooltip} from 'antd';
import {Link} from "@inertiajs/react";
const { Title, Text } = Typography;
import shopifyIcon from '../../../../public/media/shopifyIcon64.png';
import wooIcon from '../../../../public/media/wooIcon.svg';
import wordpressIcon from '../../../../public/media/wordpressIcon.svg';
import csvIcon from '../../../../public/media/csvIcon.svg';
import xlsxIcon from '../../../../public/media/xlsxIcon.svg';
import {QuestionCircleFilled, QuestionCircleOutlined} from "@ant-design/icons";

const WelcomeBanner = () => {
    return (
        <Card
            style={{
                backgroundColor: '#e1faeb',
                borderRadius: '8px',
                border: '1px solid #c3e6cb',
                marginBottom: '10px',
            }}
        >
            <Title level={2} style={{ color: '#155724' }}>
                Welcome
            </Title>
            <Text style={{ fontSize: '16px', color: '#155724' }}>
                ShopGPT is the AI-powered platform for creating and managing your online store. Get started by managing your
                first product with AI.
            </Text>
            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={8}>
                    <Title level={4} style={{ color: '#155724' }}>
                        Sync Product Data
                    </Title>
                    <ul style={{color: '#155724', listStyle: 'none', padding: 0}}>
                        <li>
                            <Link href={'/integrations'}
                                  className={' py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}><Avatar
                                size={'small'} src={shopifyIcon} shape={'square'}/><span className={'font-bold'}>Shopify</span></Link>
                        </li>
                        <li>
                            <Link href={'/integrations'}
                                  className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}><Avatar
                                size={'small'} src={wooIcon} shape={'square'}/><span className={'font-bold'}>WooCommerce</span></Link>
                        </li>
                        <li>
                            <Link href={'/integrations'}
                                  className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}><Avatar
                                size={'small'} src={wordpressIcon} shape={'square'}/><span className={'font-bold'}>Wordpress</span></Link>
                        </li>
                        <li>
                            <Link href={'/import/templates/create'}
                                  className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}><Avatar
                                size={'small'} src={csvIcon} shape={'square'}/><span className={'font-bold'}>CSV</span></Link>
                        </li>
                        <li>
                            <Link href={'/import/templates/create'}
                                  className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}><Avatar
                                size={'small'} src={xlsxIcon} shape={'square'}/><span className={'font-bold'}>XLSX</span></Link>
                        </li>
                    </ul>
                </Col>
                <Col span={8}>
                    <Title level={4} style={{color: '#155724'}}>
                        AI Bulk Update
                    </Title>
                    <ul style={{color: '#155724', listStyle: 'none', padding: 0}}>
                        <li>
                            <Tooltip title={'Optimize your product title, description, and meta SEO to align perfectly with your product title for better visibility.'}>
                                <Link href={''}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>AI Fix</span>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={'Enhance your product title by translating, shortening, extending, or rephrasing it to include relevant keywords effectively.'}>
                                <Link href={''}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Product Titles</span>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={'Rephrase or generate a product description that includes all relevant details about your product.'}>
                                <Link href={''}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Product Descriptions</span>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={'Optimize meta SEO for better search engine previews with relevant keywords and ideal length.'}>
                                <Link href={''}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Product Meta SEO</span>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={'Refine your product tags to improve rankings and visibility.'}>
                                <Link href={''}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Product Tags</span>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={'Edit or enhance your collection descriptions to provide better clarity and boost SEO.'}>
                                <Link href={''}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Collections</span>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={'Create or rephrase blogs to engage readers and improve SEO.'}>
                                <Link href={''}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Blogs</span>
                                </Link>
                            </Tooltip>
                        </li>
                    </ul>
                </Col>
                <Col span={8}>
                    <Title level={4} style={{color: '#155724'}}>
                        Settings
                    </Title>
                    <ul style={{color: '#155724', listStyle: 'none', padding: 0}}>
                        <li>
                            <Tooltip title={'Switch the AI model\'s language to generate data in your preferred language.'}>
                                <Link href={'/settings'}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Change AI Language</span>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={'Choose to preview changes before syncing to your store or enable auto-sync for direct updates.'}>
                                <Link href={'/settings'}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Auto Sync Off</span>
                                </Link>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={'Get instant assistance via email from our dedicated support team.'}>
                                <Link href={'/help'}
                                      className={'py-2 px-1 text-green-900 hover:text-green-900 hover:bg-green-200 rounded flex items-center gap-2'}>
                                    <QuestionCircleOutlined/>
                                    <span className={'font-bold'}>Help</span>
                                </Link>
                            </Tooltip>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Title level={5} style={{color: '#155724'}}>
                <Tooltip title={'Message us your requirement or what you are looking for and we will respond you back on how to do that.'}>
                    <Link href={'/help'} className={'underline text-green-900'}><span className={'text-green-900 underline'}>Looking for something else?</span></Link>
                </Tooltip>
            </Title>
        </Card>
    );
};

export default WelcomeBanner;
