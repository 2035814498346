
import {Head} from "@inertiajs/react";
import React, {useState} from "react";
import ProductSidebarWrapper from "../components/Navs/ProductSidebarWrapper";
import VariantsTable from "../components/Tables/VariantsTable";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";
import MediaGrid from "../components/Tables/MediaGrid";

export default function MediaIndex({product}) {
    const [syncLoader, setSyncLoader] = useState(false);

    return (
        <AuthSideBarWrapper>
            <ProductSidebarWrapper product={product}>
                <Head>
                    <title>Media</title>
                </Head>
                <MediaGrid product={product} media={product.media} />
            </ProductSidebarWrapper>
        </AuthSideBarWrapper>
    )
}
