import React, { useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { VideoCameraTwoTone } from '@ant-design/icons';

const VideoTutorial = ({ url }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Tooltip title="Watch Tutorial Video">
            <VideoCameraTwoTone onClick={showModal} style={{cursor: 'pointer' }} />

            <Modal
                title="Watch Video"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={700}
            >
                <iframe
                    style={{height:'400px'}}
                    className="absolute top-0 left-0 w-full h-full"
                    src={isModalVisible ? url : ''}
                    title="YouTube video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </Modal>
        </Tooltip>
    );
};

export default VideoTutorial;
