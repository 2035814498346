import React from 'react';
import { Table, Button, Card, Row, Col } from 'antd';
import { Inertia } from '@inertiajs/inertia';
import TopBarWrapper from "../../components/Admin/TopBarWrapper";
import SubscriptionLineGraph from "../../components/Admin/SubscriptionLineGraph";

const Billing = ({ pendingCharges, activeCharges, cancelledCharges }) => {

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Login Type',
            dataIndex: 'login_type',
            key: 'login_type',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className={'flex gap-1'}>
                    <Button onClick={() => impersonate(record.id)}>Login as</Button>
                </div>
            ),
        },
    ];

    return (
        <TopBarWrapper>
            <div className="mb-4">
                <Row gutter={16}>
                    <Col span={6}>
                        <Card title="Pending" bordered={false}>
                            {pendingCharges}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Active" bordered={false}>
                            {activeCharges}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Cancelled" bordered={false}>
                            {cancelledCharges}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="All" bordered={false}>
                            {pendingCharges+activeCharges+cancelledCharges}
                        </Card>
                    </Col>
                </Row>
            </div>
            <SubscriptionLineGraph />
        </TopBarWrapper>
    );
};

export default Billing;
