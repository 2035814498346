import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Flex, Spin, Card } from 'antd';
import axios from 'axios';

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip);

const ProductCompletenessPie = () => {
    const [synced, setSynced] = useState(0);
    const [updated, setUpdated] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Make API request to get product completeness stats
        axios
            .get('/api/stats/product/sync')
            .then((response) => {
                const { synced, updated } = response.data;
                setSynced(synced);
                setUpdated(updated);
                setLoading(false); // Set loading to false after data is loaded
            })
            .catch((error) => {
                console.error('Error fetching product sync stats:', error);
                setLoading(false);
            });
    }, []);

    // Determine if both values are zero
    const allValuesAreZero = synced === 0 && updated === 0;

    // Chart data
    const data = {
        labels: allValuesAreZero ? ['No Data'] : ['Synced', 'Updated'],
        datasets: [
            {
                label: 'Synced Products',
                data: allValuesAreZero ? [1] : [synced, updated],
                backgroundColor: allValuesAreZero ? ['#d9d9d9'] : ['#3f8600', '#e06315'],
                hoverBackgroundColor: allValuesAreZero ? ['#bfbfbf'] : ['#66bb6a', '#ffb74d'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        return `${value}`;
                    },
                },
            },
        },
        cutout: '60%',
        layout: {
            padding: 0,
        },
    };

    const chartContainerStyle = {
        width: '60px',
        height: '60px',
        margin: 0,
        padding: 0,
    };

    return (
        <Flex gap={0} align={'center'}>
            <div style={chartContainerStyle}>
                <Doughnut data={data} options={options} />
            </div>
            <div className={'text-[12px]'}>
                <ul className={'m-0 pl-5'}>
                    <li>
                        Synced{' '}
                        <span style={{ color: '#3f8600' }} className={'font-bold'}>
                            <ArrowUpOutlined /> {synced}
                        </span>
                    </li>
                    <li>
                        Updated{' '}
                        <span style={{ color: '#e06315' }} className={'font-bold'}>
                            <ArrowDownOutlined /> {updated}
                        </span>
                    </li>
                </ul>
            </div>
        </Flex>
    );
};

export default ProductCompletenessPie;
