import React, { useState, useEffect } from 'react';
import {Card, Button, Select, Input, Typography, Space, Flex, Steps, message} from 'antd';
import { Head } from "@inertiajs/react";
import axios from "axios";
import IntegrationsMultiDropDown from "@/components/Forms/IntegrationsMultiDropDown";
import CollectionsMultiDropDown from "@/components/Forms/CollectionsMultiDropDown";
import TextArea from "antd/lib/input/TextArea";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

const { Title } = Typography;
const { Option } = Select;

const BulkUpdateAction = ({ savedPayload, action }) => {

    // Configurations for each criteria with `name` added
    const filterConfig = {
        all: {
            name: 'All Products', // User-friendly name
            conditionOptions: null,
            InputOption: null
        },
        title: {
            name: 'Title', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="equals_to">Equals to</Option>
                    <Option key={2} value="not_equals_to">Not Equals to</Option>
                    <Option key={3} value="contains">Contains</Option>
                    <Option key={4} value="not_contains">Not Contains</Option>
                </Select>
            ),
            InputOption: <Input style={{ width: 200 }} placeholder="Enter title" />
        },
        description: {
            name: 'Description', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="contains">Contains</Option>
                    <Option key={2} value="not_contains">Not Contains</Option>
                </Select>
            ),
            InputOption: <Input style={{ width: 200 }} placeholder="Enter description" />
        },
        tags: {
            name: 'Tags', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="contains">Contains</Option>
                </Select>
            ),
            InputOption: <Input style={{ width: 200 }} placeholder="Enter comma-separated tags" />
        },
        sync_status: {
            name: 'Sync Status', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="equals_to">Equals to</Option>
                    <Option key={2} value="not_equals_to">Not Equals to</Option>
                </Select>
            ),
            InputOption: (
                <Select style={{ width: 200 }} placeholder="Select status">
                    <Option key={1} value="synced">Synced</Option>
                    <Option key={2} value="updated">Not Synced</Option>
                    <Option key={3} value="issue">Issue</Option>
                </Select>
            )
        },
        collections: {
            name: 'Collections/Categories', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="contains">Contains</Option>
                </Select>
            ),
            InputOption: <CollectionsMultiDropDown />
        },
        integrations: {
            name: 'Integrations', // User-friendly name
            conditionOptions: (
                <Select style={{ width: 200 }} placeholder="Select Condition">
                    <Option key={1} value="contains">Contains</Option>
                </Select>
            ),
            InputOption: <IntegrationsMultiDropDown />
        }
    };

    // Actions configuration with `with` added similar to filterConfig
    const actionsConfig = {
        delete: {
            name: 'Bulk Delete',
            with: null // No additional input needed
        },
        sync: {
            name: 'Bulk Sync',
            with: <IntegrationsMultiDropDown />
        },
        ai_fix: {
            name: 'AI Fix',
            with: (
                <Select mode={"multiple"} style={{ width: 200 }} placeholder="Select Product Attributes">
                    <Option key={1} value="title">Title</Option>
                    <Option key={2} value="description">Description</Option>
                </Select>
            )
        },
        ai_fix_title: {
            name: 'AI Fix Title',
            with: <TextArea style={{ width: 200 }} placeholder="Initial AI Prompt" />
        },
        ai_fix_description: {
            name: 'AI Fix Description',
            with: <TextArea style={{ width: 200 }} placeholder="Initial AI Prompt" />
        },
        ai_fix_tags: {
            name: 'AI Fix Tags',
            with: <TextArea style={{ width: 200 }} placeholder="Initial AI Prompt" />
        },
        ai_fix_meta: {
            name: 'AI Fix SEO Meta',
            with: <TextArea style={{ width: 200 }} placeholder="Initial AI Prompt" />
        },
        assign_category: {
            name: 'Assign Collections/Categories',
            with: <CollectionsMultiDropDown />
        },
        assign_integrations: {
            name: 'Assign Integrations',
            with: <IntegrationsMultiDropDown />
        }
    };

    // State to store multiple filters and actions
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const [actions, setActions] = useState([]); // New state for actions

    // Criteria and actions options with the new `name` attribute
    const criteriaOptions = Object.keys(filterConfig).map(key => ({
        value: key,
        label: filterConfig[key].name
    }));

    const actionOptions = Object.keys(actionsConfig).map(key => ({
        value: key,
        label: actionsConfig[key].name
    }));

    // Load saved payload and pre-populate the filters
    useEffect(() => {
        if (savedPayload) {
            const prePopulatedFilters = Object.keys(savedPayload.filters || {}).map(key => ({
                criteria: key,
                condition: savedPayload.filters[key].condition,
                value: savedPayload.filters[key].value
            }));
            setFilters(prePopulatedFilters);

            const prePopulatedActions = Object.keys(savedPayload.actions || {}).map(key => ({
                action: key,
                withValue: savedPayload.actions[key].with
            }));
            setActions(prePopulatedActions);
        }
    }, [savedPayload]);


    // Load saved payload and pre-populate the filters
    useEffect(() => {
        if (action) {
            setName(action.name);
        }
    }, [action]);

    // Handle criteria change
    const handleCriteriaChange = (index, value) => {
        const updatedFilters = [...filters];
        updatedFilters[index].criteria = value;
        updatedFilters[index].condition = null;
        updatedFilters[index].value = '';
        setFilters(updatedFilters);
    };

    // Handle condition change
    const handleConditionChange = (index, value) => {
        const updatedFilters = [...filters];
        updatedFilters[index].condition = value;
        setFilters(updatedFilters);
    };

    // Handle input change for filters
    const handleInputChange = (index, e) => {
        const updatedFilters = [...filters];
        updatedFilters[index].value = e.target ? e.target.value : e; // Support for dropdown select too
        setFilters(updatedFilters);
    };

    // Handle action change
    const handleActionChange = (index, value) => {
        const updatedActions = [...actions];
        updatedActions[index].action = value;
        updatedActions[index].withValue = []; // Reset the withValue when action changes
        setActions(updatedActions);
    };

    // Handle with change for actions
    const handleWithChange = (index, value) => {
        const updatedActions = [...actions];
        // If the event is an object (TextArea, for example), take the target value; otherwise, use value directly
        updatedActions[index].withValue = value?.target ? value.target.value : value;
        setActions(updatedActions);
    };

    // Add a new filter row
    const addNewFilter = () => {
        setFilters([...filters, { criteria: null, condition: null, value: '' }]);
    };

    // Add a new action row
    const addNewAction = () => {
        setActions([...actions, { action: null, withValue: [] }]);
    };

    // Remove filter row
    const removeFilter = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters(updatedFilters);
    };

    // Remove action row
    const removeAction = (index) => {
        const updatedActions = actions.filter((_, i) => i !== index);
        setActions(updatedActions);
    };

    // Function to generate JSON payload for API
    const generateJsonPayload = async () => {
        setLoading(true);
        const payload = {
            filters: {},
            actions: {},
            name: name
        };

        filters.forEach(filter => {
            if (filter.criteria) {
                payload.filters[filter.criteria] = {
                    condition: filter.condition,
                    value: filter.value
                };
            }
        });

        actions.forEach(action => {
            if (action.action) {
                payload.actions[action.action] = {
                    with: Array.isArray(action.withValue) && action.withValue.length === 0 ? null : action.withValue
                };
            }
        });

        try {
            if(action) {
                const response = await axios.put(`/api/actions/${action.id}/update`, payload);
            }
            else {
                const response = await axios.post('/api/actions/store', payload);
            }
            message.success('Action Saved!');
            window.location.href = '/actions';
        } catch (error) {
            if (error.response && error.response.status === 422) {
                // Laravel validation error
                const validationErrors = error.response.data.errors;
                Object.keys(validationErrors).forEach((key) => {
                    message.error(validationErrors[key][0]); // Display the first validation error for each field
                });
            } else {
                // General error handling
                message.error("Our servers are busy right now, please try again later.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Actions</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'top'}>
                <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                    <div>
                        <Title level={4} className={'m-0'}>Actions</Title>
                        <p className={'m-0 p-0'}>Choose an action from below.</p>
                    </div>
                </Flex>

                <Button type={'primary'} onClick={generateJsonPayload}>
                    Request New Action
                </Button>
            </Flex>

            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {/* Filters Section */}

                <Steps
                    direction="vertical"
                    size="small"
                    items={[
                        {
                            title: 'Choose Filtered Products',
                            description: <Card className={'bg-gray-200'} title={"Filter Products"} size={'small'}>
                                {filters.map((filter, index) => (
                                    <Flex key={index} gap={5} wrap={true} align="center" size={8} style={{ marginBottom: 16 }}>
                                        {/* Criteria Select */}
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder="Select Criteria"
                                            value={filter.criteria}
                                            onChange={(value) => handleCriteriaChange(index, value)}
                                        >
                                            {criteriaOptions.map(option => (
                                                <Option key={option.value} value={option.value}>{option.label}</Option>
                                            ))}
                                        </Select>

                                        {/* Condition Options */}
                                        {filter.criteria && filterConfig[filter.criteria].conditionOptions && React.cloneElement(
                                            filterConfig[filter.criteria].conditionOptions,
                                            { value: filter.condition, onChange: (value) => handleConditionChange(index, value) }
                                        )}

                                        {/* Input Option */}
                                        {filter.criteria && filterConfig[filter.criteria].InputOption && React.cloneElement(
                                            filterConfig[filter.criteria].InputOption,
                                            { value: filter.value, onChange: (e) => handleInputChange(index, e) }
                                        )}

                                        {/* Remove Filter Button */}
                                        {filters.length > 1 && (
                                            <Button type={'link'} onClick={() => removeFilter(index)}>Remove</Button>
                                        )}
                                    </Flex>
                                ))}

                                <Button type="dashed" onClick={addNewFilter}>
                                    Add Filter
                                </Button>
                            </Card>
                        },
                        {
                            title: 'Choose Set of Actions to Perform',
                            description: <>
                                {/* Actions Section */}
                                <Card className={'bg-gray-200'} title="Actions" size={'small'}>
                                    {actions.map((actionItem, index) => (
                                        <div key={index} style={{ marginBottom: 16 }}>
                                            <Space>
                                                <Select
                                                    value={actionItem.action}
                                                    placeholder="Select Action"
                                                    style={{ width: 200 }}
                                                    onChange={(value) => handleActionChange(index, value)}
                                                >
                                                    {actionOptions.map(option => (
                                                        <Option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select>

                                                {actionItem.action && actionsConfig[actionItem.action].with && (
                                                    <div>
                                                        {React.cloneElement(actionsConfig[actionItem.action].with, {
                                                            value: actionItem.withValue,
                                                            onChange: (value) => handleWithChange(index, value)
                                                        })}
                                                    </div>
                                                )}

                                                <Button type={'link'} onClick={() => removeAction(index)}>Remove</Button>
                                            </Space>
                                        </div>
                                    ))}
                                    <Button type={'dashed'} onClick={addNewAction}>Add Action</Button>
                                </Card>
                            </>
                        },
                        {
                            title: 'Name this Action',
                            description: <Input value={name} placeholder={'Enter Action Name'} onChange={(e) => setName(e.target.value)} />
                        },
                        {
                            title: 'Run this Action',
                            description: <Button loading={loading} type={"primary"} onClick={generateJsonPayload}>Save Action</Button>
                        }
                    ]}
                />
            </Space>
        </AuthSideBarWrapper>
    );
};

export default BulkUpdateAction;
