import React, { useState } from 'react';
import { Table, Input, Row, Col, Button, message } from "antd";
import axios from 'axios';
import { debounce } from 'lodash';

export default function MetafieldsTable({ product, metafields }) {
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState(metafields);
    const [filteredMetafields, setFilteredMetafields] = useState(metafields);
    const [editingValues, setEditingValues] = useState({}); // Track changes

    const { Search } = Input;

    // Debounced search function
    const handleSearch = debounce((value) => {
        setSearchValue(value);
        const lowercasedValue = value.toLowerCase();

        const filtered = metafields.filter(metafield => {
            return (
                metafield.name.toLowerCase().includes(lowercasedValue) ||
                metafield.owner_resource.toLowerCase().includes(lowercasedValue)
            );
        });

        setFilteredMetafields(filtered);
    }, 500); // 500ms debounce

    const handleInputChange = (e, key, record) => {
        const { value } = e.target;
        setEditingValues((prev) => ({
            ...prev,
            [record.id]: {
                ...prev[record.id],
                [key]: value,
            },
        }));
    };

    const handleSave = async () => {
        try {
            const productId = product.id;
            const updatedMetafields = dataSource.map(item => ({
                ...item,
                ...editingValues[item.id],
            }));

            await axios.post(`/api/products/${productId}/metafields/update`, { metafields: updatedMetafields });

            setDataSource(updatedMetafields);
            setFilteredMetafields(updatedMetafields);
            message.success("Metafields updated successfully!");
        } catch (error) {
            console.error("Error saving data:", error);
            message.error("Our server is busy right now, please try again.");
        }
    };

    const columns = [
        {
            title: 'Metafield Key',
            dataIndex: 'key',
            key: 'key',
            width: 200,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            width: 400,
            render: (text, record) => (
                <Input
                    value={editingValues[record.id]?.value || text}
                    onChange={(e) => handleInputChange(e, 'value', record)}
                />
            ),
        },
    ];

    return (
        <>
            <div className='w-100 bg-white rounded p-2 mb-1'>
                <Row className="justify-between">
                    <Col className={'mb-2'}>
                        <Search
                            placeholder="Search metafield by name or owner resource"
                            onChange={e => handleSearch(e.target.value)}
                            style={{ width: 300 }}
                        />
                    </Col>
                    <Col>
                        <Button type={'primary'} onClick={handleSave}>Update Metafields</Button>
                    </Col>
                </Row>
            </div>
            <Table
                size={'small'}
                pagination={{ pageSize: 100 }}
                columns={columns}
                dataSource={filteredMetafields}
                rowKey="id"
            />
        </>
    );
}
