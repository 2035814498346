import React, {useEffect, useState} from 'react';
import {Head, usePage} from "@inertiajs/react";
import Title from "antd/lib/typography/Title";
import {Card, Col, Row, message, Flex, Button, Switch} from "antd";
import axios from "axios";
import SelectLanguageDropDown from "@/components/Forms/SelectLanguageDropDown";
import { Popconfirm } from "antd";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function Setting({ user, settings }) {
    const [locale, setLocale] = useState(user.primary_locale);
    const [loading, setLoading] = useState(false);
    const [reviewChanges, setReviewChanges] = useState(settings.length > 0 ? settings.find(item => item.key === "auto_sync").value === '1' : 0);

    const { props } = usePage();
    const { success, error } = props;

    useEffect(() => {
        if (success) {
            message.success(success);
        }
        if (error) {
            message.error(error);
        }
    }, [success, error]);

    const handleLocaleChange = (value) => {
        setLocale(value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.patch('/api/user/update/primary_locale', {
                primary_locale: locale,
            });

            if (response.status === 200) {
                message.success('Locale updated successfully');
            }
        } catch (error) {
            message.error('Failed to update locale');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = '/auth/google/begin';
    }

    const handleAutoSync = async () => {
        try {
            // Make an Axios request to update primary_locale
            const response = await axios.patch('/api/settings/update/auto-sync', {
                auto_sync: reviewChanges, // or dynamically set this value
            });
            message.success('Settings updated successfully');
        } catch (error) {
            // Handle error
            console.error('Error making request:', error);
            // Optionally redirect to an error page or display an error message
        }
    };

    const handleResyncProducts = async () => {
        try {
            const response = await axios.post('/api/integrations/sync/update');
            message.success('Products resyncing in progress, It will take some time.');
        } catch (error) {
            message.error('Maybe our servers are busy, please try again later.');
        }
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Settings</title>
            </Head>
            <Flex wrap justify={'space-between'} align={'center'} className={'mb-2'}>
                <Title level={3} className={'m-0'}>Settings</Title>
            </Flex>
            <p className={'m-0 p-0 mb-4 font-bold text-[20px]'}>Language Settings</p>

            <Row>
                <Col span={24} className="mb-5">
                    <Card size={'small'} bordered className="mb-2">
                        <p className={'mt-0 mb-1'}>This will be the language your content is generated in</p>
                        <SelectLanguageDropDown
                            initialLocale={locale}
                            onChange={handleLocaleChange}
                        />
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            loading={loading}
                            className="mt-2"
                        >
                            Update
                        </Button>
                    </Card>
                    <Card size={'small'} bordered className="mb-2">
                        <p className={'mt-0 mb-1'}>Do you want to sync changes automatically to your integrations/channels?</p>
                        <div className={'mb-3'}>
                            <Switch checked={reviewChanges} onChange={() => setReviewChanges(!reviewChanges)} />
                        </div>
                        <Button
                            onClick={handleAutoSync}
                            type={'primary'}
                            iconPosition={'end'}
                        >
                            Update
                        </Button>
                    </Card>
                    <Card size={'small'} bordered className="mb-2">
                        <p className={'mt-0 mb-1'}>Resync Products</p>
                        <Popconfirm
                            title={
                                <p>
                                    Re-syncing will remove any un-synced changes.
                                    <br/>
                                    Are you sure you want to continue?
                                </p>
                            }
                            onConfirm={handleResyncProducts}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" className="mt-2 bg-red-500">
                                Re-sync Products
                            </Button>
                        </Popconfirm>
                    </Card>
                </Col>
            </Row>
            <p className={'m-0 p-0 mb-4 font-bold text-[20px]'}>Login With:</p>
            <Row>
                <Col span={24} className="mb-5">
                    <Card size={'small'} bordered className="mb-2">
                        <p className={'mt-0 mb-1'}>You can also choose a google account to easily and securely login to your account.</p>

                        {
                            user.google_id
                                ?
                                <div className={'flex items-center gap-2'}>
                                    <svg className="w-6 h-6 text-green-600 dark:text-white" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path fillRule="evenodd"
                                              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    Connected
                                </div>
                                :
                                <button
                                    type="button"
                                    onClick={handleGoogleLogin}
                                    className="bg-white text-gray-800 border-gray-200 px-4 py-2 cursor-pointer hover:bg-white hover:border-gray-300 shadow-md flex items-center gap-2"
                                >
                                    <svg className="w-6 h-6 text-black dark:text-white" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path fillRule="evenodd"
                                              d="M12.037 21.998a10.313 10.313 0 0 1-7.168-3.049 9.888 9.888 0 0 1-2.868-7.118 9.947 9.947 0 0 1 3.064-6.949A10.37 10.37 0 0 1 12.212 2h.176a9.935 9.935 0 0 1 6.614 2.564L16.457 6.88a6.187 6.187 0 0 0-4.131-1.566 6.9 6.9 0 0 0-4.794 1.913 6.618 6.618 0 0 0-2.045 4.657 6.608 6.608 0 0 0 1.882 4.723 6.891 6.891 0 0 0 4.725 2.07h.143c1.41.072 2.8-.354 3.917-1.2a5.77 5.77 0 0 0 2.172-3.41l.043-.117H12.22v-3.41h9.678c.075.617.109 1.238.1 1.859-.099 5.741-4.017 9.6-9.746 9.6l-.215-.002Z"
                                              clipRule="evenodd"/>
                                    </svg>

                                    Connect with Google Login
                                </button>
                        }
                    </Card>
                </Col>
            </Row>
        </AuthSideBarWrapper>
    )
}
