import React, { useEffect, useState } from 'react';
import {Avatar, Button, Card, Progress, Spin, Tooltip} from "antd";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import {ExclamationCircleFilled} from "@ant-design/icons";

export default function DashboardNotifications() {
    const [message, setMessage] = useState("Your store has some issues please contact sales.");
    const [actionURL, setActionURL] = useState("/");
    const [actionLabel, setActionLabel] = useState("Install Now");
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get('/api/notifications/important', {
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                setNotifications(response.data.notifications);
            } catch (error) {
                console.error('Error fetching job notifications:', error);
            }
        };

        fetchNotifications().then();
    }, []); // Adding the empty array as a dependency to ensure it runs only once

    useEffect(() => {
        // Get the last notification message
        if (notifications.length > 0) {
            const lastNotification = notifications[notifications.length - 1];
            setMessage(lastNotification.data.message);
            setActionURL(lastNotification.data.action_url);
            setActionLabel(lastNotification.data.action_label);
        }
    }, [notifications]);

    if(notifications.length > 0) {
        return (
            <Card size={'small'} className={'mb-3'}>
                <div className={'flex items-center gap-2'}>
                    <ExclamationCircleFilled style={{color:'orange'}}/>
                    <div className={'flex gap-5 items-center w-full'}>
                        <p className={'mb-0 mt-0 font-medium text-[14px]'}>{message}
                        </p>
                        <Button type={"primary"} onClick={() => window.location.href = actionURL}>
                            {actionLabel}
                        </Button>
                    </div>
                </div>
            </Card>
        );
    }
    else return "";
}
