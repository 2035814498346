import { Avatar, Layout, Menu, Progress, Tag } from "antd";
import {
    HomeOutlined,
    BlockOutlined,
    GoldOutlined,
    ReadOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { Link } from "@inertiajs/react";
import logo from "../../../../public/media/logoIcon.png";
import JobProgressCard from "@/components/Cards/JobProgressCard";

const { Content, Footer, Sider } = Layout;

const AuthSideBarWrapper = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKey, setSelectedKey] = useState("");
    const [userInfo, setUserInfo] = useState({
        credits: 0,
        plan_name: "Trial",
        credit_left: 0,
        name: ""
    });

    useEffect(() => {
        const currentPath = window.location.pathname.split("/")[1];
        setSelectedKey(currentPath);
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch("/api/user/me");
                const data = await response.json();
                setUserInfo({
                    credits: data.user.credits,
                    plan_name: data.user.plan_name,
                    credit_left: data.user.credit_left,
                    name: data.user.name,
                });
            } catch (error) {
                console.error("Error fetching user info:", error);
            }
        };
        fetchUserData();
    }, []);

    const siderStyle = {
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: "thin",
        backgroundColor: "white",
        borderRight: "2px solid #f0f0f0",
    };

    const menuStyle = {
        display: "flex",
        padding: 0,
        paddingBottom: 150,
        flexDirection: "column",
        justifyContent: "space-between",
    };

    const menuItems = [
        {
            key: 'favorites',
            label: (
                <div>
                    <HomeOutlined className="text-gray-500" />{" "}
                    {!collapsed && <span className="font-bold text-[12px] text-gray-800">Favourites</span>}
                </div>
            ),
            type: 'group',
            children: [
                {
                    key: "dashboard",
                    label: <Link href="/dashboard">Home</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "products",
                    label: <Link href="/products">Products</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "collections",
                    label: <Link href="/collections">Collections</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "dam",
                    label: <Link href="/dam">Media</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "blogs",
                    label: <Link href="/blogs">Blogs</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
            ],
        },
        {
            key: 'data-import',
            label: (
                <div>
                    <BlockOutlined className="text-gray-500" />{" "}
                    {!collapsed && <span className="font-bold text-[12px] text-gray-800">Data Import</span>}
                </div>
            ),
            type: 'group',
            children: [
                {
                    key: "import",
                    label: <Link href="/import">Import</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "integrations",
                    label: <Link href="/integrations">Integrations</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
            ],
        },
        {
            key: 'bulk-tasks',
            label: (
                <div>
                    <GoldOutlined className="text-gray-500" />{" "}
                    {!collapsed && <span className="font-bold text-[12px] text-gray-800">Bulk Tasks</span>}
                </div>
            ),
            type: 'group',
            children: [
                {
                    key: "actions",
                    label: <Link href="/actions">Bulk Actions</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "edit",
                    label: <Link href="/edit">Bulk Edit</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
            ],
        },
        {
            key: 'others',
            label: (
                <div>
                    <ReadOutlined className="text-gray-500" />{" "}
                    {!collapsed && <span className="font-bold text-[12px] text-gray-800">Others</span>}
                </div>
            ),
            type: 'group',
            children: [
                {
                    key: "billing/plans",
                    label: <Link href="/billing/plans">Pricing</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "settings",
                    label: <Link href="/settings">Settings</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "notifications",
                    label: <Link href="/notifications">Notifications</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "help",
                    label: <Link href="/help">Help</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
                {
                    key: "logout",
                    label: <Link href="/logout">Logout</Link>,
                    style: {  height: 25, lineHeight: '30px'}
                },
            ],
        },
    ];

    return (
        <Layout hasSider>
            <Sider style={siderStyle}>
                <div style={{ padding: "5px", display: "flex", alignItems: "center", gap: 7 }}>
                    <Avatar shape="square" src={logo} size="large" />
                    <div className="font-bold">{userInfo.name}</div>
                </div>
                <Menu
                    theme="light"
                    style={{ ...menuStyle, borderInlineEnd: "none" }}
                    selectedKeys={[selectedKey]}
                    mode="inline"
                    items={menuItems}
                />
                {/* Sticky progress bar */}
                <div
                    style={{
                        position: "fixed",
                        bottom: 0,
                        width: "190px",
                        padding: "10px",
                        background: "#e7fbee",
                        borderTop: "1px solid #eee",
                    }}
                >
                    <Tag className="mb-3">{userInfo.plan_name} Plan</Tag>
                    <div style={{fontSize: collapsed ? "10px" : "12px", marginBottom: "4px"}}>
                        {"Plan Usage"}
                    </div>
                    <Progress
                        size="small"
                        strokeColor="#00b96b"
                        percent={((userInfo.credit_left))}
                        showInfo={!collapsed}
                    />
                    <div style={{fontSize: collapsed ? "10px" : "12px", marginBottom: "4px"}}>
                        {`You have ${userInfo.credits} AI words remaining`}
                    </div>
                    <Link href={'/billing/plans'} style={{fontSize: "12px"}}>Upgrade Plan</Link>
                </div>
            </Sider>
            <Layout
                style={{
                    marginInlineStart: collapsed ? 80 : 200,
                    transition: "margin 0.2s",
                    backgroundColor: "#f9fafb"
                }}
            >
                <Content
                    style={{
                        margin: "10px 8px 0",
                        overflow: "initial",
                    }}
                >
                    <JobProgressCard />
                    {children}
                </Content>
                <Footer
                    style={{
                        textAlign: "center",
                        backgroundColor: "#f9fafb"
                    }}
                >
                    <small>ShopGPT ©{new Date().getFullYear()}</small>
                </Footer>
            </Layout>
        </Layout>
    );
};

export default AuthSideBarWrapper;
