import { Form, Input, Button, message, Select, Drawer } from "antd";
import { RobotFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function ProductCreateAI({ data, onSubmit }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [integrationOptions, setIntegrationOptions] = useState([]);

    useEffect(() => {
        if (data && drawerOpen) {  // Only set fields when drawer is open
            form.setFieldsValue({
                product_topic: data.product_topic || "",
                primary_keywords: data.primary_keywords || "",
                additional_instructions: data.additional_instructions || "",
                integrations: data.integrations || [],
            });
        }
    }, [data, drawerOpen]);  // Add drawerOpen as dependency

    // Fetch options from /api/integrations
    useEffect(() => {
        const fetchIntegrationOptions = async () => {
            try {
                const response = await axios.get("/api/integrations");
                const options = response.data?.integrations.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                setIntegrationOptions(options);
            } catch (error) {
                message.error("Failed to load integration options.");
            }
        };
        fetchIntegrationOptions();
    }, []);

    const onFinish = async (values) => {
        try {
            setLoading(true);
            let response;

            if (data?.id) {
                response = await axios.post(`/api/products/${data?.id}/edit`, values);
                message.success("Product updated successfully");
                window.location.href = `/products/${response?.data?.product?.id}/edit`;
            } else {
                response = await axios.post(`/api/products/store`, values);
                message.success("Product created successfully");
                window.location.href = `/products/${response?.data?.product?.id}/edit`;
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 422) {
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            errors[key].forEach((errMsg) => {
                                message.error(errMsg);
                            });
                        }
                    }
                } else if (error.response.status === 402) {
                    message.error(
                        <span>
                            Your AI Credits are finished. Subscribe now to upgrade your plan.
                            <div className="flex justify-end mt-2">
                                <Button
                                    size="small"
                                    type="primary"
                                    onClick={() => {
                                        window.location.href = "/billing/plans";
                                    }}
                                >
                                    Choose Plan
                                </Button>
                            </div>
                        </span>
                    );
                } else {
                    message.error("Our servers are busy right now. Please try again later.");
                }
            } else {
                message.error("Our servers are busy right now. Please try again later.");
            }
        } finally {
            setLoading(false);
        }
    };

    const onOpen = () => {
        setDrawerOpen(true);
    };

    const onClose = () => {
        setDrawerOpen(false);
    };

    return (
        <>
            <Button className="bg-purple-600 text-white" onClick={onOpen} type="dashed">
                <RobotFilled />
                {data?.id ? "Edit AI Prompt" : "Create Product"}
            </Button>

            <Drawer
                title={`${data?.id ? "Update this" : "Create a new"} Product with AI ✨`}
                onClose={onClose}
                open={drawerOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <label>
                        What should be the topic of your new product? <span className="text-red-600">*</span>
                    </label>
                    <Form.Item
                        className="mb-3"
                        name="product_topic"
                        rules={[{ required: true, message: "Please enter product topic." }]}
                    >
                        <Input placeholder="Please enter product topic" />
                    </Form.Item>

                    <label>What should be the primary keywords?</label>
                    <Form.Item className="mb-3" name="primary_keywords">
                        <Input placeholder="Please enter primary keyword" />
                    </Form.Item>

                    <label>Any additional instructions?</label>
                    <Form.Item className="mb-3" name="additional_instructions">
                        <Input.TextArea placeholder="Please enter any additional instructions" />
                    </Form.Item>

                    <label>Where do you want to sync this product?</label>
                    <Form.Item className="mb-3" name="integrations">
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="Select integrations"
                            options={integrationOptions}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button loading={loading} block={true} type="primary" htmlType="submit">
                            {data?.id ? "Update Product" : "Generate Product"}
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
}
