import {Button, Card, Col, Statistic, Switch, Form, Row, Spin, message} from "antd";
import { Head } from "@inertiajs/react";
import React, { useState, useEffect } from "react";
import Title from "antd/lib/typography/Title";
import axios from "axios";
import AuthSideBarWrapper from "@/components/Navs/AuthSideBarWrapper";

export default function IntegrationShow({ integration, totalProductsInStore }) {
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true); // New state for initial loading
    const [form] = Form.useForm(); // Create form instance for easy manipulation

    useEffect(() => {
        // Fetch webhook data when the component mounts
        axios.get(`/api/${integration.id}/webhooks`)
            .then(response => {
                const webhookData = response.data.webhooks; // Assuming response.data contains a webhooks array

                // Extract all topics from the webhooks data
                const topics = webhookData.map(webhook => webhook.topic);

                // Set initial form values based on extracted topics
                form.setFieldsValue({
                    productCreate: topics.includes("PRODUCTS_CREATE"),
                    collectionCreate: topics.includes("collections/create")
                });
            })
            .catch(error => {
                console.error("Error fetching webhook information:", error);
            })
            .finally(() => {
                // Stop the initial loading spinner
                setInitialLoading(false);
            });
    }, []);

    const onFinish = (values) => {
        setLoading(true);

        // Prepare webhooks array based on form values
        const webhooks = [];
        if (values.productCreate) webhooks.push("PRODUCTS_CREATE");
        if (values.collectionCreate) webhooks.push("collection.create");

        axios.post(`/api/${integration.id}/webhooks/update`, { webhooks })
            .then(response => {
                message.success('Webhook updated successfully');
            })
            .catch(error => {
                message.error('Something went wrong. Please try again later.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <AuthSideBarWrapper>
            <Head>
                <title>Integration Details</title>
            </Head>
            <div className={'mb-5'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <Title level={4} className={'m-0'}>{integration.name}</Title>
                    <p className={'m-0 p-0'}>Your store stats and automatic data syncing settings.</p>
                </div>
                <Button
                    type={'primary'}
                    form="webhookForm"
                    htmlType="submit"
                    loading={loading || initialLoading} // Show loading state while fetching data
                >
                    Save
                </Button>
            </div>

            <Row gutter={16} className={'mb-5'}>
                <Col span={6}>
                    <Statistic title="Total Products in Store" value={totalProductsInStore} />
                </Col>
            </Row>

            {/* Show spinner while the initial data is loading */}
            <Spin spinning={initialLoading}>
                <Card className={'bg-gray-200'}>
                    <Form
                        id="webhookForm"
                        layout="vertical"
                        form={form} // Attach form instance
                        onFinish={onFinish}
                        initialValues={{
                            productCreate: false,
                            collectionCreate: false
                        }}
                    >
                        <Form.Item className={'m-0'} label="Enable automatic product syncing from your store to ShopGPT." name="productCreate" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                    </Form>
                </Card>
            </Spin>
        </AuthSideBarWrapper>
    );
}
