import React, { useState, useEffect } from 'react';
import {Card, Button, Flex} from 'antd';
import axios from 'axios';

const PaymentActivationAlert = () => {
    const [recurringCharge, setRecurringCharge] = useState(null);

    useEffect(() => {
        // Fetch the recurring charge data from the API
        const fetchRecurringCharge = async () => {
            try {
                const response = await axios.get('/api/recurring-charges/pending');
                setRecurringCharge(response.data.recurring_charge);
            } catch (error) {
                console.error('Failed to fetch recurring charge:', error);
            }
        };

        fetchRecurringCharge();
    }, []);

    // Don't render the component if there is no recurring charge data
    if (!recurringCharge) {
        return null;
    }

    return (
        <Card size={'small'} className={'mb-3 bg-yellow-100'}>
            <Flex align={'center'} gap={5}>
                <b>Activate your {recurringCharge.plan.name} plan of ${recurringCharge.plan.price} to unlock features of the app!</b>
                <Button size={'small'} type={'primary'} onClick={() => window.location.href = recurringCharge.confirmation_url}>Activate Now</Button>
            </Flex>
        </Card>
    );
};

export default PaymentActivationAlert;
