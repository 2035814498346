import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectLanguageDropdown = ({ initialLocale = 'en', onChange = () => {} }) => {
    const [selectedLanguage, setSelectedLanguage] = useState('');

    const languages = [
        { country: "Chinese", flag: "🇨🇳", name: "Chinese", code: "zh" },
        { country: "Spanish", flag: "🇪🇸", name: "Spanish", code: "es" },
        { country: "English", flag: "🇺🇸", name: "English", code: "en" },
        { country: "Hindi", flag: "🇮🇳", name: "Hindi", code: "hi" },
        { country: "Arabic", flag: "🇸🇦", name: "Arabic", code: "ar" },
        { country: "Portuguese", flag: "🇵🇹", name: "Portuguese", code: "pt" },
        { country: "Bengali", flag: "🇧🇩", name: "Bengali", code: "bn" },
        { country: "Russian", flag: "🇷🇺", name: "Russian", code: "ru" },
        { country: "Japanese", flag: "🇯🇵", name: "Japanese", code: "ja" },
        { country: "Punjabi", flag: "🇮🇳", name: "Punjabi", code: "pa" },
        { country: "German", flag: "🇩🇪", name: "German", code: "de" },
        { country: "Javanese", flag: "🇮🇩", name: "Javanese", code: "jv" },
        { country: "Korean", flag: "🇰🇷", name: "Korean", code: "ko" },
        { country: "French", flag: "🇫🇷", name: "French", code: "fr" },
        { country: "Telugu", flag: "🇮🇳", name: "Telugu", code: "te" },
        { country: "Marathi", flag: "🇮🇳", name: "Marathi", code: "mr" },
        { country: "Turkish", flag: "🇹🇷", name: "Turkish", code: "tr" },
        { country: "Tamil", flag: "🇮🇳", name: "Tamil", code: "ta" },
        { country: "Urdu", flag: "🇵🇰", name: "Urdu", code: "ur" },
        { country: "Vietnamese", flag: "🇻🇳", name: "Vietnamese", code: "vi" },
    ];

    useEffect(() => {
        setSelectedLanguage(initialLocale);
    }, [initialLocale]);

    const handleChange = (value) => {
        setSelectedLanguage(value);
        onChange(value);
    };

    const filterOptions = (input, option) => {
        const searchText = input.toLowerCase();
        return option.name.toLowerCase().includes(searchText);
    };

    return (
        <Select
            showSearch
            className={'w-full'}
            placeholder="Select a language"
            optionFilterProp="children"
            onChange={handleChange}
            filterOption={filterOptions}
            value={selectedLanguage}
        >
            {languages.map((language) => (
                <Option
                    key={language.code}
                    value={language.code}
                    name={language.name}
                >
                    <span className="flex items-center">
                        <span className="mr-2">{language.flag}</span>
                        {language.name}
                    </span>
                </Option>
            ))}
        </Select>
    );
};

export default SelectLanguageDropdown;

